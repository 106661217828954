import React, {useState} from 'react'
import s from './AccountStatement.module.css'
import {StatementTable} from "./StatementTable/StatementTable";
import {StatementForm} from "./StatementForm/StatementForm";
import DownloadStatement from "./DownloadStatement/DownloadStatement";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import styles from "../../../../styles/styles.module.css";
import {motion} from "framer-motion";
import AccountConfirmationLetter from "./AccountConfirmationLetter/AccountConfirmationLetter";

export const AccountStatement = () => {

    const [dataForStatement, setDataForStatement] = useState<any>()

    const accountRef = React.useRef<HTMLDivElement | null>(null);

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.container}>
                <Tabs>
                    <TabList className={s.tabstitle_block}>
                        <Tab selectedClassName={s.active_tab} className={s.tabtitle}>Account Statement</Tab>
                        <div className={s.separator}></div>
                        <Tab selectedClassName={s.active_tab} className={s.tabtitle}>Account Confirmation Letter</Tab>
                    </TabList>
                    <TabPanel>
                        <div className={s.accountStatementWrapper}>
                            <StatementForm scrollPage={accountRef} setDataForStatement={setDataForStatement}/>
                            <DownloadStatement dataForStatement={dataForStatement}/>
                            <StatementTable ref={accountRef}/>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <AccountConfirmationLetter/>
                    </TabPanel>
                </Tabs>
            </div>
        </motion.div>
    )
}
