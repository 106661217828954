import React from 'react';
import s from './DownloadStatement.module.css'
import {Api} from "../../../../../api/Api";
import {useAppSelector} from "../../../../../hooks/redux";

const DownloadStatement = (props: any) => {

    const token = useAppSelector(state => state.userReducer.userToken)
    const accountId = useAppSelector(state => state.userReducer.chosenAccountTypeId)

    const prepareDataForPDF = (obj: Record<string, any>) => {
        const prepareData: {[key: string]: any} = {}
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (key === "amount" && obj[key] === 0) {
                prepareData[key] = null;
            } else {
                prepareData[key] = obj[key] === '' ? null : obj[key]
                }
            }
        }
        return prepareData
    }

    

    const accountInfo = {
        accountNumber: props?.dataForStatement?.hiddenAccountNumber ? props?.dataForStatement?.hiddenAccountNumber : "ALL",
        dateFrom: props?.dataForStatement?.from,
        dateTo: props?.dataForStatement?.to,
        accountId: accountId,
        transferType: "all",
        inComing: props?.dataForStatement?.incoming,
        outgoing: props?.dataForStatement?.outgoing,
        other: props?.dataForStatement?.other,
        amount: +props?.dataForStatement?.amount,
        fees: props?.dataForStatement?.fees,
        recipient: props?.dataForStatement?.recipient
    }
    const dataToSend = prepareDataForPDF(accountInfo)

    const getStatement = () => {
        
        Api.getStatementInfo(token, dataToSend)
            .then((res: any) => {
                fetch(process.env.REACT_APP_API_URL + res.data)
                const url = process.env.REACT_APP_API_URL + res.data
                window.open(url, '_blank');

            })
            .catch((err: any) => {
                console.log(err)
            })
    }


    return (

        <div className={s.statementWrapper} >
            <div className={s.buttonsWrapper}>
                <p className={s.downloadTitle}>Download as</p>
                <p className={s.buttonTitle} onClick={() => getStatement()}>PDF</p>
                {/* <div className={s.separator}/> */}
                {/*<p className={s.buttonTitle}>XLSX</p>*/}
                {/*<div className={s.separator}/>*/}
                {/* <p className={s.buttonTitle}>XLM</p> */}
                {/*<div className={s.separator}/>*/}
                {/*<p className={s.buttonTitle}>LITAS-ESIS</p>*/}
                {/*<div className={s.separator}/>*/}
                {/*<p className={s.buttonTitle}>FIDAVISTA</p>*/}
            </div>

            {/*<div className={s.printWrapper}>*/}
            {/*    <img src={print} alt="print_icon"/>*/}
            {/*    <p className={s.print}>Print</p>*/}
            {/*</div>*/}
        </div>
    )
}

export default DownloadStatement
