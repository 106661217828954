import React, {Dispatch, useState} from 'react';
import s from "../BankTransfer/BankTransfer.module.css";
import ToAnLuxPayStepOne from "./ToAnLuxPayStepOne/ToAnLuxPayStepOne";
import ToAnLuxPayStepTwo from "./ToAnLuxPayStepTwo/ToAnLuxPayStepTwo";

type BankTransferPropsType = {
    isButtonDisable: boolean | undefined
    code: string
    withdrawalStatus: string
    setIsButtonDisabled: Dispatch<React.SetStateAction<boolean>>
}

const ToAnLuxPay = (props: BankTransferPropsType) => {
    const [current, setCurrent] = useState(0);
    const [ourTransferData, setOurTransferData] = useState({})

    const steps = [
        {
            content: <ToAnLuxPayStepOne setCurrent={setCurrent}
                                        setTransferData={setOurTransferData}
                                        isButtonDisable={props.isButtonDisable}
                                        setIsButtonDisabled={props.setIsButtonDisabled}
            />
        },
        {
            content: <ToAnLuxPayStepTwo setCurrent={setCurrent}
                                        current={current}
                                        transferData={ourTransferData}
                                        code={props.code}
                                        withdrawalStatus={props.withdrawalStatus}
            />
        },
    ];

    return (
        <>
            <div className={s.wrapper}>
                {steps[current].content}
            </div>
            <div className={s.transfer_description}>
                <p>
                    Internal transfers are possible in any currency. All internal transfers reach the recipient within a
                    minute.
                </p>
            </div>
        </>

    );
};

export default ToAnLuxPay;
