import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Formik} from "formik";
import s from "./ToLuxPayTransactionTemplate.module.css";
import {Popover} from "antd";
import info from "../../../assets/img/personalAccount/information.svg";
import classnames from "classnames";
import {motion} from "framer-motion";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import CustomDropdownForAccounts from "../../ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import CustomDropdown from "../../ui/CustomDropdown/CustomDropdown";
import MyBtn from "../../ui/MyBtn/MyBtn";
import MyInput from "../../ui/MyInput/MyInput";
import TransparentButton from "../../ui/TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import {ReactComponent as Back} from "../../../assets/img/personalAccount/back.svg";
import {useNavigate} from "react-router-dom";
import {
    PrepareDataForPaymentIntraTemplate,
} from "../../../helpers/PrepareDataForPaymentTemplate";
import {createTemplate, getTemplateByIdThunk, SendTransferThunk} from "../../../store/reducers/ActionCreators";
import {ITemplate} from "../../../types/interfaces";
import CustomModal from "../../ui/CustomModal/CustomModal";
import {
    CheckAvailableAccountsByEmail,
    CheckAvaliableAccountsByPhone, ResetFieldByTransferMethod,
    CheckAvaliableAccountByAccountNumber
} from "../../ToAnLuxPayTransferForm/Helpers";
import styles from "../../../styles/styles.module.css";
import iconSuccess from "../../../assets/img/personalAccount/tick-circle.svg";
import {validationSchemaForTemplate, validationSchemaForTransfer} from "./FormHelpers/formHelpers";
import {PrepareDataForLuxpayTransaction} from "../../../helpers/PrepareDataForLuxpayTransaction";
import {setDisabled} from "../../../store/reducers/UserSlice";
import { areLimitsExceeded } from '../../../helpers/LimitsHelper';

type PropsType = {
    id?: string | number;
    templateName: string,
    isButtonDisable: boolean,
    setPurpose: Dispatch<SetStateAction<string>>
    code: string
    withdrawalStatus: string
}

const CurrencyHelper = (props: any) => {

    useEffect(() => {
        props.accountsList.map((account: any) => {

            if (account.number === props.accNumberFrom) {
                props.setCurrency(account.currency)
            }
        })
    }, [props.accountsList, props.accNumberFrom])

    return null
}

const ToLuxpayTransactionTemplate = (props: PropsType) => {
    const accountsList = useAppSelector(state => state.userReducer.accountsList.accounts)
    const navigate = useNavigate()
    const token = useAppSelector(state => state.userReducer.userToken)
    const accountId = useAppSelector(state => state.userReducer.chosenAccountTypeId)
    const dispatch = useAppDispatch()
    const [paymentToLuxPayTemplate, setPaymentToLuxPayTemplate] = useState<ITemplate>()
    const [isShowSuccessModal, setIsShowSuccessModal] = useState(false)
    const [currency, setCurrency] = useState('')
    const [isRequestDenied, setIsRequestDenied] = useState<boolean | null>(false)
    const [isAccountWrong, setIsAccountWrong] = useState<boolean | null>(false)
    const [isAvailableAccountExists, setIsAvailableAccountExists] = useState<boolean | null>(true)
    const [isPaymentSuccessfullyFinished, setIsPaymentSuccessfullyFinished] = useState<boolean | null>(null)
    const [isShowPaymentSentSuccessModal, setIsShowPaymentSentSuccessModal] = useState(false)
    const [accountNumber, setAccountNumber] = useState('')
    const [localyDisabled, setLocalyDisabled] = useState(false)
    const [isListOfAccounts, setIsListOfAccounts] = useState()


    useEffect(() => {
        if (props.id !== 'create') {
            dispatch(getTemplateByIdThunk(token, props.id, accountId))
                .then((res) => {
                    props.setPurpose('Transfer to LuxPay User')
                    setPaymentToLuxPayTemplate(res.data)
                })
        }
    }, [])

    const transferMethods = [
        'wallet id',
        'email',
        'phone number'
    ]

    const accountType = [
        'Personal',
        'Business',
    ]

    const closeAndRedirect = () => {
        setIsShowSuccessModal(false)
        setIsShowPaymentSentSuccessModal(false)
        navigate(-1)
    }

    const limitsInfo = useAppSelector(state => state.userReducer.userLimits);
    const [isLimitPopupOpen, setIsLimitPopupOpen] = useState<boolean | null>(false)
    const [limitType, setLimitType] = useState<string | null>('');

    const errorAndRedirect = () => {
        setIsLimitPopupOpen(false)
        localStorage.removeItem("transfer-form")
        navigate('/personal_account/templates')
    }

    useEffect(() => {
        if (props.id !== 'create') {
            areLimitsExceeded(limitsInfo, setLimitType, setIsLimitPopupOpen);
        }
    })

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.form_wrapper}
        >
            <Formik
                enableReinitialize={true}
                initialValues={{
                    accNumberFrom: paymentToLuxPayTemplate?.fromAccountMainIban,
                    accNumberTo: paymentToLuxPayTemplate?.toAccountUserNumber,
                    email: paymentToLuxPayTemplate?.recipientDetails?.email,
                    phoneNumber: paymentToLuxPayTemplate?.recipientDetails?.phone,
                    emailLanguage: 'EN',
                    amount: '',
                    invoice: '',
                    purpose: paymentToLuxPayTemplate?.recipientDetails?.purpose,
                    transferCurrency: 'EUR',
                    transferMethod: paymentToLuxPayTemplate?.transferType
                        ? (paymentToLuxPayTemplate?.transferType === 'account' ? 'wallet id' : paymentToLuxPayTemplate?.transferType)
                        : 'wallet id',
                    hiddenAccountNumberTo: paymentToLuxPayTemplate?.toAccount,
                    hiddenAccountNumberFrom: paymentToLuxPayTemplate?.account,
                    accountType: paymentToLuxPayTemplate?.accountType
                }}
                validationSchema={props.id === 'create'
                    ? validationSchemaForTemplate
                    : validationSchemaForTransfer}
                onSubmit={async (values) => {
                    setLocalyDisabled(true)
                    // dispatch(setDisabled(true))

                    if (props.id === 'create') {
                        //@ts-ignore
                        const selectedAccount = isListOfAccounts?.find((account: any) => account.clientType === values.accountType.toLocaleLowerCase());
                        if (selectedAccount) {
                            values.hiddenAccountNumberTo = selectedAccount.number;
                        }
                    }

                    let tempTransferMethod: string|null = ''

                    if (values.transferMethod === 'phone number') {
                        tempTransferMethod = 'phone'
                    }

                    if (values.transferMethod === 'email') {
                        tempTransferMethod = 'email'
                    }

                    if (values.transferMethod === 'wallet id') {
                        tempTransferMethod = 'account'
                    }

                    const tempData = {
                        ...values,
                        templateName: props.templateName,
                        accountNumber: accountNumber,
                        transferMethod: tempTransferMethod
                    }

                    if (props.id === 'create') {
                        const preparedData = PrepareDataForPaymentIntraTemplate(tempData, accountId)

                        const requestData = {
                            ...preparedData,
                            toAccount: values.hiddenAccountNumberTo !== undefined ? values.hiddenAccountNumberTo : values.accNumberTo
                        }

                        dispatch(createTemplate(token, requestData))
                            .then((res) => {
                                res && res.data === 'Created' ? setIsShowSuccessModal(true) : setIsRequestDenied(true)
                            })
                            .finally(() => {
                                dispatch(setDisabled(false))
                            })
                    } else {
                        const secondTempData = {
                            ...tempData,
                            type: 'internal',
                            hiddenAccountNumber: paymentToLuxPayTemplate?.toAccount,
                            accountNumber: paymentToLuxPayTemplate?.account,
                            transferCurrency: currency,
                        }

                        if (secondTempData.transferMethod === 'wallet id') {
                            secondTempData.transferMethod = 'account'
                        }

                        const preparedData = PrepareDataForLuxpayTransaction(
                            secondTempData,
                            accountId,
                            props.code,
                            props.withdrawalStatus
                        )

                        dispatch(SendTransferThunk(token, await preparedData, accountId))
                            .then((res) => {
                                if (res.data === 'Created') {
                                    setIsPaymentSuccessfullyFinished(true)
                                }
                            })
                            .catch((e) => {
                                console.error(e)
                                setIsPaymentSuccessfullyFinished(false)
                            })
                            .finally(() => {
                                dispatch(setDisabled(false))
                            })
                    }


                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit
                  }) => (
                    <form onSubmit={handleSubmit}>

                        <CustomModal isModalOpen={isShowPaymentSentSuccessModal} isNotify>
                            <div className={styles.modal_content}>
                                <div className={styles.popup_subtitle}>
                                    <span className={styles.success}>Success!</span> Your request has been sent
                                    successfully.
                                </div>
                                <div className={styles.popup_button_block}>
                                    <MyBtn
                                        isPersonalAccountBtn
                                        title={"Ok"}
                                        onClick={() => closeAndRedirect()}
                                    />
                                </div>
                            </div>
                        </CustomModal>

                        <CustomModal isModalOpen={isShowSuccessModal} type="confirm">
                            <div className={classnames(styles.modal_content, s.modal_content_center)}>
                                <img src={iconSuccess} alt="" className={styles.popup_icon}/>
                                <div className={styles.popup_title}>
                                    Success!
                                </div>
                                <div className={styles.popup_subtitle}>
                                    Your template has been added successfully.
                                </div>
                                <div className={styles.popup_button_block}>
                                    <MyBtn title={"Ok"}
                                           medium
                                           isPersonalAccountBtn
                                           onClick={() => closeAndRedirect()}
                                    />
                                </div>
                            </div>
                        </CustomModal>

                        <CustomModal type="error" isNotify isModalOpen={isAccountWrong}
                                     setIsModalOpen={setIsAccountWrong}>
                            <div className={s.modal_content}>
                                <div className={s.popup_subtitle}>
                                    <span className={s.error}>Failed!</span> The transfer cannot be made as currency of
                                    the payer account doesn't equal to currency of the recipient account.
                                </div>
                                <div className={s.popup_button_block}>
                                    <MyBtn buttonType="error" isPersonalAccountBtn title={"Ok"} onClick={() => {
                                        setIsAccountWrong(false)
                                    }}/>
                                </div>
                            </div>
                        </CustomModal>

                        <CustomModal type="error" isNotify isModalOpen={!isAvailableAccountExists}
                                     setIsModalOpen={setIsAvailableAccountExists}>
                            <div className={s.modal_content}>
                                <div className={s.popup_subtitle}>
                                    <span className={s.error}>Failed!</span> The transfer cannot be made to the
                                    specified&nbsp;
                                    {values.transferMethod?.toLowerCase()} due to the lack of an account with the
                                    recipient.
                                </div>
                                <div className={s.popup_button_block}>
                                    <MyBtn buttonType="error" isPersonalAccountBtn title={"Ok"} onClick={() => {
                                        setIsAvailableAccountExists(true)
                                    }}/>
                                </div>
                            </div>
                        </CustomModal>

                        <CustomModal type="error" isNotify isModalOpen={isRequestDenied}
                                     setIsModalOpen={setIsRequestDenied}>
                            <div className={s.modal_content}>
                                <div className={s.popup_subtitle}>
                                    <span className={s.error}>Failed!</span> Your request was rejected.
                                    <br/>Please try again.
                                </div>
                                <div className={s.popup_button_block}>
                                    <MyBtn buttonType="error" isPersonalAccountBtn title={"Ok"} onClick={() => {
                                        navigate('/personal_account/templates')
                                    }}/>
                                </div>
                            </div>
                        </CustomModal>

                        <CustomModal type="confirm" isNotify isModalOpen={isPaymentSuccessfullyFinished}
                                     setIsModalOpen={setIsPaymentSuccessfullyFinished}>
                            <div className={styles.modal_content}>
                                <div className={styles.popup_subtitle}>
                                    <span className={styles.success}>Success!</span> Your request has been sent
                                    successfully.
                                </div>
                                <div className={styles.popup_button_block}>
                                    <MyBtn isPersonalAccountBtn title={"Ok"} onClick={() => {
                                        navigate('/personal_account/templates')
                                    }}/>
                                </div>
                            </div>
                        </CustomModal>

                        <CustomModal type="error" isNotify isModalOpen={isPaymentSuccessfullyFinished === false}
                                     setIsModalOpen={setIsPaymentSuccessfullyFinished}>
                            <div className={styles.modal_content}>
                                <div className={styles.popup_subtitle}>
                                    <span className={styles.error}>Failed!</span> Something went wrong. Please try again
                                    or contact us.
                                </div>
                                <div className={styles.popup_button_block}>
                                    <MyBtn buttonType="error" isPersonalAccountBtn title={"Ok"} onClick={() => {
                                        setIsPaymentSuccessfullyFinished(null)
                                    }}/>
                                </div>
                            </div>
                        </CustomModal>

                        <CustomModal type="error" isNotify isModalOpen={isLimitPopupOpen} setIsModalOpen={setIsLimitPopupOpen}>
                            <div className={styles.modal_content}>
                                <div className={styles.popup_subtitle}>
                                    <span className={styles.error}>Failed!</span> The transaction is impossible due to exceeding {limitType} limit.
                                    Please contact <a className={styles.popup_link} href='mailto:support@luxpay.lt'>support@luxpay.lt</a>&nbsp;
                                    if you have any questions.
                                </div>
                                <div className={styles.popup_button_block}>
                                    <MyBtn buttonType="error" isPersonalAccountBtn title={"Ok"} onClick={() => errorAndRedirect()} />
                                </div>
                            </div>
                        </CustomModal>

                        <div className={s.row}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    <span><span className={s.red}>*</span> Payer account</span>
                                    <Popover content={"Please select the account you wish to make a transfer from."}
                                             color={'#EEEFEF'}
                                             overlayInnerStyle={{
                                                 fontFamily: 'Manrope',
                                                 width: '280px',
                                                 padding: '13px 16px',
                                                 cursor: 'pointer',
                                                 backgroundColor: '#E7F0EC'
                                             }}
                                    >
                                        <img src={info} alt=""/>
                                    </Popover>
                                </div>
                                <CustomDropdownForAccounts
                                    items={accountsList}
                                    placeholder={"Please select the account"}
                                    id="accNumberFrom"
                                    name="accNumberFrom"
                                    isError={errors.accNumberFrom}
                                    touched={touched.accNumberFrom}
                                    setAccountNumber={setAccountNumber}
                                    disabled={props.id !== 'create'}
                                    setCurrency={(selectedCurrency) => {
                                        setCurrency(selectedCurrency);
                                    }}
                                />
                                {errors.accNumberFrom &&
                                    <div className={s.error_message}>{errors.accNumberFrom}</div>}
                            </div>

                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    <span><span className={s.red}>*</span> Transfer Method</span>
                                    <Popover content={"Choose one of the recipient’s identifiers: their LuxPay" +
                                        " account, email address or phone number."}
                                             color={'#EEEFEF'}
                                             overlayInnerStyle={{
                                                 fontFamily: 'Manrope',
                                                 width: '280px',
                                                 padding: '13px 16px',
                                                 cursor: 'pointer',
                                                 backgroundColor: '#E7F0EC'
                                             }}
                                    >
                                        <img src={info} alt=""/>
                                    </Popover>
                                </div>
                                <CustomDropdown
                                    items={transferMethods}
                                    id="transferMethod"
                                    name="transferMethod"
                                    isError={errors.transferMethod}
                                    touched={touched.transferMethod}
                                    disable={props.id !== 'create'}
                                />
                            </div>
                        </div>

                        <div className={s.block}>
                            <div className={s.title}>
                                Outgoing Payment Details
                            </div>

                            <div className={s.row}>

                                <div className={s.input_block}>
                                    {values.transferMethod === 'wallet id'
                                        && <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                <span><span className={s.red}>*</span> Wallet ID</span>
                                            </div>
                                            <MyInput id={"accNumberTo"}
                                                     name={"accNumberTo"}
                                                     touched={touched.accNumberTo}
                                                     value={values.accNumberTo}
                                                     onChange={handleChange}
                                                      isError={errors.accNumberTo}
                                                     placeholder={"Enter wallet id"}
                                                     disabled={props.id !== 'create'}
                                            />
                                            {errors.accNumberTo &&
                                                <div className={s.error_message}>{errors.accNumberTo}</div>}
                                        </div>
                                    }

                                    {values.transferMethod === 'email'
                                        && <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                *Email
                                            </div>
                                            <MyInput id={"email"}
                                                     name={"email"}
                                                     touched={touched.email}
                                                     value={values.email}
                                                     onChange={handleChange}
                                                     isError={errors.email}
                                                     disabled={props.id !== 'create'}
                                            />
                                        </div>
                                    }

                                    {(values.transferMethod === 'phone number' || values.transferMethod === 'phone')
                                        && <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                <span><span className={s.red}>*</span> phone number</span>
                                                <Popover
                                                    content={"Phone number in international format."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Manrope',
                                                        width: '280px',
                                                        padding: '13px 16px',
                                                        cursor: 'pointer',
                                                        backgroundColor: '#E7F0EC'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"phoneNumber"}
                                                     name={"phoneNumber"}
                                                     touched={touched.phoneNumber}
                                                     value={values.phoneNumber}
                                                     onChange={handleChange}
                                                     isError={errors.phoneNumber}
                                                     disabled={props.id !== 'create'}
                                            />
                                        </div>
                                    }

                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        Purpose
                                    </div>
                                    <MyInput id={"purpose"}
                                             name={"purpose"}
                                             touched={touched.purpose}
                                             value={values.purpose}
                                             onChange={handleChange}
                                             isError={errors.purpose}
                                             placeholder={"Enter your purpose of payment"}
                                             disabled={props.id !== 'create'}
                                    />
                                </div>

                            </div>

                            {
                                props.id !== 'create' && values.transferMethod === "wallet id" &&
                                    <div className={classnames(s.row)}>
                                        <div className={s.mobile_row}>
                                                <div className={classnames(s.input_block, s.short)}>
                                                    <div className={s.input_label}>
                                                        <span><span className={s.red}>*</span> Amount</span>
                                                        <Popover content={"Select a currency and enter the transfer amount.\n" +
                                                            "In the account balance in the selected currency is insufficient, the system " +
                                                            "will automatically offer you to top up using funds in a different currency " +
                                                            "you have on the account."}
                                                                 color={'#EEEFEF'}
                                                                 overlayInnerStyle={{
                                                                     fontFamily: 'Manrope',
                                                                     width: '280px',
                                                                     padding: '13px 16px',
                                                                     cursor: 'pointer',
                                                                     backgroundColor: '#E7F0EC'
                                                                 }}
                                                        >
                                                            <img src={info} alt=""/>
                                                        </Popover>
                                                    </div>
                                                    <MyInput id={"amount"}
                                                             name={"amount"}
                                                             value={values.amount}
                                                             onChange={handleChange}
                                                             isError={errors.amount}
                                                    />
                                                    {errors.amount && <div className={s.error_message}>{errors.amount}</div>}
                                                </div>
                                        </div>
                                    </div>
                            }

                            {
                                props.id !== 'create' && values.transferMethod !== "wallet id" &&
                                <div className={s.row}>

                                    <div className={s.mobile_row}>
                                        <div className={classnames(s.input_block, s.short)}>
                                            <div className={s.input_label}>
                                                <span><span className={s.red}>*</span> Amount</span>
                                                <Popover content={"Select a currency and enter the transfer amount.\n" +
                                                    "In the account balance in the selected currency is insufficient, the system " +
                                                    "will automatically offer you to top up using funds in a different currency " +
                                                    "you have on the account."}
                                                         color={'#EEEFEF'}
                                                         overlayInnerStyle={{
                                                             fontFamily: 'Manrope',
                                                             width: '280px',
                                                             padding: '13px 16px',
                                                             cursor: 'pointer',
                                                             backgroundColor: '#E7F0EC'
                                                         }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"amount"}
                                                     name={"amount"}
                                                     value={values.amount}
                                                     onChange={handleChange}
                                                     isError={errors.amount}
                                            />
                                            {errors.amount && <div className={s.error_message}>{errors.amount}</div>}
                                        </div>
                                    </div>

                                    <div className={s.currency_dropdown}>
                                        <div className={s.input_label}>
                                        </div>
                                        <MyInput
                                            id="transferCurrency"
                                            name="transferCurrency"
                                            value={currency}
                                            disabled={true}
                                        />
                                    </div>

                                    <div className={classnames(s.input_block, s.short)}>

                                    </div>

                                </div>
                            }
                            {
                                props.id === 'create' &&
                                <div className={s.row}>
                                    <div className={s.mobile_row}>

                                        <div className={s.currency_dropdown}>
                                            <div className={s.input_label}>
                                            </div>
                                            <MyInput
                                                id="transferCurrency"
                                                name="transferCurrency"
                                                value={currency}
                                                disabled={true}
                                            />
                                        </div>

                                        <div className={classnames(s.input_block, s.short)}>
                                            {
                                                //@ts-ignore
                                                isListOfAccounts?.length > 1 &&
                                                <div className={s.input_block}>
                                                    <div className={s.input_label}>
                                                        <span><span
                                                            className={s.red}>*</span> RECIPIENT ACCOUNT TYPE</span>
                                                    </div>
                                                    <CustomDropdown
                                                        items={accountType}
                                                        id="accountType"
                                                        name="accountType"
                                                        isError={errors.accountType}
                                                        touched={touched.accountType}
                                                    />
                                                    {errors.accountType && touched.accountType &&
                                                        <div className={s.error_message}>{errors.accountType}</div>}
                                                </div>
                                            }
                                        </div>

                                    </div>
                                </div>
                            }

                        </div>

                        <div className={s.continue_block}>
                            <div className={s.required}>
                                <div>
                                    <span><span className={s.red}>*</span> required fields.</span>
                                </div>
                            </div>
                        </div>

                        <div className={s.buttonsWrapper}>
                            <div className={s.button_block}>
                                <TransparentButton title={'Back'}
                                                   isPersonalAccountBtn
                                                   large
                                                   icon={<Icon component={() => <Back/>} rev={undefined}/>}
                                                   onClick={() => {
                                                       navigate(-1);
                                                   }}/>
                            </div>

                            <MyBtn title={props.id === 'create' ? 'Save' : 'Continue'}
                                   large
                                   style={{width: 180}}
                                   type="submit"
                                   isPersonalAccountBtn
                                   localDisable={(props.id !== 'create' && props.isButtonDisable) || localyDisabled}
                            />
                        </div>

                        <CurrencyHelper
                            accountsList={accountsList}
                            setCurrency={setCurrency}
                            accNumberFrom={values.hiddenAccountNumberFrom}
                        />

                        <CheckAvailableAccountsByEmail
                            name={"accNumberTo"}
                            email={values.email}
                            token={token}
                            currency={currency}
                            setIsAvailableAccountExists={setIsAvailableAccountExists}
                            setIsListOfAccounts={setIsListOfAccounts}
                        />

                        <CheckAvaliableAccountsByPhone
                            name={"accNumberTo"}
                            phone={values.phoneNumber}
                            token={token}
                            currency={currency}
                            setIsAvailableAccountExists={setIsAvailableAccountExists}
                            setIsListOfAccounts={setIsListOfAccounts}
                        />

                        <CheckAvaliableAccountByAccountNumber
                            name={"hiddenAccountNumberTo"}
                            account={values.accNumberTo}
                            token={token}
                            currency={currency}
                            setIsAccountWrong={setIsAccountWrong}
                            setIsAvailableAccountExists={setIsAvailableAccountExists}
                        />

                        <ResetFieldByTransferMethod
                            isCreate={props.id === 'create'}
                            name={"email"}
                            handleReset={() => {
                            }}
                        />
                        <ResetFieldByTransferMethod
                            isCreate={props.id === 'create'}
                            name={"phoneNumber"}
                            handleReset={() => {
                            }}
                        />
                        <ResetFieldByTransferMethod
                            isCreate={props.id === 'create'}
                            name={"accNumberTo"}
                            handleReset={() => {
                            }}
                        />

                    </form>
                )}
            </Formik>
        </motion.div>
    );
};

export default ToLuxpayTransactionTemplate;
