import {toBase64} from "./toBase64";

export const PrepareDataForLuxpayTransaction = async (values: any, accountId?: number | null | undefined, code?: string, withdrawalStatus?: string) => {

     const invoiceAsString = await toBase64(values.invoice) as string

     return {
          accNumberFrom: values.accountNumber,
          accNumberTo: values.hiddenAccountNumber,
          amount: Number(values.amount),
          transferType: values.transferMethod,
          reference: values.reference ? values.reference : values.purpose,
          templateName: values.templateName,
          type: values.type,
          currency: values.transferCurrency,
          accountId: accountId,
          invoice: invoiceAsString,
          invoiceFilename: values?.invoice?.name,
          withdrawalCode: code
     }
}
