import React, {useState, useEffect} from 'react';
import s from './MobileHeader.module.css'
import logo from '../../assets/img/logo_without_text.svg'
import burger from '../../assets/img/burger.svg'
import TransparentButton from "../ui/TransparentButton/TransparentButton";
import {Link, useNavigate, useLocation} from "react-router-dom";
import NavBar from "../Header/NavBar/NavBar";
import MyBtn from "../ui/MyBtn/MyBtn";
import { useAppSelector } from '../../hooks/redux';

const MobileHeader = () => {

    const [navIsShow, setNavIsShow] = useState(false)
    const navigate = useNavigate()

    const location = useLocation()
    const [isHeaderShow, setIsHeaderShow] = useState(true)

    const token = useAppSelector(state => state.userReducer.userToken)
    const kycVerificationStatus = useAppSelector(state => state.userReducer.user.flags.kycVerificationStatus)
    const clientId: string | null = useAppSelector(state => state.userReducer.user.userData.clientId)
    const firstName: string | null = useAppSelector(state => state.userReducer.user.userData.firstName)
    const lastName: string | null = useAppSelector(state => state.userReducer.user.userData.lastName)
    const companyName: string | null = useAppSelector(state => state.userReducer.user.userData.companyName)    
    
    const backToPersonalAccount = () => {
        if (kycVerificationStatus !== "Approved") {
            navigate("/personal_account/personal/settings");
        } else {
            navigate('/personal_account/accounts')
        }
    }

    useEffect(() => {

        if (location.pathname.includes("sign_up") || location.pathname.includes("login"))
        {
            setIsHeaderShow(false)
        } else {
            setIsHeaderShow(true)
        }
    }, [location.pathname])

    return (
        <>
            {
             isHeaderShow &&
             <div className={s.wrapper}>
                <div className={s.logo_block}>
                    <img src={logo} alt="" onClick={() => navigate("/")}/>
                </div>
                <div className={s.right}>
                    {
                        token !== "" ?
                            <div onClick={() => backToPersonalAccount()} className={s.round}>
                                <div className={s.user_typography}>
                                    <div><b><p className={s.name}>{firstName} {lastName} {companyName}</p></b></div>
                                    <div><p className={s.id}>ID: {clientId}</p></div>
                                </div>
                                <div className={s.user}></div>
                            </div>
                        : <div className={s.buttons_block}>
                            <div className={s.button_wrapper_short}>
                                <Link to={"/login"}>
                                    <TransparentButton width='111px' height='26px' padding='0' title={"Sign In"}/>
                                </Link>
                            </div>
                            <div className={s.button_wrapper}>
                                <Link to={"/sign_up"}>
                                    <MyBtn fs='12px' width='111px' height='26px' padding='0' title={"Open account"}/>
                                </Link>
                            </div>
                        </div>
                    }
                    <div>
                        <img src={burger} alt="" onClick={() => setNavIsShow(true)}/>
                    </div>
                </div>

                <NavBar isActive={navIsShow} setIsActive={setNavIsShow}/>
            </div>
            }
        </>
    );
};

export default MobileHeader;
