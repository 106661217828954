import React, {useEffect, useState} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import s from '../../../../styles/styles.module.css'
import KycPersonal from "./KycPersonal/KycPersonal";
import {motion} from 'framer-motion';
import ProfileSettings from "./ProfileSettings/ProfileSettings";
import Limits from "./Limits/Limits";
import { useAppSelector } from "../../../../hooks/redux";

const Settings = () => {

    const kycVerificationStatus = useAppSelector(state => state.userReducer.user.flags.kycVerificationStatus)
    const kycFormStatus = useAppSelector(state => state.userReducer.user.flags.kycFormStatus)
    const [isMenuShow, setIsMenuShow] = useState<boolean | null>(false)

    const accountType = useAppSelector((state) => state.userReducer.user.userData.accountType)

    useEffect(() => {
        if (kycVerificationStatus === "Approved" && kycFormStatus === "Approved") {
            setIsMenuShow(true)
        } else {
            setIsMenuShow(false)
        }
    }, [kycVerificationStatus, kycFormStatus])

    return (
        <div style={{height: '100%'}}>
            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
            >
                <Tabs>
                    <TabList className={s.tabstitle_block}>
                        <Tab selectedClassName={s.active_tab} className={s.tabtitle}>{accountType === 'business' ? "KYB" : "KYC"}</Tab>
                        <Tab selectedClassName={s.active_tab} className={s.tabtitle}>Profile Settings</Tab>
                        {
                            isMenuShow &&
                        <Tab selectedClassName={s.active_tab} className={s.tabtitle}>Limits</Tab>}
                    </TabList>


                    <TabPanel>
                        <KycPersonal/>
                    </TabPanel>
                    <TabPanel>
                        <ProfileSettings/>
                    </TabPanel>
                    {
                        // kycIsValid() &&
                        <TabPanel>
                            <Limits/>
                        </TabPanel>
                    }
                </Tabs>
            </motion.div>
        </div>
    );
};

export default Settings;
