export const ABOUT = "/about_us"
export const CAREERS = "/careers"
export const CONTACT_US = "/contact_us"
export const CONTACT_US_FOR_AGENTS = "/contact_us_for_agents"
export const FAQ = "/faq"
export const AGENT_PROGRAM = "/agent_program"
export const PERSONAL_ACCOUNT = "/account_info_personal"
export const BUSINESS_ACCOUNT = "/account_info_business"
export const PRICING = "/pricing"
export const PRICING_PERSONAL_ACCOUNT = "/pricing/pricing_personal"
export const PRICING_BUSINESS_ACCOUNT = "/pricing/pricing_business"
export const TERMS_AND_CONDITIONS = "/terms_and_conditions"
export const TERMS_AND_CONDITIONS_PERSONAL = "/terms_and_conditions/personal"
export const TERMS_AND_CONDITIONS_BUSINESS = "/terms_and_conditions/business"
export const PRIVACY_POLICY = "/privacy_policy"
export const COOKIE_POLICY = "/cookie_policy"
export const COMPLAINTS = "/complaints"
export const KYC_SUCCESS = "/kyc_success"
export const KYC_SUCCESS_BUSINESS = "/kyc_success_business"
export const KYC_DECLINED = "/kyc_declined"
export const KYC_FAILURE = "/kyc_failure"
export const FRAUD_AND_SCAM_ALERT = "/fraud_and_scam_alert"
export const KYB_FAILURE = '/kyb_failure'
export const KYB_SUCCESS = "/kyb_success"
export const KYB_DECLINED = "/kyb_declined"
