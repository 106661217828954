import * as yup from "yup";
import {amountValidation} from "./FormHelpers";

const regexDomesticGBP = /^[a-zA-Z0-9\/\-?:().,’+\s#=!"%&*<>;{@\r\n]*$/;
const regexDomesticEUR = /^(?!.*\/\/)(?!.*(^\/|\/$))(?!.*-\s)([A-Za-z0-9\/ -?:().,'+]+)$/;

const regexInternationalEURAndGBP = /^[A-Za-z0-9\s.()\-\/<+&$*%;@="\\]*$/

export const validationSchemaDomesticEUR  = {
    payerAccount: yup.string().required('Required field').matches(regexDomesticEUR, "Not allowed symbol") ,
    paymentRegions: yup.string().required('Required field').matches(regexDomesticEUR, "Not allowed symbol"),
    name: yup.string()
        .test('one-of-required', 'Required field', function(value) {
            return value || this.parent.companyName;
        })
        .matches(regexDomesticEUR, "Not allowed symbol")
        .max(70, "max 70 symbols"),
    surname: yup.string()
        .test('one-of-required', 'Required field', function(value) {
            return value || this.parent.companyName;
        })
        .matches(regexDomesticEUR, "Not allowed symbol")
        .max(70, "max 70 symbols"),
    companyName: yup.string()
        .test('one-of-required', 'Required field', function(value) {
            return value || this.parent.name || this.parent.surname;
        })
        .matches(regexDomesticEUR, "Not allowed symbol")
        .max(70, "max 70 symbols"),
    recipientsIban: yup.string().required('Required field').matches(regexDomesticEUR, "Not allowed symbol").max(34, "max 34 symbols"),
    recipientsSwiftBic: yup.string().required('Required field').matches(regexDomesticEUR, "Not allowed symbol").max(11, "max 11 symbols"),
    reference: yup.string().required('Required field').matches(regexDomesticEUR, "Not allowed symbol").max(140, "max 140 symbols"),
    templateName: yup.string().max(100, 'max 100 symbols'),
};
export const validationSchemaInternationalEUR  = {
    name: yup.string()
        .test('one-of-required', 'Required field', function(value) {
            return value || this.parent.companyName;
        })
        .matches(regexDomesticEUR, "Not allowed symbol")
        .max(70, "max 70 symbols"),
    surname: yup.string()
        .test('one-of-required', 'Required field', function(value) {
            return value || this.parent.companyName;
        })
        .matches(regexDomesticEUR, "Not allowed symbol")
        .max(70, "max 70 symbols"),
    companyName: yup.string()
        .test('one-of-required', 'Required field', function(value) {
            return value || this.parent.name || this.parent.surname;
        })
        .matches(regexDomesticEUR, "Not allowed symbol")
        .max(70, "max 70 symbols"),
    recipientsIban: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "Not allowed symbol").max(34, "max 34 symbols"),
    recipientsSwiftBic: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "Not allowed symbol").max(11, "max 11 symbols"),
    recipientsAddress: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "Not allowed symbol").max(105, "max 105 symbols"),
    recipientCity: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "Not allowed symbol").max(105, "max 105 symbols"),
    recipientPostCode: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "Not allowed symbol").max(105, "max 105 symbols"),
    recipientCountry: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "Not allowed symbol").max(105, "max 105 symbols"),
    bankName: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "Not allowed symbol").max(105, "max 105 symbols"),
    bankAddress: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "Not allowed symbol").max(105, "max 105 symbols"),
    bankCity: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "Not allowed symbol").max(105, "max 105 symbols"),
    bankPostCode: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "Not allowed symbol").max(105, "max 105 symbols"),
    bankCountry: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "Not allowed symbol").max(105, "max 105 symbols"),
    reference: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "Not allowed symbol").max(35, "max 35 symbols"),
    payerAccount: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "Not allowed symbol"),
    paymentRegions: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "Not allowed symbol"),
    templateName: yup.string().max(100, 'max 100 symbols').nullable().optional(),
};
export const validationSchemaDomesticGBP  = {
    payerAccount: yup.string().required('Required field').matches(regexDomesticGBP, "Not allowed symbol"),
    name: yup.string()
        .test('one-of-required', 'Required field', function(value) {
            return value || this.parent.companyName;
        })
        .matches(regexDomesticEUR, "Not allowed symbol")
        .max(70, "max 70 symbols"),
    surname: yup.string()
        .test('one-of-required', 'Required field', function(value) {
            return value || this.parent.companyName;
        })
        .matches(regexDomesticEUR, "Not allowed symbol")
        .max(70, "max 70 symbols"),
    companyName: yup.string()
        .test('one-of-required', 'Required field', function(value) {
            return value || this.parent.name || this.parent.surname;
        })
        .matches(regexDomesticEUR, "Not allowed symbol")
        .max(70, "max 70 symbols"),
    reference: yup.string().required('Required field').matches(regexDomesticGBP, "Not allowed symbol").max(18, "max 18 symbols"),
    recipientsAccountNumber: yup.string().required('Required field').matches(regexDomesticGBP, "Not allowed symbol").max(12, "max 12 symbols"),
    sortCode: yup.string().required('Required field').matches(regexDomesticGBP, "Not allowed symbol").max(6, "max 6 symbols"),
    paymentRegions: yup.string().required('Required field').matches(regexDomesticGBP, "Not allowed symbol"),
    templateName: yup.string().max(100, 'max 100 symbols').matches(regexDomesticGBP, "Not allowed symbol"),
};

export const validationSchemaInternationalGBP  = {
    name: yup.string()
        .test('one-of-required', 'Required field', function(value) {
            return value || this.parent.companyName;
        })
        .matches(regexDomesticEUR, "Not allowed symbol")
        .max(70, "max 70 symbols"),
    surname: yup.string()
        .test('one-of-required', 'Required field', function(value) {
            return value || this.parent.companyName;
        })
        .matches(regexDomesticEUR, "Not allowed symbol")
        .max(70, "max 70 symbols"),
    companyName: yup.string()
        .test('one-of-required', 'Required field', function(value) {
            return value || this.parent.name || this.parent.surname;
        })
        .matches(regexDomesticEUR, "Not allowed symbol")
        .max(70, "max 70 symbols"),
    recipientsAccountNumber: yup.string().required('Required field').matches(regexDomesticGBP, "Not allowed symbol").max(12, "max 12 symbols"),
    sortCode: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "Not allowed symbol"),
    recipientsIban: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "Not allowed symbol").max(34, "max 34 symbols"),
    recipientsSwiftBic: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "Not allowed symbol").max(11, "max 11 symbols"),
    reference: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "Not allowed symbol").max(35, "max 35 symbols"),
    paymentRegions: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "Not allowed symbol"),
    templateName: yup.string().max(100, 'max 100 symbols').matches(regexInternationalEURAndGBP, "Not allowed symbol"),
};

export const validationSchemaDomesticUSD  = {
    name: yup.string()
        .test('one-of-required', 'Required field', function(value) {
            return value || this.parent.companyName;
        })
        .matches(regexDomesticEUR, "Not allowed symbol")
        .max(70, "max 70 symbols"),
    surname: yup.string()
        .test('one-of-required', 'Required field', function(value) {
            return value || this.parent.companyName;
        })
        .matches(regexDomesticEUR, "Not allowed symbol")
        .max(70, "max 70 symbols"),
    companyName: yup.string()
        .test('one-of-required', 'Required field', function(value) {
            return value || this.parent.name || this.parent.surname;
        })
        .matches(regexDomesticEUR, "Not allowed symbol")
        .max(70, "max 70 symbols"),
    recipientsAccountNumber: yup.string().required('Required field').matches(regexDomesticGBP, "Not allowed symbol").max(12, "max 12 symbols"),
    sortCode: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "Not allowed symbol"),
    reference: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "Not allowed symbol").max(35, "max 35 symbols"),
    paymentRegions: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "Not allowed symbol"),
    templateName: yup.string().max(100, 'max 100 symbols').matches(regexInternationalEURAndGBP, "Not allowed symbol"),
};

export const defaultValidation  = yup.object({
    payerAccount: yup.string().required('Required field'),
    paymentRegions: yup.string().required('Required field'),
});

export const amountValidationSchema  = yup.object({
    amount: amountValidation
});
