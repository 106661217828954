import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Formik} from "formik";
import s from "./BankTransactionTemplate.module.css";
import styles from "../../../styles/styles.module.css";
import {Popover} from "antd";
import info from "../../../assets/img/personalAccount/information.svg";
import classnames from "classnames";
import {motion} from "framer-motion";
import {useNavigate} from "react-router-dom";
import TransparentButton from "../../ui/TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import {ReactComponent as Back} from "../../../assets/img/personalAccount/back.svg";
import MyBtn from "../../ui/MyBtn/MyBtn";
import CustomDropdown from "../../ui/CustomDropdown/CustomDropdown";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import CustomDropdownForAccounts from "../../ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import MyInput from "../../ui/MyInput/MyInput";
import {createTemplate, getTemplateByIdThunk, sendPinCodeEmailTrans, SendTransferThunk} from "../../../store/reducers/ActionCreators";
import {PrepareDataForPaymentTemplate} from "../../../helpers/PrepareDataForPaymentTemplate";
import {ITemplate} from "../../../types/interfaces";
import {typesList} from "../../../Constants/StaticData";
import {
    getValidationSchemaForTemplates
} from "../../BankTransferFormPersonal/FormHelpersForTemplates/FormHelpersForTemplates";
import {SwiftCheckerHelper} from "../../BankTransferFormPersonal/FormHelpers/FormHelpers";
import {PrepareDataForBankTransaction} from "../../../helpers/PrepareDataForAzaTranzactions";
import {setDisabled} from "../../../store/reducers/UserSlice";
import {useSelector} from "react-redux";
import {selectAccountsByFilter} from "../../../store/reducers/TransactionsSlice/selector";
import {Persist} from "formik-persist";
import CustomModal from "../../ui/CustomModal/CustomModal";
import iconSuccess from "../../../assets/img/personalAccount/tick-circle.svg"
import { areLimitsExceeded } from '../../../helpers/LimitsHelper';
import PopUpEmailCodConfirm from '../../ui/PopUpEmailCodConfirm/PopUpEmailCodFonfirm';
import { calculateCurrentDate } from '../../../helpers/CalculateDate';
import DomesticEur from "../../BankTransferFormPersonal/FieldsForPaymentTypes/DomesticEur";
import InternationalEur from "../../BankTransferFormPersonal/FieldsForPaymentTypes/InternationalEur";
import DomesticGbp from "../../BankTransferFormPersonal/FieldsForPaymentTypes/DomesticGbp";
import InternationalGbp from "../../BankTransferFormPersonal/FieldsForPaymentTypes/InternationalGbp";
import CustomCheckbox from "../../ui/customCheckbox/CustomCheckbox";
import DomesticUsd from "../../BankTransferFormPersonal/FieldsForPaymentTypes/DomesticUsd";
import {amountValidationSchema} from "../../BankTransferFormPersonal/FormHelpers/ValidationSchemas";
import {clearFormByChangeType} from "../../../helpers/clearFormHelper";

type PropsType = {
    setCurrent?: Dispatch<SetStateAction<number>>
    setTransferData?: Dispatch<SetStateAction<any>>
    id?: string | number;
    templateName: string,
    setPurpose: Dispatch<SetStateAction<string>>
    isButtonDisable: boolean
    withdrawalStatus: string
    code: string
}

const CurrencyHelper = (props: any) => {

    useEffect(() => {
        props.accountsList.map((account: any) => {

            if (account.number === props.hiddenAccountNumberFrom) {
                props.setCurrency(account.currency)
            }
        })
    }, [props.accountsList, props.hiddenAccountNumberFrom])

    useEffect(() => {
        props.accountsList.map((account: any) => {
            if (account.number === props.payerAccount) {
                props.setCurrency(account.currency)
            }
        })
    }, [])

    return null
}

const BankTransactionTemplate = (props: PropsType) => {
    const navigate = useNavigate()

    const [paymentBankTemplate, setPaymentBankTemplate] = useState<ITemplate>()
    const [paymentRegion, setPaymentRegion] = useState("")
    const [isShowSuccessModal, setIsShowSuccessModal] = useState(false)
    const [isShowPaymentSentSuccessModal, setIsShowPaymentSentSuccessModal] = useState(false)

    const accountsList = useSelector(selectAccountsByFilter)
    const token = useAppSelector(state => state.userReducer.userToken)
    const accountId = useAppSelector(state => state.userReducer.chosenAccountTypeId)
    const dispatch = useAppDispatch()
    const [isMobile, setIsMobile] = useState(false)
    const [currency, setCurrency] = useState('')
    const [accountNumber, setAccountNumber] = useState('')
    const [localyDisabled, setLocalyDisabled] = useState(false)

    const limitsInfo = useAppSelector(state => state.userReducer.userLimits);
    const [isLimitPopupOpen, setIsLimitPopupOpen] = useState<boolean | null>(false)
    const [limitType, setLimitType] = useState<string | null>('');

    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState<boolean | null>(false)
    const [isShowEmailPopUpCode, setIsShowEmailPopUpCode] = useState(false)

    const [isEmailCodeValid, setIsemailCodeValid] = useState('')
    const [isIbanWrong, setIsIbanWrong] = useState(false)
    const [selectedOptionRecipientType, setSelectedOptionRecipientType] = useState("INDIVIDUAL")


    const showEmailPopUpCodeVer = () => {
        setIsShowEmailPopUpCode(true)
        dispatch(sendPinCodeEmailTrans(token))
    }

    const getEmailPin = (emailCode: string) => {
        setIsemailCodeValid(emailCode)

    }

    const closeEmailPinPopUp = () => {
        setIsShowEmailPopUpCode(false)
        setLocalyDisabled(false)
    }

    const clearEmailPin = () => {
        setIsemailCodeValid('')
        setIsErrorPopupOpen(false)
    }

    const errorAndRedirect = () => {
        setIsLimitPopupOpen(false)
        localStorage.removeItem("transfer-form")
        navigate('/personal_account/templates')
    }

    useEffect(() => {
        if (props.id !== 'create') {
            areLimitsExceeded(limitsInfo, setLimitType, setIsLimitPopupOpen);
        }
    })
    useEffect(() => {
        if (props.id !== 'create') {
            dispatch(getTemplateByIdThunk(token, props.id, accountId))
                .then((res) => {
                    if (res.data.type === 'Internal') {
                        props.setPurpose('Transfer to LuxPay User')
                    }
                    else {
                        setPaymentBankTemplate(res.data)
                    }
                })
        }
        return () => {
            localStorage.removeItem('transfer-form')
        }
    }, [])

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [window.innerWidth])

    useEffect(() => {
        if (paymentBankTemplate) {
          const type = paymentBankTemplate.recipientType ||
                      paymentBankTemplate.recipientDetails?.recipientType;
          if (type) {
            setSelectedOptionRecipientType(type);
          }
        }
      }, [paymentBankTemplate]);

    // domestic USD

    const [paymentRegionOptions, setPaymentRegionOptions] = useState(typesList)

     useEffect(() => {

                if (currency === 'USD') {

                    setPaymentRegionOptions(['domestic']);
                    setPaymentRegion('domestic')
                } else if (currency !== 'USD') {

                    setPaymentRegionOptions(typesList);

                } else {

                    setPaymentRegionOptions(typesList);
                }
            }, [currency]);

    // domestic USD

    const myInitValues = {
        fullName: paymentBankTemplate?.recipientDetails?.beneficiaryName,
        recipientsIban: paymentBankTemplate?.recipientDetails?.iban || paymentBankTemplate?.recipientDetails?.ibanCode,
        recipientsSwiftBic: paymentBankTemplate?.recipientDetails?.swiftCode,
        reference: paymentBankTemplate?.recipientDetails?.reference,
        recipientsAddress: paymentBankTemplate?.recipientDetails?.beneficiaryAddress,
        recipientsAccountNumber: paymentBankTemplate?.recipientDetails?.beneficiaryAccountNumber,
        sortCode: paymentBankTemplate?.recipientDetails?.beneficiarySortCode,
        paymentRegions: paymentBankTemplate?.paymentType,
        payerAccount: paymentBankTemplate?.fromAccountMainIban,
        hiddenAccountNumberFrom: paymentBankTemplate?.account,
        name: paymentBankTemplate?.recipientDetails.firstName,
        surname: paymentBankTemplate?.recipientDetails.lastName,
        recipientPostCode: paymentBankTemplate?.recipientDetails.recipientPostCode,
        companyName: paymentBankTemplate?.recipientDetails.companyName,
        bankName: paymentBankTemplate?.recipientDetails.bankName,
        bankAddress: paymentBankTemplate?.recipientDetails.bankAddress,
        bankCity: paymentBankTemplate?.recipientDetails.bankCity,
        bankPostCode: paymentBankTemplate?.recipientDetails.bankPostCode,
        bankCountry: paymentBankTemplate?.recipientDetails.bankCountry,
        invoice: '',
        type: 'international',
        recipientCity: paymentBankTemplate?.recipientDetails.beneficiaryCity,
        recipientCountry: paymentBankTemplate?.recipientDetails.beneficiaryCountry,
        amount: '',
        recipientType: paymentBankTemplate?.recipientType || paymentBankTemplate?.recipientDetails?.recipientType || 'INDIVIDUAL',
    }

    const closeAndRedirect = () => {
        setIsShowSuccessModal(false)
        setIsShowPaymentSentSuccessModal(false)
        navigate(-1)
    }

    return (
        <div className={s.form_wrapper}>

            <CustomModal isModalOpen={isShowSuccessModal} type="confirm">
                <div className={classnames(styles.modal_content, s.modal_content_center)}>
                    <img src={iconSuccess} alt="" className={styles.popup_icon}/>
                    <div className={styles.popup_title}>
                        Success!
                    </div>
                    <div className={styles.popup_subtitle}>
                        Your template has been added successfully.
                    </div>
                    <div className={styles.popup_button_block}>
                        <MyBtn title={"Ok"}
                               medium
                               isPersonalAccountBtn
                               onClick={() => closeAndRedirect()}
                        />
                    </div>
                </div>
            </CustomModal>

            <CustomModal isModalOpen={isShowPaymentSentSuccessModal} isNotify>
                <div className={styles.modal_content}>
                    <div className={styles.popup_subtitle}>
                        <span className={styles.success}>Success!</span> Your request has been sent
                        successfully.
                    </div>
                    <div className={styles.popup_button_block}>
                        <MyBtn
                            isPersonalAccountBtn
                            title={"Ok"}
                            onClick={() => closeAndRedirect()}
                        />
                    </div>
                </div>
            </CustomModal>

            <CustomModal type="error" isNotify isModalOpen={isLimitPopupOpen} setIsModalOpen={setIsLimitPopupOpen}>
                <div className={styles.modal_content}>
                    <div className={styles.popup_subtitle}>
                        <span className={styles.error}>Failed!</span> The transaction is impossible due to exceeding {limitType} limit.
                        Please contact <a className={styles.popup_link} href='mailto:support@luxpay.lt'>support@luxpay.lt</a>&nbsp;
                        if you have any questions.
                    </div>
                    <div className={styles.popup_button_block}>
                        <MyBtn buttonType="error" isPersonalAccountBtn title={"Ok"} onClick={() => errorAndRedirect()} />
                    </div>
                </div>
            </CustomModal>

            <CustomModal type="error" isModalOpen={isErrorPopupOpen} setIsModalOpen={setIsErrorPopupOpen}>
                <div className={styles.modal_content}>
                    <div className={styles.popup_subtitle}>
                        <span className={styles.error}>Failed!</span> {isEmailCodeValid ? "Your email code hasn’t been verified. Please try again" : "Your request was rejected. Please try again."}
                    </div>
                    <div className={styles.popup_button_block}>
                        <MyBtn buttonType="error" isPersonalAccountBtn title={"Ok"} onClick={clearEmailPin}/>
                    </div>
                </div>
            </CustomModal>

            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
            >
                <Formik
                    enableReinitialize={true}
                    initialValues={myInitValues}
                    validationSchema={props.id === "create" ? getValidationSchemaForTemplates(paymentRegion, currency) : amountValidationSchema}
                    onSubmit={async (values, formikHelpers) => {

                        if (isIbanWrong) {
                            return
                        }
                        setLocalyDisabled(true)

                        // dispatch(setDisabled(true))
                        const tempData = {
                            ...values,
                            templateName: props.templateName,
                            transferCurrency: currency,
                            accountId: accountId,
                        }


                        if (props.id === 'create') {

                            const secondTempData = {
                                ...tempData,
                                hiddenAccountNumberFrom: accountNumber,
                            }

                            const preparedData = PrepareDataForPaymentTemplate(secondTempData)

                            dispatch(createTemplate(token, preparedData))
                                .then((res) => {
                                    if (res.data === 'Created') {
                                        setIsShowSuccessModal(true)
                                        formikHelpers.resetForm()
                                    }
                                })
                                .finally(() => {

                                    setLocalyDisabled(false)
                                    dispatch(setDisabled(false))
                                })
                        } else {

                            if (isEmailCodeValid.length === 0) return

                            const currentData = calculateCurrentDate()
                            const secondTempData = {
                                ...values,
                                templateName: props.templateName,
                                transferCurrency: currency,
                                accountId: accountId,
                                emailCode: isEmailCodeValid,
                                userDatetime: currentData
                            }

                            const preparedData = PrepareDataForBankTransaction(
                                secondTempData,
                                props.code,
                                props.withdrawalStatus,
                            )

                            dispatch(SendTransferThunk(token, await preparedData, accountId))
                                .then((res) => {

                                    if (res.data === 'Created') {
                                        formikHelpers.resetForm()
                                        setIsShowPaymentSentSuccessModal(true)
                                    } else {
                                        console.error(`res.data :`, res.data)
                                    }
                                })
                                .catch((e) => {
                                    if (e.response.data === "Invalid email code") {
                                        setIsemailCodeValid(e.response.data)
                                        setIsErrorPopupOpen(true)
                                    }
                                })
                                .finally(() => {
                                    dispatch(setDisabled(false))
                                })
                        }
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          setFieldValue,
                          setValues
                      }) =>

                        (
                        <form onSubmit={handleSubmit}>
                            <div className={s.row}>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        <span><span className={s.red}>*</span> Payer account</span>
                                        <Popover content={"Please select the account you wish to make a transfer from."}
                                                 color={'#EEEFEF'}
                                                 overlayInnerStyle={{
                                                     fontFamily: 'Manrope',
                                                     width: '280px',
                                                     padding: '13px 16px',
                                                     cursor: 'pointer',
                                                     backgroundColor: '#E7F0EC'
                                                 }}
                                        >
                                            <img src={info} alt=""/>
                                        </Popover>
                                    </div>
                                    <CustomDropdownForAccounts
                                        items={accountsList}
                                        placeholder={"Please select the account"}
                                        id="payerAccount"
                                        name="payerAccount"
                                        isError={errors.payerAccount}
                                        touched={touched.payerAccount}
                                        setAccountNumber={setAccountNumber}
                                        disabled={props.id !== 'create'}
                                        setCurrency={(selectedCurrency) => {
                                            setCurrency(selectedCurrency);
                                        }}
                                    />
                                    {errors.payerAccount && touched.payerAccount &&
                                        <div className={s.error_message}>{errors.payerAccount}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        <span><span className={s.red}>*</span> Payment type</span>
                                    </div>
                                    <CustomDropdown
                                        customId="paymentRegions"
                                        items={paymentRegionOptions}
                                        placeholder={"Please select the payment type"}
                                        id="paymentRegions"
                                        name="paymentRegions"
                                        isError={errors.paymentRegions}
                                        touched={touched.paymentRegions}
                                        setItem={setPaymentRegion}
                                        disable={props.id !== 'create'}
                                    />
                                    {errors.paymentRegions && touched.paymentRegions &&
                                        <div className={s.error_message}>{errors.paymentRegions}</div>}
                                </div>

                            </div>

                            {
                                values.paymentRegions !== "" && currency !== "" &&
                                <div>
                                    <div className={classnames(s.input_block, s.recipient_type_block)}>
                                        <div className={s.input_label}>
                                            <span>RECIPIENT TYPE</span>
                                        </div>
                                        <div className={s.flex_label}>
                                            <label className={s.flex_label}>
                                                <CustomCheckbox
                                                    handleChange={() => {

                                                        if (props.id !== 'create') {
                                                            return
                                                        }

                                                        clearFormByChangeType(values, setValues)

                                                        setSelectedOptionRecipientType(
                                                            "INDIVIDUAL"
                                                        );
                                                        setFieldValue(
                                                            "companyName",
                                                            ""
                                                        );
                                                        setFieldValue(
                                                            "recipientType",
                                                            "INDIVIDUAL"
                                                        );
                                                    }}
                                                    id="INDIVIDUAL"
                                                    name="INDIVIDUAL"
                                                    isChecked={selectedOptionRecipientType === "INDIVIDUAL" || selectedOptionRecipientType === undefined}
                                                />
                                                <span
                                                    className={
                                                        selectedOptionRecipientType ===
                                                        "INDIVIDUAL"
                                                            ? s.type_of_payment_active
                                                            : s.type_of_payment_inactive
                                                    }
                                                >
                                                        INDIVIDUAL
                                                    </span>
                                            </label>
                                            <label className={s.flex_label}>
                                                <CustomCheckbox
                                                    handleChange={() => {

                                                        if (props.id !== 'create') {
                                                            return
                                                        }

                                                        clearFormByChangeType(values, setValues)

                                                        setSelectedOptionRecipientType(
                                                            "BUSINESS"
                                                        );
                                                        setFieldValue(
                                                            "name",
                                                            ""
                                                        );
                                                        setFieldValue(
                                                            "surname",
                                                            ""
                                                        );
                                                        setFieldValue(
                                                            "recipientType",
                                                            "BUSINESS"
                                                        );
                                                    }}
                                                    id="BUSINESS"
                                                    name="BUSINESS"
                                                    isChecked={
                                                        selectedOptionRecipientType ===
                                                        "BUSINESS"
                                                    }
                                                />
                                                <span
                                                    className={
                                                        selectedOptionRecipientType ===
                                                        "BUSINESS"
                                                            ? s.type_of_payment_active
                                                            : s.type_of_payment_inactive
                                                    }
                                                >
                                                        BUSINESS
                                                    </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            }

                            {/*Domestic (for EUR)*/}
                            {
                                values.paymentRegions === "domestic" && currency === "EUR" &&
                                <DomesticEur
                                    values={values}
                                    currency={currency}
                                    touched={touched}
                                    errors={errors}
                                    handleChange={handleChange}
                                    isIbanWrong={isIbanWrong}
                                    disabled={props.id !== 'create'}
                                    isTemplateCreation={props.id === 'create'}
                                />
                            }

                            {/*International (for EUR)*/}
                            {
                                values.paymentRegions === "international" && currency === "EUR" &&
                                <InternationalEur
                                    values={values}
                                    currency={currency}
                                    touched={touched}
                                    errors={errors}
                                    handleChange={handleChange}
                                    disabled={props.id !== 'create'}
                                    isIbanWrong={isIbanWrong}
                                    isTemplateCreation={props.id === 'create'}
                                />
                            }

                            {/*Domestic (for GBP)*/}
                            {
                                values.paymentRegions === "domestic" && currency === "GBP" &&
                                <DomesticGbp
                                    values={values}
                                    currency={currency}
                                    touched={touched}
                                    errors={errors}
                                    handleChange={handleChange}
                                    disabled={props.id !== 'create'}
                                    isIbanWrong={isIbanWrong}
                                    isTemplateCreation={props.id === 'create'}
                                />
                            }

                            {/*International (for GBP)*/}
                            {
                                values.paymentRegions === "international" && currency === "GBP" &&
                                <InternationalGbp
                                    values={values}
                                    currency={currency}
                                    touched={touched}
                                    errors={errors}
                                    handleChange={handleChange}
                                    disabled={props.id !== 'create'}
                                    isIbanWrong={isIbanWrong}
                                    isTemplateCreation={props.id === 'create'}
                                />
                            }

                            {/*domestic (for USD)*/}
                            {
                                values.paymentRegions === "domestic" && currency === "USD" &&
                                <DomesticUsd
                                    values={values}
                                    currency={currency}
                                    touched={touched}
                                    errors={errors}
                                    handleChange={handleChange}
                                    disabled={props.id !== 'create'}
                                    isIbanWrong={isIbanWrong}
                                />
                            }

                            {
                                props.id !== 'create' ?
                                    <div className={s.row}>

                                        <div className={s.mobile_row}>
                                            <div className={classnames(s.input_block, s.short)}>
                                                <div className={s.input_label}>
                                                    <span><span className={s.red}>*</span> Amount</span>
                                                    <Popover
                                                        content={"Select a currency and enter the transfer amount.\n" +
                                                            "In the account balance in the selected currency is insufficient, the system " +
                                                            "will automatically offer you to top up using funds in a different currency " +
                                                            "you have on the account."}
                                                        color={'#EEEFEF'}
                                                        overlayInnerStyle={{
                                                            fontFamily: 'Manrope',
                                                            width: '280px',
                                                            padding: '13px 16px',
                                                            cursor: 'pointer',
                                                            backgroundColor: '#E7F0EC'
                                                        }}
                                                    >
                                                        <img src={info} alt=""/>
                                                    </Popover>
                                                </div>
                                                <MyInput id={"amount"}
                                                         name={"amount"}
                                                         value={values.amount}
                                                         onChange={handleChange}
                                                         isError={errors.amount}
                                                />
                                                {errors.amount && <div className={s.error_label}>{errors.amount}</div>}
                                            </div>

                                            <div className={s.languages_dropdown}>
                                                <MyInput
                                                    id="transferCurrency"
                                                    name="transferCurrency"
                                                    value={currency}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    : <div className={s.row}>

                                        <div className={s.mobile_row}>
                                            <div className={s.languages_dropdown}>
                                                <MyInput
                                                    id="transferCurrency"
                                                    name="transferCurrency"
                                                    value={currency}
                                                />
                                            </div>

                                            <div className={classnames(s.input_block, s.short)}>

                                            </div>

                                        </div>
                                    </div>

                            }

                            <div className={s.continue_block}>
                                <div className={s.required}>
                                    <div>
                                        <span><span className={s.red}>*</span> required fields.</span>
                                    </div>
                                </div>
                            </div>

                            <div className={s.buttonsWrapper}>
                                <div className={s.button_block}>
                                    <TransparentButton title={'Back'}
                                                       isPersonalAccountBtn
                                                       large
                                                       icon={<Icon component={() => <Back/>} rev={undefined}/>}
                                                       onClick={() => {
                                                           navigate(-1);
                                                       }}/>
                                </div>

                                <MyBtn title={props.id === 'create' ? 'Save' : 'Continue'}
                                       large
                                       localDisable={(props.id !== 'create' && props.isButtonDisable) || localyDisabled}
                                       style={{width: isMobile ? '100%' : 180}}
                                       type="submit"
                                       isPersonalAccountBtn
                                       onClick={() => {

                                           if (props.id !== 'create' && Object.keys(errors).length === 0) {
                                               showEmailPopUpCodeVer();
                                           } else {
                                               handleSubmit();
                                           }
                                       }}
                                />
                            </div>
                            {isShowEmailPopUpCode ?
                               ( <PopUpEmailCodConfirm getPinEmail={getEmailPin} onClose={closeEmailPinPopUp}
                                submitForm={handleSubmit}     />
                                ) : null}
                            <Persist name="transfer-form"/>

                            <CurrencyHelper
                                accountsList={accountsList}
                                payerAccount={values.payerAccount}
                                hiddenAccountNumberFrom={values.hiddenAccountNumberFrom}
                                setCurrency={setCurrency}
                            />

                            <SwiftCheckerHelper
                                setFieldValue={setFieldValue}
                                setIsIbanWrong={setIsIbanWrong}
                                isAdditionalFields
                                currency={currency}
                            />

                        </form>
                    )}
                </Formik>
            </motion.div>
        </div>
    );
};

export default BankTransactionTemplate;
