import {Dispatch, memo, SetStateAction, useLayoutEffect, useRef} from "react";
import {useFormikContext} from "formik";
import {useAppDispatch} from "../../../../../hooks/redux";
import {useSelector} from "react-redux";
import {selectAccountsByFilter} from "../../../../../store/reducers/TransactionsSlice/selector";

type propsType = {
    accountNumberFrom: string
    accountNumberTo: string
    setAccountNumberTo: Dispatch<SetStateAction<string>>
    setAccountNumberFrom: Dispatch<SetStateAction<string>>
}

const ReverseAccountsHelper = memo((props: propsType) => {

    const {values, setFieldValue} = useFormikContext();

    const accountsForSelect = useSelector(selectAccountsByFilter)

    const prevAccountFromRef = useRef(props.accountNumberFrom);
    const prevAccountToRef = useRef(props.accountNumberTo);

    useLayoutEffect(() => {
        if (props.accountNumberFrom === '' && props.accountNumberTo === '') {
            return;
        }

        const actualAccountFrom = accountsForSelect.find((account: { number: string; }) => account.number === props.accountNumberFrom);
        const actualAccountTo = accountsForSelect.filter((account: { number: string; }) => account.number !== props.accountNumberFrom);

        if (actualAccountFrom && props.accountNumberFrom === props.accountNumberTo) {
            setFieldValue('accNumberTo', actualAccountTo[0].accountMainIban);
            setFieldValue('accNumberToCurrency', actualAccountTo[0].currency);
            props.setAccountNumberTo(actualAccountTo[0].number);
        }

        // Обновляем ссылку на предыдущее значение
        prevAccountFromRef.current = props.accountNumberFrom;
    }, [props.accountNumberFrom, accountsForSelect]);

    useLayoutEffect(() => {
        if (props.accountNumberFrom === '' && props.accountNumberTo === '') {
            return;
        }

        const actualAccountTo = accountsForSelect.find((account: { number: string; }) => account.number === props.accountNumberTo);
        const actualAccountFrom = accountsForSelect.filter((account: { number: string; }) => account.number !== props.accountNumberTo);

        if (actualAccountTo && props.accountNumberFrom === props.accountNumberTo) {
            setFieldValue('accNumberFrom', actualAccountFrom[0].accountMainIban);
            setFieldValue('accNumberFromCurrency', actualAccountFrom[0].currency);
            props.setAccountNumberFrom(actualAccountFrom[0].number);
        }

        // Обновляем ссылку на предыдущее значение
        prevAccountToRef.current = props.accountNumberTo;
    }, [props.accountNumberTo, accountsForSelect]);
    return null
});

export default ReverseAccountsHelper;
