import React, {Dispatch, SetStateAction, useEffect, useLayoutEffect, useState} from 'react';
import {motion} from "framer-motion";
import s from './BankTransferStepTwo.module.css'
import MyBtn from "../../../../../components/ui/MyBtn/MyBtn";
import {ReactComponent as Back} from '../../../../../assets/img/personalAccount/back.svg'
import Icon from "@ant-design/icons";
import TransparentButton from "../../../../../components/ui/TransparentButton/TransparentButton";
import {ITransacton} from "../../../../../types/interfaces";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/redux";
import {sendPinCodeEmailTrans, SendTransferThunk, verifyOldEmailThunk} from "../../../../../store/reducers/ActionCreators";
import CustomModal from "../../../../../components/ui/CustomModal/CustomModal";
import {
    PrepareDataForBankTransaction
} from "../../../../../helpers/PrepareDataForAzaTranzactions";
import {setDisabled} from "../../../../../store/reducers/UserSlice";
import {areLimitsExceeded} from "../../../../../helpers/LimitsHelper";
import ChangeEmail from '../../../../../components/ChangeEmail/ChangeEmail';
import PopUpEmailCodConfirm from '../../../../../components/ui/PopUpEmailCodConfirm/PopUpEmailCodFonfirm';
import { calculateCurrentDate } from '../../../../../helpers/CalculateDate';

type PropsType = {
    setCurrent: Dispatch<SetStateAction<number>>
    current: number
    transferData: ITransacton
    code: string
    withdrawalStatus: string
}

const BankTransferStepTwo = (props: PropsType) => {

    const dispatch = useAppDispatch()
    const token = useAppSelector(state => state.userReducer.userToken)
    const accountId = useAppSelector(state => state.userReducer.chosenAccountTypeId)
    const limitsInfo = useAppSelector(state => state.userReducer.userLimits);
    const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState<boolean | null>(false)
    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState<boolean | null>(false)
    const [isLimitPopupOpen, setIsLimitPopupOpen] = useState<boolean | null>(false)
    const [isNotEnoughMoney, setIsNotEnoughMoney] = useState<boolean | null>(false)
    const [limitType, setLimitType] = useState<string | null>('');

    const [showModalVerfyTransfer, setIsShowVerifyModalTransfer] = useState<boolean | null >(false)
    const [emailPin, setEmailPin] = useState('')

    const [isEmailValid, setIsEmailValid] = useState('')

    const toReadableFormat = (str:string) => {
        return str
            .replace(/([A-Z])/g, ' $1')
            .trim() // Убираем лишние пробелы
            .replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const getPinEmail = (emailCode: string) => {

        setEmailPin(emailCode)

        sendTransferData(emailCode)
    }

    useLayoutEffect(() => {
        areLimitsExceeded(limitsInfo, setLimitType, setIsLimitPopupOpen)
    }, [limitsInfo])

    const showVerifyTransactionEmail = () => {
            setIsShowVerifyModalTransfer(true)
            dispatch(sendPinCodeEmailTrans(token))
    };

    const clearEmailPin = () => {

        setIsEmailValid('')
        setIsErrorPopupOpen(false)
    }

    useEffect(() => {

    }, [isEmailValid]);

    const sendTransferData = async (emailCode: string) => {
        // dispatch(setDisabled(true))

        const userDatetime = calculateCurrentDate()


        const preparedData = await PrepareDataForBankTransaction(props.transferData, props.code, props.withdrawalStatus)
        const ResponseObj = {...preparedData, emailCode, userDatetime}

        dispatch(SendTransferThunk(token, ResponseObj, accountId))
            .then((res) => {
                if (res.data === 'Created') {
                    setIsSuccessPopupOpen(true)
                } else {
                    setIsErrorPopupOpen(true)
                }
            })
            .catch((e) => {
                if (e.response.data === "Not enough money to create transaction") {
                    setIsNotEnoughMoney(true)
                    return
                }
                if (e.response.data === "Daily Limit") {
                    setLimitType("daily")
                    setIsLimitPopupOpen(true)
                    return
                }
                if (e.response.data === "Monthly Limit") {
                    setLimitType("monthly")
                    setIsLimitPopupOpen(true)
                    return
                }
                if (e.response.data === "Invalid email code") {

                    setIsEmailValid(e.response.data)
                    setIsErrorPopupOpen(true)
                    return
                }
                else {setIsErrorPopupOpen(true)}
            })
            .finally(() => {
                localStorage.removeItem("transfer-form")
                dispatch(setDisabled(false))
            })
    }

    const closeAndRedirect = () => {
        setIsSuccessPopupOpen(false)
        localStorage.removeItem("transfer-form")
        props.setCurrent(0)
    }


    const errorAndRedirect = () => {
        setIsLimitPopupOpen(false)
        localStorage.removeItem("transfer-form")
        props.setCurrent(0)
    }

    const closeEmailPinPopUp = () => {
        setIsShowVerifyModalTransfer(false)
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >

            <CustomModal isNotify isModalOpen={isSuccessPopupOpen} setIsModalOpen={setIsSuccessPopupOpen}>
                <div className={s.modal_content}>
                    <div className={s.popup_subtitle}>
                        <span className={s.success}>Success!</span> Your request has been sent successfully.
                    </div>
                    <div className={s.popup_button_block}>
                        <MyBtn title={'Ok'} isPersonalAccountBtn medium onClick={() => closeAndRedirect()}/>
                    </div>
                </div>
            </CustomModal>

            <CustomModal type="error" isModalOpen={isErrorPopupOpen} setIsModalOpen={setIsErrorPopupOpen}>
                <div className={s.modal_content}>
                    <div className={s.popup_subtitle}>
                        <span className={s.error}>Failed!</span> {isEmailValid ? "Your email code hasn’t been verified. Please try again" : "Your request was rejected. Please try again."}
                    </div>
                    <div className={s.popup_button_block}>
                        <MyBtn buttonType="error" isPersonalAccountBtn title={"Ok"} onClick={clearEmailPin}/>
                    </div>
                </div>
            </CustomModal>

            <CustomModal type="error" isNotify isModalOpen={isNotEnoughMoney} setIsModalOpen={setIsErrorPopupOpen}>
                <div className={s.modal_content}>
                    <div className={s.popup_subtitle}>
                        <span className={s.error}>Failed!</span> The transfer cannot be made due to insufficient funds.
                    </div>
                    <div className={s.popup_button_block}>
                        <MyBtn buttonType="error" isPersonalAccountBtn title={"Ok"} onClick={() => setIsNotEnoughMoney(false)}/>
                    </div>
                </div>
            </CustomModal>

            <CustomModal type="error" isNotify isModalOpen={isLimitPopupOpen} setIsModalOpen={setIsLimitPopupOpen}>
                <div className={s.modal_content}>
                    <div className={s.popup_subtitle}>
                        <span className={s.error}>Failed!</span> The transaction is impossible due to exceeding {limitType} limit.
                        Please contact <a className={s.popup_link} href='mailto:support@luxpay.lt'>support@luxpay.lt</a>&nbsp;
                        if you have any questions.
                    </div>
                    <div className={s.popup_button_block}>
                        <MyBtn buttonType="error" isPersonalAccountBtn title={"Ok"} onClick={() => errorAndRedirect()} />
                    </div>
                </div>
            </CustomModal>

            <div className={s.title}>
                Transfer Information
            </div>
            <div className={s.data_block}>

                {Object.entries(props.transferData).map(([key, value]) => {
                    if (value !== ""
                        && key !== "hiddenAccountNumberFrom"
                        && key !== "recipientType"
                        && key !== "paymentRegions"
                        && key !== "payerAccountCurrency"
                        && key !== "transferCurrency"
                        && key !== "accountId"
                        && key !== "amount"
                        && key !== "type"
                    ) {
                        return (
                            <div className={s.row} key={key}>
                                <span className={s.row_name}>{toReadableFormat(key)}</span>
                                <span className={s.row_value}>{value}</span>
                            </div>
                    )}
                    if (key === "amount") {
                        return (
                            <div className={s.row} key={key}>
                                <span className={s.row_name}>{toReadableFormat(key)}</span>
                                <span className={s.row_value}>{value} {props.transferData.transferCurrency}</span>
                            </div>
                        )}
                    return null;
                })}

            </div>
            <div className={s.buttons_block}>
                <div className={s.button_wrapper}>
                    <TransparentButton
                        isPersonalAccountBtn
                        icon={<Icon component={() => <Back/>} rev={undefined}/>}
                        large
                        title={"Back"}
                        onClick={() => props.setCurrent(0)}/>
                </div>

                <div className={s.button_wrapper}>
                    {/* <MyBtn large title={"Transfer"} isPersonalAccountBtn onClick={() => sendTransferData()}/> */}
                    <MyBtn large title={'Transfer'} isPersonalAccountBtn  onClick={showVerifyTransactionEmail}  />
                    {showModalVerfyTransfer ?
                    <PopUpEmailCodConfirm  getPinEmail={getPinEmail} onClose={closeEmailPinPopUp}></PopUpEmailCodConfirm>
                   :
                     null}
                </div>
            </div>
        </motion.div>
    );
};

export default BankTransferStepTwo;
