import React, {useState} from 'react';
import ExchangeStepOne from "./ExchangeStepOne/ExchangeStepOne";
import ExchangeStepTwo from "./ExchangeStepTwo/ExchangeStepTwo";
import s from './CurrencyExchange.module.css'

const CurrencyExchange = () => {

    const [current, setCurrent] = useState(0);
    const [exchangeData, setExchangeData] = useState([])

    const steps = [
        {
            content: <ExchangeStepOne setCurrent={setCurrent}
                                      current={current}
                                      setExchangeData={setExchangeData}

            />
        },
        {
            content: <ExchangeStepTwo setCurrent={setCurrent}
                                      current={current}
                                      exchangeData={exchangeData}
            />
        }
    ]

    return (
        <div>
            <div className={s.info_text}>
                        Below in the currency calculator you can check the exchange 
                        rates offered by LuxPay. Provided amounts include <a href="https://bank.luxpay.eu/pricing" className={s.link}>fee</a> .
            </div>
            {steps[current].content}
        </div>
    )
};

export default CurrencyExchange;
