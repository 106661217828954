import React from 'react';
import {FormikErrors, FormikTouched, FormikValues} from "formik";
import s from "../BankTransferFormPersonal.module.css";
import MyInput from "../../ui/MyInput/MyInput";
import {Popover} from "antd";
import info from "../../../assets/img/personalAccount/information.svg";

type InternationalPropsType = {
    values: FormikValues,
    currency: string,
    touched: FormikTouched<FormikValues>,
    errors: FormikErrors<FormikValues>,
    handleChange: (e: React.ChangeEvent<any>) => void,
    isIbanWrong: boolean,
    disabled?: boolean,
    isTemplateCreation?: boolean
}

const DomesticUsd = (props: InternationalPropsType) => {

    return (
        <div className={s.flex_wrap}>

            {
                props.values.recipientType === "INDIVIDUAL"
                    ? <div className={s.recipient_details_row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                <span><span className={s.red}>*</span> Name</span>
                            </div>
                            <MyInput id={"name"}
                                     name={"name"}
                                     touched={props.touched.name}
                                     value={props.values.name}
                                     onChange={props.handleChange}
                                     isError={props.touched.name && !!props.errors.name}
                                     disabled={props.disabled}
                            />
                            {props.errors.name && props.touched.name &&
                                <div className={s.error_message}>{`${props.errors.name}`}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                <span><span className={s.red}>*</span> Surname</span>
                            </div>
                            <MyInput id={"surname"}
                                     name={"surname"}
                                     touched={props.touched.surname}
                                     value={props.values.surname}
                                     onChange={props.handleChange}
                                     isError={props.touched.surname && !!props.errors.surname}
                                     disabled={props.disabled}
                            />
                            {props.errors.surname && props.touched.surname &&
                                <div className={s.error_message}>{`${props.errors.surname}`}</div>}
                        </div>
                    </div>
                    : <div className={s.input_block}>
                        <div className={s.input_label}>
                            <span><span className={s.red}>*</span> Company Name</span>
                        </div>
                        <MyInput id={"companyName"}
                                 name={"companyName"}
                                 touched={props.touched.companyName}
                                 value={props.values.companyName}
                                 onChange={props.handleChange}
                                 isError={props.touched.companyName && !!props.errors.companyName}
                                 disabled={props.disabled}
                        />
                        {props.errors.companyName && props.touched.companyName &&
                            <div className={s.error_message}>{`${props.errors.companyName}`}</div>}
                    </div>
            }

            <div className={s.input_block}>
                <div className={s.input_label}>
                    <span><span className={s.red}>*</span> Account Number</span>
                </div>
                <MyInput id={"recipientsAccountNumber"}
                         name={"recipientsAccountNumber"}
                         touched={props.touched.recipientsAccountNumber}
                         value={props.values.recipientsAccountNumber}
                         onChange={props.handleChange}
                         disabled={props.disabled}
                         isError={props.touched.recipientsAccountNumber && !!props.errors.recipientsAccountNumber}
                />
                {props.errors.recipientsAccountNumber && props.touched.recipientsAccountNumber &&
                    <div className={s.error_message}>{`${props.errors.recipientsAccountNumber}`}</div>}
            </div>

            <div className={s.input_block}>
                <div className={s.input_label}>
                    <span><span className={s.red}>*</span> Sort Code</span>
                </div>
                <MyInput id={"sortCode"}
                         name={"sortCode"}
                         touched={props.touched.sortCode}
                         value={props.values.sortCode}
                         onChange={props.handleChange}
                         disabled={props.disabled}
                         isError={props.touched.sortCode && !!props.errors.sortCode}
                />
                {props.errors.sortCode && props.touched.sortCode &&
                    <div className={s.error_message}>{`${props.errors.sortCode}`}</div>}
            </div>

            <div className={s.input_block}>
                <div className={s.input_label}>
                    <span><span className={s.red}>*</span> Reference</span>
                </div>
                <MyInput id={"reference"}
                         name={"reference"}
                         touched={props.touched.reference}
                         value={props.values.reference}
                         onChange={props.handleChange}
                         disabled={props.disabled}
                         isError={props.touched.reference && !!props.errors.reference}
                />
                {props.errors.reference && props.touched.reference &&
                    <div className={s.error_message}>{`${props.errors.reference}`}</div>}
            </div>

        </div>
    );
};

export default DomesticUsd;
