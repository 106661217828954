import React, { useState } from 'react';
import s from './Accounts.module.css'
import styles from "../../../styles/styles.module.css"
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {AccountStatement} from "./AccountStatement/AccountStatement";
import {AccountTopUp} from "./AccountTopUp/AccountTopUp";
import {AccountOverview} from './AccountOverview/AccountOverview';
import {motion} from "framer-motion";
import { useDispatch } from 'react-redux';
import { GetTransactionsListThunk } from '../../../store/reducers/ActionCreators';
import { useAppSelector } from '../../../hooks/redux';
import { AppDispatch } from '../../../store/store';

const Accounts = () => {
    const [chosenAccountId, setChosenAccountId] = useState<any>()
    
    const userToken = useAppSelector(store => store.userReducer.userToken)
    const userId = useAppSelector(store => store.userReducer.chosenAccountTypeId)
    const dispatch = useDispatch<AppDispatch>()

    const getListTransactions = async () => {
        await dispatch(GetTransactionsListThunk(userToken, userId))
    }
    

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.container}>
                <Tabs>
                    <TabList className={styles.tabstitle_block}>
                        <Tab selectedClassName={styles.active_tab} className={styles.tabtitle}>Account Overview</Tab>
                        <Tab selectedClassName={styles.active_tab} className={styles.tabtitle} onClick={getListTransactions}>Statements</Tab>
                        <Tab selectedClassName={styles.active_tab} className={styles.tabtitle}>Account Top-Up</Tab>
                    </TabList>
                    <TabPanel>
                        <AccountOverview/>
                    </TabPanel>
                    <TabPanel>
                        <AccountStatement/>
                    </TabPanel>
                    <TabPanel>
                        <AccountTopUp/>
                    </TabPanel>
                </Tabs>
            </div>
        </motion.div>
    );
};

export default Accounts;
