import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectAccountsByFilter, selectTopUpByAccount} from "../../../../../store/reducers/TransactionsSlice/selector";
import {useAppDispatch} from "../../../../../hooks/redux";
import {setFilters, setFiltersTemplates} from "../../../../../store/reducers/TransactionsSlice/TransactionsSlice";
import s from "./DetailsForBankTransfer.module.css";
import {Formik, useFormikContext} from "formik";
import {Popover} from "antd";
import infoIcon from "../../../../../assets/img/personalAccount/information.svg";
import CustomDropdownForAccounts
    from "../../../../../components/ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import CustomDropdown from "../../../../../components/ui/CustomDropdown/CustomDropdown";
import { typesList} from "../../../../../Constants/StaticData";
import { current } from '@reduxjs/toolkit';
import info from "../../../../../assets/img/personalAccount/information.svg";

interface Helper {
    transferValues: {
        accountNumber: string;
    };
}

const DetailsForBankTransferHelper: React.FC<Helper> = ({ transferValues }) => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (transferValues.accountNumber) {
            dispatch(setFilters({ accountNumber: transferValues.accountNumber }));
        }
    }, [dispatch, transferValues.accountNumber]);

    return null;
}







const AutoSetPaymentTypeHelper: React.FC<{ currency: string }> = ({ currency }) => {

    const { setFieldValue, values } = useFormikContext();



    useEffect(() => {
        if (currency === 'GBP') {
            setFieldValue('paymentType', 'domestic');
        }
    }, [currency, setFieldValue]);

    return null;
};


const DetailsForBankTransfer = () => {
    const accountsList = useSelector(selectAccountsByFilter)
    const accountTopUpList = useSelector(selectTopUpByAccount)
    const dispatch = useAppDispatch()
    const [currency, setCurrency] = useState("")
    const [disabled, setDisabled] = useState(true)

    // Hidden Iternational for GBP

    // const [paymentRegionOptions, setPaymentRegionOptions] = useState(typesList)

    // useEffect(() => {
    //         if (currency === 'GBP') {

    //             setPaymentRegionOptions(['domestic']);
    //         } else if (currency === 'EUR') {

    //             setPaymentRegionOptions(typesList);

    //         } else {

    //             setPaymentRegionOptions(typesList);
    //         }
    //     }, [currency]);

    // Hidden Iternational for GBP


    const clearFilters = () => {
        dispatch(setFiltersTemplates(
            {
                accountNumber: '',
                period: null,
                from: '',
                to: '',
                purpose: '',
                recipient: '',
                amount: '',
                incoming: false,
                outgoing: false,
                currency: '',
                selectedType: '',
                templateName: '',
                paymentRegion: ''
            }
        ))
    }

    useEffect(() => {
        return clearFilters()
    }, [accountTopUpList])

    return (
        <div className={s.accountTopUpWrapper}>
            <Formik initialValues={{
                accountNumber: '',
                paymentType: '',
            }}
                    onSubmit={(values) => {


                        dispatch(setFilters({accountNumber: values.accountNumber}))
                    }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      setFieldValue
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <DetailsForBankTransferHelper transferValues={values}  />
                        <AutoSetPaymentTypeHelper currency={currency}  />
                        <div className={s.detailsWrapper}>
                            <p className={s.detailsTitle}>Details for Bank Transfer</p>
                            <div className={s.form_row}>
                                <div className={s.input_block}>
                                    <p className={s.labelText}>Account Number <Popover
                                        content={"Please select the account you wish to make a transfer to."}
                                        color={'#EEEFEF'}
                                        placement={"bottom"}
                                        overlayInnerStyle={{
                                            fontFamily: 'Manrope',
                                            boxShadow: 'none',
                                            width: '280px',
                                            padding: '13px 16px',
                                            cursor: 'pointer',
                                            backgroundColor: '#E7F0EC'
                                        }}
                                    >
                                        <img src={infoIcon} alt=""/>
                                    </Popover></p>
                                    <CustomDropdownForAccounts
                                        // setCurrency={setCurrency}
                                        items={accountsList}
                                        id='accountNumber'
                                        name='accountNumber'
                                        placeholder={"Please select the account"}
                                        setDisabled={setDisabled}
                                        setCurrency={(selectedCurrency) => {
                                            setCurrency(selectedCurrency);
                                            if (selectedCurrency === 'GBP') {
                                                setFieldValue('paymentRegions', 'domestic');
                                            }
                                        }}
                                    />
                                </div>
                                <div className={s.input_block}>
                                    <p className={s.labelText}>payment type <Popover
                                        content={"Please select the Account Number first."}
                                        color={'#EEEFEF'}
                                        placement={"bottom"}
                                        overlayInnerStyle={{
                                            fontFamily: 'Manrope',
                                            boxShadow: 'none',
                                            width: '280px',
                                            padding: '13px 16px',
                                            cursor: 'pointer',
                                            backgroundColor: '#E7F0EC'
                                        }}
                                    >
                                        <img src={infoIcon} alt=""/>
                                    </Popover>
                                    </p>
                                    <div className={s.dropdown_wrapper}>
                                        <CustomDropdown

                                            items={typesList}
                                            id='paymentType'
                                            name='paymentType'
                                            disable={disabled}
                                            placeholder={"Please select the payment type"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={s.info_wrapper}>
                                {accountTopUpList?.all.length > 0 && accountTopUpList?.all ? (
                                    currency === "GBP" && values.paymentType === "domestic" ? (
                                        accountTopUpList.all.map((e: any, key: any) => (
                                            <div className={s.data_wrapper} key={key}>
                                                <div className={s.row}>
                                                    <span>Beneficiary’s Name</span>
                                                    <span><b>{e.beneficiaryName}</b></span>
                                                </div>
                                                <div className={s.row}>
                                                    <span>Beneficiary’s Bank Name</span>
                                                    <span><b>{e.bankName}</b></span>
                                                </div>
                                                <div className={s.row}>
                                                    <span>Beneficiary’s Account Number</span>
                                                    <span><b>{e.iban}</b></span>
                                                </div>
                                                <div className={s.row}>
                                                    <span>Beneficiary’s Sort Code</span>
                                                    <span><b>{e.sortCode}</b></span>
                                                </div>
                                                <div className={s.row}>
                                                    <span className={s.spanHelper}>Reference
                                                    <Popover content={"Please note: Always use the Reference when submitting the payment; otherwise, it will not be recived."}
                                                            color={'#EEEFEF'}
                                                            placement={"top"}

                                                            overlayInnerStyle={{
                                                                fontFamily: 'Manrope',
                                                                padding: '13px 16px',
                                                                boxShadow: 'none',
                                                                cursor: 'pointer',
                                                                backgroundColor: '#E7F0EC',
                                                                width: "280px",
                                                                marginLeft: '10px'
                                                            }}
                                                    >
                                                        <img src={info} alt=""/>
                                                    </Popover>
                                                    </span>
                                                    <span><b>{e.reference}</b></span>
                                                </div>
                                            </div>
                                        ))
                                    )
                                     :
                                    (
                                            accountTopUpList.all.map((e: any, key: any) => (
                                            <div className={s.data_wrapper} key={key}>
                                                <div className={s.row}>
                                                    <span>Beneficiary’s Name</span>
                                                    <span><b>{e.beneficiaryName}</b></span>
                                                </div>
                                                <div className={s.row}>
                                                    <span>Beneficiary’s BIC Code</span>
                                                    <span><b>{e.swift}</b></span>
                                                </div>
                                                <div className={s.row}>
                                                    <span>Beneficiary’s IBAN</span>
                                                    <span><b>{e.iban}</b></span>
                                                </div>
                                                <div className={s.row}>
                                                    <span>Beneficiary’s Bank Name</span>
                                                    <span><b>{e.bankName}</b></span>
                                                </div>
                                                <div className={s.row}>
                                                    <span>Beneficiary’s Bank address</span>
                                                    <span><b>{e.bankAddress}</b></span>
                                                </div>
                                                <div className={s.row}>
                                                    <span className={s.spanHelper}>Mandatory Reference
                                                        <Popover content={"Please note: Always use the Mandatory Reference when submitting the payment; otherwise, it will not be recived."}
                                                                color={'#EEEFEF'}
                                                                placement={"top"}

                                                                overlayInnerStyle={{
                                                                    fontFamily: 'Manrope',
                                                                    padding: '13px 16px',
                                                                    boxShadow: 'none',
                                                                    cursor: 'pointer',
                                                                    backgroundColor: '#E7F0EC',
                                                                    width: "280px",
                                                                    marginLeft: '10px'
                                                                }}
                                                        >
                                                            <img src={info} alt=""/>
                                                        </Popover>
                                                    </span>
                                                    <span><b>{e.reference}</b></span>
                                                </div>
                                            </div>
                                        ))
                                    )
                                ) : (
                                    <div>No data found</div>
                                )}
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
};



export default DetailsForBankTransfer;
