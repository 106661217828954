import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import s from "./RegistrationSteps.module.css"
import {NavLink, useParams} from "react-router-dom";
import RegistrationAutentication from "./RegistrationAutentication/RegistrationAutentication";
import CustomModalContactContainer from "../../components/ui/CustomModalContactContainer/CustomModalContactContainer";
import logo from "../../assets/img/personalAccount/logo_sign-in.svg"
import SelectAccountType from './SelectAccountType/SelectAccountType';
import MainInfoStep from './MainInfoStep/MainInfoStep';
import PasswordCreationStep from './PasswordCreationStep/PasswordCreationStep';
import Steps from '../../components/ui/Steps/Steps';
import classNames from 'classnames';
import CustomModal from '../../components/ui/CustomModal/CustomModal';
import MyBtn from '../../components/ui/MyBtn/MyBtn';
import closeCircle from '../../assets/img/personalAccount/close-circle.svg'
import { myDate } from '../../helpers/CalculateDate';

const RegistrationSteps = () => {

    const currentYear = myDate.getFullYear()
    const { type } = useParams()

    const [isModalOpen, setIsModalOpen] = useState<boolean | null>(false)
    const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean | null>(false)
    const [dataForSend, setDataForSend] = useState({
        type: type ? type : '',
        firstName: '',
        lastName: '',
        companyName: '',
        email: '',
        phoneNumber: '',
        password: '',
        emailCode: '',
    })
    const stepQuantity = 4;

    useEffect(() => {
        localStorage.removeItem('registrationData')
    }, [])

    useEffect(() => {
        localStorage.setItem('registrationData', JSON.stringify(dataForSend));
    }, [dataForSend]);

    useEffect(() => {
        const savedData = localStorage.getItem('registrationData');
        if (savedData) {
            setDataForSend(JSON.parse(savedData));
        }
    }, []);

    const [current, setCurrent] = useState(type ? 1 : 0)

    const steps = [
        {
            content: <SelectAccountType onSubmit={() => setCurrent(1)}
            />
        },
        {
            content: <MainInfoStep onSubmit={() => setCurrent(2)}
            />
        },
        {
            content: <PasswordCreationStep setCurrent={setCurrent}
                        onSubmit={() => setCurrent(3)}
            />
        },
        {
            content: <RegistrationAutentication dataForSend={dataForSend}
                        setDataForSend={() => setDataForSend}
                        setCurrent={setCurrent}
                        setIsErrorModalOpen={setIsErrorModalOpen}
            />
        },
    ];

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >

            <CustomModalContactContainer isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>

            <CustomModal setIsModalOpen={setIsErrorModalOpen} isModalOpen={isErrorModalOpen} type={"error"}>
                <div className={s.flex_modal}>
                    <img src={closeCircle} alt="closeCircle" />
                    <div className={s.popup_title}>
                        Oops!
                    </div>
                    <div className={s.popup_subtitle}>
                        Authentication time has run out. <br/> Please login again.
                    </div>
                    <div className={s.popup_button_wrapper}>
                        <div className={s.button_block}>
                            <MyBtn style={{padding: "8px 48px"}} buttonType={"error"} title={"Ok"} onClick={() => setIsErrorModalOpen(false)} isPersonalAccountBtn/>
                        </div>
                    </div>
                </div>
            </CustomModal>

            <div className={current === 0 ? s.wrapper_bg : s.wrapper}>
            <div className={current === 0 ? s.wrapper_bg_body : s.wrapper_body}>

                <div className={current === 0 ? s.signup_wrapper_content_none : s.signup_wrapper_content_left}>
                    <div className={s.content_left}>
                        <div className={s.logo_block}>
                            <NavLink to={"/"}>
                                <img src={logo} alt=""/>
                            </NavLink>
                        </div>
                        <div className={s.welcome_block}>
                            Welcome to <br/>
                            LuxPay!
                        </div>
                        <div className={s.welcome_block_mobile}>
                            Welcome to LuxPay!
                        </div>
                    </div>
                </div>
                <div className={current === 0 ? s.signup_wrapper_content_full : s.signup_wrapper_content_right}>
                    {current === 0
                    ?
                        steps[current].content
                    :
                        <div className={s.main_info}>
                            <div className={s.content}>
                                <div className={s.content_info}>
                                    {steps[current].content}
                                </div>
                                <div className={classNames(s.back, s.backMobile)}>
                                    <span className={s.back_text}>Back to</span>
                                    <span className={s.back_link}><NavLink className={s.link} to={"/login"}>Sign In</NavLink></span>
                                </div>
                                <div className={s.steps}><Steps quantity={stepQuantity} activeNum={current} /></div>
                            </div>
                        </div>
                    }
                </div>
                
            </div>
            <div className={s.footer_text}>
                    All rights reserved © {currentYear} Lux International Payment System, UAB License No. 81.&nbsp;
                    <a href="https://www.lb.lt/en/sfi-financial-market-participants/uab-lux-international-payment-system" className={s.licence_link}>Bank of Lithuania</a>
                </div>
            </div>
        </motion.div>
    );
};

export default RegistrationSteps;
