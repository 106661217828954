import React from 'react';
import {FormikErrors, FormikTouched, FormikValues} from "formik";
import s from "../BankTransferFormPersonal.module.css";
import MyInput from "../../ui/MyInput/MyInput";
import {Popover} from "antd";
import info from "../../../assets/img/personalAccount/information.svg";

type InternationalPropsType = {
    values: FormikValues,
    currency: string,
    touched: FormikTouched<FormikValues>,
    errors: FormikErrors<FormikValues>,
    handleChange: (e: React.ChangeEvent<any>) => void,
    isIbanWrong: boolean,
    disabled?: boolean,
    isTemplateCreation?: boolean
}

const InternationalEur = (props: InternationalPropsType) => {

    return (
        <div className={s.flex_wrap}>

            {
                props.values.recipientType === "INDIVIDUAL"
                    ? <div className={s.recipient_details_row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                <span><span className={s.red}>*</span> Name</span>
                            </div>
                            <MyInput id={"name"}
                                     name={"name"}
                                     touched={props.touched.name}
                                     value={props.values.name}
                                     onChange={props.handleChange}
                                     isError={props.touched.name && !!props.errors.name}
                                     disabled={props.disabled}
                            />
                            {props.errors.name && props.touched.name &&
                                <div className={s.error_message}>{`${props.errors.name}`}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                <span><span className={s.red}>*</span> Surname</span>
                            </div>
                            <MyInput id={"surname"}
                                     name={"surname"}
                                     touched={props.touched.surname}
                                     value={props.values.surname}
                                     onChange={props.handleChange}
                                     isError={props.touched.surname && !!props.errors.surname}
                                     disabled={props.disabled}
                            />
                            {props.errors.surname && props.touched.surname &&
                                <div className={s.error_message}>{`${props.errors.surname}`}</div>}
                        </div>
                    </div>
                    : <div className={s.input_block}>
                        <div className={s.input_label}>
                            <span><span className={s.red}>*</span> Company Name</span>
                        </div>
                        <MyInput id={"companyName"}
                                 name={"companyName"}
                                 touched={props.touched.companyName}
                                 value={props.values.companyName}
                                 onChange={props.handleChange}
                                 isError={props.touched.companyName && !!props.errors.companyName}
                                 disabled={props.disabled}
                        />
                        {props.errors.companyName && props.touched.companyName &&
                            <div className={s.error_message}>{`${props.errors.companyName}`}</div>}
                    </div>
            }

            <div className={s.input_block}>
                <div className={s.input_label}>
                    <span><span className={s.red}>*</span> IBAN / Account number</span>
                    <Popover
                        content={"Enter the recipient’s bank account number or IBAN account."}
                        color={'#EEEFEF'}
                        overlayInnerStyle={{
                            fontFamily: 'Manrope',
                            width: '280px',
                            padding: '13px 16px',
                            cursor: 'pointer',
                            backgroundColor: '#E7F0EC'
                        }}
                    >
                        <img src={info} alt=""/>
                    </Popover>
                </div>
                <MyInput id={"recipientsIban"}
                         name={"recipientsIban"}
                         touched={props.touched.recipientsIban}
                         value={props.values.recipientsIban}
                         onChange={props.handleChange}
                         disabled={props.disabled}
                         isError={(props.touched.recipientsIban && !!props.errors.recipientsIban) || props.isIbanWrong}
                />
                {props.errors.recipientsIban && props.touched.recipientsIban &&
                    <div className={s.error_message}>{`${props.errors.recipientsIban}`}</div>}
                {props.isIbanWrong &&
                    <div className={s.error_message}>invalid IBAN</div>}
            </div>

            <div className={s.input_block}>
                <div className={s.input_label}>
                    <span><span className={s.red}>*</span> SWIFT / BIC Code</span>
                    <Popover
                        content={"A SWIFT code is an international bank code that identifies " +
                            "particular banks worldwide. It's also known as a Bank Identifier" +
                            " Code (BIC). A SWIFT code consists of 8 or 11 characters."}
                        color={'#EEEFEF'}
                        overlayInnerStyle={{
                            fontFamily: 'Manrope',
                            width: '280px',
                            padding: '13px 16px',
                            cursor: 'pointer',
                            backgroundColor: '#E7F0EC'
                        }}
                    >
                        <img src={info} alt=""/>
                    </Popover>
                </div>
                <MyInput id={"recipientsSwiftBic"}
                         name={"recipientsSwiftBic"}
                         touched={props.touched.recipientsSwiftBic}
                         value={props.values.recipientsSwiftBic}
                         onChange={props.handleChange}
                         disabled={props.disabled}
                         isError={props.touched.recipientsSwiftBic && !!props.errors.recipientsSwiftBic}
                />
                {props.errors.recipientsSwiftBic && props.touched.recipientsSwiftBic &&
                    <div className={s.error_message}>{`${props.errors.recipientsSwiftBic}`}</div>}
            </div>

            <div className={s.input_block}>
                <div className={s.input_label}>
                    <span><span className={s.red}>*</span> Recipient Address</span>
                </div>
                <MyInput id={"recipientsAddress"}
                         name={"recipientsAddress"}
                         touched={props.touched.recipientsAddress}
                         value={props.values.recipientsAddress}
                         onChange={props.handleChange}
                         disabled={props.disabled}
                         isError={props.touched.recipientsAddress && !!props.errors.recipientsAddress}
                />
                {props.errors.recipientsAddress && props.touched.recipientsAddress &&
                    <div className={s.error_message}>{`${props.errors.recipientsAddress}`}</div>}
            </div>

            <div className={s.input_block}>
                <div className={s.input_label}>
                    <span><span className={s.red}>*</span> Recipient City</span>
                </div>
                <MyInput id={"recipientCity"}
                         name={"recipientCity"}
                         touched={props.touched.recipientCity}
                         value={props.values.recipientCity}
                         onChange={props.handleChange}
                         disabled={props.disabled}
                         isError={props.touched.recipientCity && !!props.errors.recipientCity}
                />
                {props.errors.recipientCity && props.touched.recipientCity &&
                    <div className={s.error_message}>{`${props.errors.recipientCity}`}</div>}
            </div>

            <div className={s.input_block}>
                <div className={s.input_label}>
                    <span><span className={s.red}>*</span> Recipient Post Code</span>
                </div>
                <MyInput id={"recipientPostCode"}
                         name={"recipientPostCode"}
                         touched={props.touched.recipientPostCode}
                         value={props.values.recipientPostCode}
                         onChange={props.handleChange}
                         disabled={props.disabled}
                         isError={props.touched.recipientPostCode && !!props.errors.recipientPostCode}
                />
                {props.errors.recipientPostCode && props.touched.recipientPostCode &&
                    <div className={s.error_message}>{`${props.errors.recipientPostCode}`}</div>}
            </div>

            <div className={s.input_block}>
                <div className={s.input_label}>
                    <span><span className={s.red}>*</span> Recipient Country</span>
                </div>
                <MyInput id={"recipientCountry"}
                         name={"recipientCountry"}
                         touched={props.touched.recipientCountry}
                         value={props.values.recipientCountry}
                         onChange={props.handleChange}
                         disabled={props.disabled}
                         isError={props.touched.recipientCountry && !!props.errors.recipientCountry}
                />
                {props.errors.recipientCountry && props.touched.recipientCountry &&
                    <div className={s.error_message}>{`${props.errors.recipientCountry}`}</div>}
            </div>

            <div className={s.input_block}>
                <div className={s.input_label}>
                    <span><span className={s.red}>*</span> Bank Name</span>
                </div>
                <MyInput id={"bankName"}
                         name={"bankName"}
                         touched={props.touched.bankName}
                         value={props.values.bankName}
                         onChange={props.handleChange}
                         disabled={props.disabled}
                         isError={props.touched.bankName && !!props.errors.bankName}
                />
                {props.errors.bankName && props.touched.bankName &&
                    <div className={s.error_message}>{`${props.errors.bankName}`}</div>}
            </div>

            <div className={s.input_block}>
                <div className={s.input_label}>
                    <span><span className={s.red}>*</span> Bank Address</span>
                </div>
                <MyInput id={"bankAddress"}
                         name={"bankAddress"}
                         touched={props.touched.bankAddress}
                         value={props.values.bankAddress}
                         onChange={props.handleChange}
                         disabled={props.disabled}
                         isError={props.touched.bankAddress && !!props.errors.bankAddress}
                />
                {props.errors.bankAddress && props.touched.bankAddress &&
                    <div className={s.error_message}>{`${props.errors.bankAddress}`}</div>}
            </div>

            <div className={s.input_block}>
                <div className={s.input_label}>
                    <span><span className={s.red}>*</span> Bank City</span>
                </div>
                <MyInput id={"bankCity"}
                         name={"bankCity"}
                         touched={props.touched.bankCity}
                         value={props.values.bankCity}
                         onChange={props.handleChange}
                         disabled={props.disabled}
                         isError={props.touched.bankCity && !!props.errors.bankCity}
                />
                {props.errors.bankCity && props.touched.bankCity &&
                    <div className={s.error_message}>{`${props.errors.bankCity}`}</div>}
            </div>

            <div className={s.input_block}>
                <div className={s.input_label}>
                    <span><span className={s.red}>*</span> Bank Post Code</span>
                </div>
                <MyInput id={"bankPostCode"}
                         name={"bankPostCode"}
                         touched={props.touched.bankPostCode}
                         value={props.values.bankPostCode}
                         onChange={props.handleChange}
                         disabled={props.disabled}
                         isError={props.touched.bankPostCode && !!props.errors.bankPostCode}
                />
                {props.errors.bankPostCode && props.touched.bankPostCode &&
                    <div className={s.error_message}>{`${props.errors.bankPostCode}`}</div>}
            </div>

            <div className={s.input_block}>
                <div className={s.input_label}>
                    <span><span className={s.red}>*</span> Bank Country</span>
                </div>
                <MyInput id={"bankCountry"}
                         name={"bankCountry"}
                         touched={props.touched.bankCountry}
                         value={props.values.bankCountry}
                         onChange={props.handleChange}
                         disabled={props.disabled}
                         isError={props.touched.bankCountry && !!props.errors.bankCountry}
                />
                {props.errors.bankCountry && props.touched.bankCountry &&
                    <div className={s.error_message}>{`${props.errors.bankCountry}`}</div>}
            </div>

            <div className={s.input_block}>
                <div className={s.input_label}>
                    <span><span className={s.red}>*</span> Reference</span>
                </div>
                <MyInput id={"reference"}
                         name={"reference"}
                         touched={props.touched.reference}
                         value={props.values.reference}
                         onChange={props.handleChange}
                         disabled={props.disabled}
                         isError={props.touched.reference && !!props.errors.reference}
                />
                {props.errors.reference && props.touched.reference &&
                    <div className={s.error_message}>{`${props.errors.reference}`}</div>}
            </div>

        </div>
    );
};

export default InternationalEur;
