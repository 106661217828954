import React, { createElement } from 'react';
import {motion} from "framer-motion";
import s from './Compliants.module.css'



const Complaints = () => {

    const complaintFormUrl = '/Appendix 3 Customer Complaint Form.docx';

    const downloadFile = () => {
        const fileurl = complaintFormUrl
        const link = document.createElement('a');
        link.href = fileurl;
        link.download = 'Complaints_Form.docx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.wrapper}>
                <div className={s.container}>
                    <div className={s.title}>
                        Complaint handling policy
                    </div>

                    <div className={s.body}>

                        <div className={s.subtitle}>
                            I. Introduction
                        </div>

                        <div className={s.text}>
                            <p>
                                1. These Rules regulate how UAB Lux International Payment System handles complaints related to its services or agreements with clients or other persons (complainants). It includes the acceptance, registration, examination, and resolution of such complaints.
                            </p>
                            <p>
                                2. The Rules are prepared in accordance with the Law on Electronic Money and Electronic Money Institutions of the Republic of Lithuania, as amended and Resolution No 03 – 105<br/>
                                „Regarding the Rules on Handling the Complaints Received by Financial Market Participants“ of the Board of the Bank of Lithuania, as of 6 June 2013, as amended, and other applicable legal acts of the Republic of Lithuania.
                            </p>
                            <p>
                                3. The purpose of these rules is to organize the process of Client or Complainant complaints management in a fair, efficient, and proper manner.
                            </p>
                            <p>
                                <p className={s.submitParagraph}>
                                4. The Rules do not apply in two cases:
                                </p>
                                
                                <ul className={s.markList}>
                                    <li className={s.markItem}>If the complaint concerns activities not regulated by special laws or out side the supervision of the Bank of Lithuania.</li>
                                    <li className={s.markItem}>If the Company is not responsible for the activity specified in the complaint.</li>
                                </ul>
                            </p>
                            <p className={s.submitParagraph}>
                                <span className={s.definitionTitle}>5. Definitions used in the Rules:</span>
                            </p>
                            <p>
                                    <span className={s.definitonName}>Company</span> – UAB Lux International Payment System (company code 305158007, Savanoriu pr. 7, Vilnius, the Republic of Lithuania) (hereinafter “the Company” or “Lux Pay”).<br/>
                                    <span className={s.definitonName}>Client</span> – a natural or legal person who has registered in the Company’s System and to whom the Company provides its services.<br/>
                                    <span className={s.definitonName}>Complaint</span> – a <span className={s.definitonName}>written request</span> submitted by a Client (or Complainant) to the Company, asserting that their rights or legitimate interests have been violated in connection with the services provided by the Company or agreements concluded with the Company. The complaint seeks the resolution of the issue and fulfillment of the Complainant's specified requirements.<br/>
                                    <span className={s.definitonName}>Complainant</span> – a person who has submitted a complaint regarding the services provided by the Company and/or contracts concluded with the Company, i.e. an existing or potential client, injured third party, client of another financial market participant, potential client or his representative.<br/>
                                    <span className={s.definitonName}>Complaints examination</span>  – the process carried out by the responsible employees of the Company, what includes:
                                    <ul>
                                            <li className={s.markItem}>Acceptance and registration of complaints or claims.</li>
                                            <li className={s.markItem}>Determination of theissues raised in the complaint.</li>
                                            <li className={s.markItem}>Preparation of an appropriate response.</li>
                                            <li className={s.markItem}>Providing the response to the Client.</li>
                                    </ul>
                                    <p>
                                    <span className={s.definitonName}>Complaint management process</span> – the sequenceof actions undertaken by the Company’s employees, including but not limited to<br/><br/>
                                    <ul>
                                            <li className={s.markItem}>Receiving and registering complaints.</li>
                                            <li className={s.markItem}>Examining the details and validity of the complaint.</li>
                                            <li className={s.markItem}>Informing the complainant about the progress and outcome of the process.</li>
                                    </ul>
                                    </p>
                            </p>
                            <p>
                                <span className={s.definitonName}>Complaints register</span> – a structured journal (digital or paper) maintained by the Company where all Client complaints are recorded. This register ensures a systematic approach to tracking and managing complaints<br/>
                                <span className={s.definitonName}>Consumer</span> - a natural (private)person using the Company services for purposes unrelated to their business, trade, or professional activity.<br/>
                                <span className={s.definitonName}>Response</span> – a written communication sent to a client or Complainant addressing their complaint. This may include responses to the Client’s questions and/or demands raised in the complaint.
                            </p>
                            <p>6. Any terms not explicitly defined in these Rules shall be understood and interpreted in accordance with the applicable laws of the Republic of Lithuania.</p>
                            <p>7. The complaints submitted by the Complainant are solved free of charge.</p>
                        </div>

                        <div className={s.subtitle}>
                            II. Submitting a complaint, Requirements for the complaint
                        </div>

                        <div className={s.text}>
                            <p>
                                1. The Complainant believing that the Company has violated the Complainant’s rights and/or interests protected by the laws related to provision of financial services and/or concluded agreements, might refer to the Company by submitting a written Complaint.
                            </p>
                            <p>
                                <span className={s.submittingParagraph}>2. The Complainant may submit a complaint in the following ways:</span>
                                2.1. at the Company’s Office at Savanoriu pr. 7, Vilnius, the Republic of Lithuania by filling in the complaint form and providing it to an employee.<br/>
                                2.2. by sending a letter by mail to the Company’s Office at Savanoriu pr. 7, Vilnius, the Republic of Lithuania.<br/>
                                2.3. by sending an email to <a href="mailto:info@luxpay.lt" className={s.link_small}>info@luxpay.lt </a> or <a href="mailto:support@luxpay.lt" className={s.link_small}>support@luxpay.lt</a>; the purpose of client identification, only claims (complaints) sent from the client's personal email specified in the client's account shall be accepted;<br/>
                                2.4. in the electronic dispute settlement system – <a href="https://webgate.ec.europa.eu/odr" className={s.link_small}>https://webgate.ec.europa.eu/odr</a>
                            </p>
                            <p>
                                3. Client inquiries made verbally (by phone or at the Client Support Department) shall be answered and the information they need shall be provided during the conversation. If an immediate answer and information cannotbe provided for an oral inquiry, the employee of the Client Support Department shall ask the client to submit the inquiry in writing.
                            </p>
                            <p className={s.submitParagraph}>
                                4. <span className={s.definitonName }>The Complaint shall include the following information:</span>
                            </p>
                            <p>
                                4.1.  Complainants full name, surname /or company name,<br/>
                                4.2. residence address and other contact details of the complainant (contact address, if different from the address of residence),<br/>
                                4.3. the date of filing the complaint,<br/>
                                4.4. the reason for the complaint and/or the essence of the complaint, i.e. the person’s rights or legitimate interests that have been violated,<br/>
                                4.5. the Complainant's requirements to the Company,<br/>
                                4.6. documents related to the complaint (if any),<br/>
                                4.7. the Client’s contacts: phone number and email address.<br/>
                            </p>
                            <p>
                                5. If the Complainant is represented by another person, the Complaint must include the name of the representative and the basis of representation. The Complaint must be accompanied by a power of attorney in the form established by applicable legal acts, or another document confirming the representative's authority to act on behalf of the Complainant.
                            </p>
                            <p>
                                6. If at least one of the required items of information specified above is not provided, the Company has the right to request the Complainant to clarify (supplement) the complaint or complete the complaint form.
                            </p>
                            <p>
                                7. The Complaint can be submitted in Lithuanian or English. The standard form of the Complaint is available here.
                            </p>
                            <p>
                                8. Upon the receipt of a complaint, the Complainant shall be informed that complaint has been received and registered in the Company's Complaints Register no later than within 1 (one) business day. Herewith the Company must indicate the time limit within which the complaint will be investigated, and the response will be provided.
                                Upon receiving a client's claim (complaint), the client shall be informed that their claim (complaint) has been received and registered in the Company's Claims (Complaints) Registration Log no later than within 1 (one) business day.
                            </p>
                        </div>

                        <div className={s.subtitle}>
                            III. Processing of Complaints
                        </div>

                        <div className={s.text}>
                            <p>
                                1. The Company ensures that the Complaint is handled in accordance with the principles of justice, fairness, impartiality and reasonableness, with a full examination of the circumstances set out in the Complaint. The Company’s employees examining the Complaint must avoid conflicts of interest due to being related by blood or marriage or other reasons that may affect objectivity.
                            </p>
                            <p>
                                2.  All Complaints, which are received in the manner set out in these Rules, are recorded in the Complaints' registration journal of the Company.
                            </p>
                            <p>
                                3. The Company does not investigate the Complaint that do not comply with the requirements set in Part II of these Rules, are written in other than Lithuanian or English language, are disorder and unreadable, the identity of the Complainant is not clear.
                            </p>
                            <p>
                                4. In case the submitted Complaint lacks information needed for the investigation, the Company has the right to ask the Complainant to eliminate the shortcomings of the Complaint by specifying the essence of the Complaint or providing additional documents or data needed for the Complaint's investigation. In such case the Company sets a reasonable time limit, which cannot be shorter than 7 calendar days to eliminate the shortcomings of the Complaint. If the Complainant eliminates the shortcomings within the set deadline, the Complaint is provided on the date the shortcomings were eliminated, i.e. the day when the specified Complaint or needed documents were provided to the Company.
                            </p>
                            <p>
                                5. A complaint shall be considered resolved when all problem-solving actions have been taken, decisions have been made, and a responsehas been provided to the Complainant.
                            </p>
                            <p className={s.submitParagraph}>
                                6. After investigating a complaint, <strong>the Company shall decide one of the following outcomes:</strong>
                            </p>
                            <p>
                                6.1. <span className={s.definitonName}>Satisfied</span> – the Company acknowledges that the Complainant's demands are legitimate and justified, and takes actions to fulfil them,<br/>
                                6.2. <span className={s.definitonName}>Partially satisfied</span>  – the Company determines that only a portion of the Complainant's demands are legitimate and justified and takes actions to fulfil the satisfied part,<br/>
                                6.3. <span className={s.definitonName}>Not satisfied</span> – the Company finds the Complainant's demands unjustified and, therefore, does not meet them,<br/>
                                6.4. <span className={s.definitonName}>Refuse to investigate</span> - the Company decides not to investigate the complaint, typically due to procedural or eligibility issues, such as the complaint falling outside the Company’s scope of responsibility or not meeting submission criteria.
                            </p>
                            <p className={s.submitParagraph}>7. The Company can refuse to investigate the Complaint if:</p>
                            <p>
                                7.1. The Complaint does not comply with the requirements set in Part II of these Rules or the shortcomings of the Complaint have not been eliminated within the set deadlines and, therefore, the investigation of the Complaint is impossible (e.g. the identity of the Complainant is not specified).<br/>
                                7.2.  The Complaint has been submitted regarding activities for which the Company is not responsible for (e.g. complained actions were executed by other financial service's provider).<br/>
                                7.3.  Decision of the Company has been adopted or decision of the Bank of Lithuania or the judgment, decision or order of the Court has come into force regarding the same object and the same basis as the Complaint, without submitting new documents casting doubt on the validity of the Company’s previous written response. Such a repeat Complaint shall be registered, and the Complainant shall be notified in writing within the established term that its Complaint has already been examined and the details of previous responses and the procedure for appealing the Company’s response shall be provided.<br/>
                            </p>
                            <p>
                                8. The response to the Complainant’s Complaint shall be provided in writing in the same manner as it was received, unless the Complainant indicates, when submitting the Complaint, that it prefers to receive a response in a different manner. The response shall be in the language in which the Complaint was lodged.
                            </p>
                            <p>
                                9. If the Company does not satisfy the Complainant’s demands or satisfies them in part, it shall in all cases provide a detailed and reasoned written response based on documents and indicate other means of defence of the Complainant’s interests, including possible means and institutions of resolution of the dispute.
                            </p>
                        </div>
                        <div className={s.subtitle}>
                            IV. Deadlines for Providing Responses to Complaints
                        </div>
                        <div className={s.text}>
                            <p className={s.submitParagraph}>
                                1. The Company will make the maximum effort to provide the response to the Complainant within the shortest possible time:
                            </p>
                            <p>
                                1.2. Upon receiving a complaint disputing the recognition of a payment transaction as properly authorised, the Company must commence its investigation without delay, no later than by the end of the next business day after receiving the complaint.<br/>
                                1.3.1. If the complainant is a consumer, the Company's response to the consumer's complaint must be provided no later than within 15 (fifteen) business days from the date of receipt of the written complaint. In case it is impossible to provide a response within15 business days due to reasons beyond the Company's control, a provisional response must be provided to the consumer, indicating the reason for the delay and the time by which the final response will be provided. The deadline for providing the final response cannot exceed 35 (thirty-five) business days from the date of receipt of the written claim complaint.<br/>
                                1.3.2. If the complainant is not a consumer, the Company's response to the complainant's complaint must be provided no later than within 30 (thirty) calendar days from the date of receipt of the complaint.
                            </p>
                            <p>
                                2. If the requested information is not provided by the Complainant or incomplete information is provided (see point 4 of the Part II), the time limit for the examination of the Complaint will start to run only after the Complainant has complied with the request to clarify the information.
                            </p>
                        </div>
                        <div className={s.subtitle}>
                        V. Storage of Complaints
                    </div>
                    <div className={s.text}>
                        <p >
                            1. Complaints submitted by the complainant and responses to those complaints are stored in the Companies internal electronic document management system.<br/>
                        </p>
                        <p>
                            2. Complaints with all the related materials and a response provided to the Complainant shall be stored not less than 3 years as of provision of the final response to the Complainant.
                        </p>
                    </div>

                    <div className={s.subtitle}>
                        VI. Evaluation of the results of investigation of complaints
                    </div>

                    <div className={s.text}>
                        <p className={s.submitParagraph}>
                            1.  To identify its weaknesses and potential legal or operational risks, the Company continually evaluates the outcome of the Complaint investigation. During this evaluation, the employee appointed by the manager of the Company:
                        </p>
                        <p>
                            1.1. collects information about similar Complaints related to a particular service or product, performs an analysis of this information to determine the root cause of the Complaints, as well as to offer the manager priorities for the removal of reasons,<br/>
                            1.2. assesses whether the root cause of certain Complaints may result in Complaints about other services or products,<br/>
                            1.3. assesses whether the root causes of the Complaints may be eliminated and suggests to the manager of the Company ways of remedying them,<br/>
                            1.4. if necessary, eliminates the identified root causes of the Complaints,<br/>
                            1.5. ensures that information on recurring or systemic causes of Complaints is routinely provided to the manager of the Company to enable him to perform his functions effectively.<br/>
                        </p>
                        <p>
                            2. The manager of the Company, having become aware of the information on the Complaints received by the Company and the results of their examination referred to in point 1 of the Section VI of the Rules, and having evaluated the received proposals regarding the priorities and methods of their elimination, shall take appropriate decisions to eliminate the identified root causes of the occurrence of Complaints.
                        </p>
                        <p>
                        3. The Company shall keep information about the decisions of the manager of the Company regarding the elimination of Complaints and risk management determined based on Complaints for at least 3 years.
                        </p>
                    </div>
                    <div className={s.subtitle}>
                        VII. Final provisions
                    </div>

                    <div className={s.text}>
                        <p>
                            1. The Complainant, believing that the Company has violated its rights or legitimate interests, arising from the provided financial services, and because of this dispute planning to apply to the Bank of Lithuania, firstly must provide the Company with the Complaint prepared in accordance with these Rules and other applicable legal acts.
                        </p>
                        <p>
                            2. The written response of the Company must provide information about the measures for protecting the Complainant’s interests, including but not limited to possible dispute resolution measures and rights.
                        </p>
                        <p>
                            3. In cases where the Complainant is a consumer, the written response provided to them must state that the Company's decision to refuse to satisfy or to partially satisfy the complaint submitted by the Complainant may be appealed to the Bank of Lithuania within one year from the date of submission of the complaint to the Company, in accordance with the Rules for the Out-of-Court Procedure of Settlement of the Disputes Between Consumers and Financial Market Participants in the Bank of Lithuania approved by resolution No. 03-23 of the Board of the Bank of Lithuania of January 26, 2012.
                        </p>
                        <p>
                            4. If the Company does not satisfy the Complainant’s requirements or partially satisfies them and the Complainant is not a consumer, the Complainant shall have the right to apply to the courts in accordance with the procedure established by the laws of the Republic of Lithuania.
                        </p>
                        <p>
                            5. The Complainant’s complaint to the Bank of Lithuania may be submitted in writing or electronically by filling in the <a href="https://www.lb.lt/uploads/documents/files/Application%20form_EN.docx?__cf_chl_tk=V1.5OALyVAcwC0BX9Dee31O8UHGQDqyWfogiUGMmj.Q-1739886978-1.0.1.1-b_HZrR57LCkDgW5KDfAWIklsZ8ZXouqUG0Vfx6RAD_4" className={s.link_small}> Consumer Application Form </a> and sending it to the Legal and Licensing Department of the Bank of Lithuania by email: <a href="mailto:prieziura@lb.lt" className={s.link_small}> prieziura@lb.lt </a>, or mail: Totorių g. 4, 01121 Vilnius, Lithuania.
                        </p>
                        <p>
                            6. You can find out more about consumer disputes with financial service providers on the website of the Bank of Lithuania: <a href="https://www.lb.lt/en/disputes-between-consumers-and-financial-market-participants" className={s.link_small}> https://www.lb.lt/en/disputes-between-consumers-and-financial-market-participants</a>
                        </p>
                        <p>
                            7. Confidentiality is treated seriously in complaints handling. Lux Pay always bear in mind legal requirements, for example, data protection legislation, as well as internal policies on confidentiality when handling the customers’ information.
                        </p>
                        <p>
                            8. The Company collects and, at the request of the Bank of Lithuania, provides information on the number of received Complaints, broken down according to the reasons for submission and the results of investigation.
                        </p>
                        <p className={s.complaintForm}>You can download the Complaint Form using the following link: <a href="#download" onClick={downloadFile} className={s.linkComplaintForm}>Complaints Form</a></p>
                    </div>
                   
                    </div>

                    
                </div>
            </div>
        </motion.div>
    );
};

export default Complaints;
