import React, {useEffect, useState} from 'react';
import s from "./ToAnLuxPayStepTwo.module.css";
import {ReactComponent as Back} from "../../../../../assets/img/personalAccount/back.svg";
import MyBtn from "../../../../../components/ui/MyBtn/MyBtn";
import {motion} from "framer-motion";
import Icon from "@ant-design/icons";
import TransparentButton from "../../../../../components/ui/TransparentButton/TransparentButton";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/redux";
import {GetLimitsListThunk, sendPinCodeEmailTrans, SendTransferThunk, verifyOldEmailThunk} from "../../../../../store/reducers/ActionCreators";
import CustomModal from "../../../../../components/ui/CustomModal/CustomModal";
import {
    PrepareDataForLuxpayTransaction,
} from "../../../../../helpers/PrepareDataForLuxpayTransaction";
import {setDisabled} from "../../../../../store/reducers/UserSlice";
import {areLimitsExceeded} from "../../../../../helpers/LimitsHelper";
import PopUpEmailCodConfirm from '../../../../../components/ui/PopUpEmailCodConfirm/PopUpEmailCodFonfirm';
import { calculateCurrentDate } from '../../../../../helpers/CalculateDate';

const ToAnLuxPayStepTwo = (props: any) => {

    const dispatch = useAppDispatch()
    const token = useAppSelector(state => state.userReducer.userToken)
    const accountId = useAppSelector(state => state.userReducer.chosenAccountTypeId)
    const clientId: string | null = useAppSelector(state => state.userReducer.user.userData.clientId)

    useEffect(() => {
        dispatch(GetLimitsListThunk(token, clientId));
    }, [dispatch, token, props.transferData.accNumberFrom]);

    const limitsInfo = useAppSelector(state => state.userReducer.userLimits);
    const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState<boolean | null>(false)
    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState<boolean | null>(false)
    const [isLimitPopupOpen, setIsLimitPopupOpen] = useState<boolean | null>(false)
    const [limitType, setLimitType] = useState<string | null>('');
    const [isAvailableAccountExists, setIsAvailableAccountExists] = useState<boolean | null>(true)
    const [isNotEnoughMoney, setIsNotEnoughMoney] = useState<boolean | null>(false)

    const [isShowVerifyEmailCode, setShowVerifyCode] = useState(false)

    const [isEmailCodeValue, setIsEmailCodeValue] = useState('')

    const [isEmailValid, setIsEmailValid] = useState('')

    const showVerifyModal = () => {
        setShowVerifyCode(true)
        dispatch(sendPinCodeEmailTrans(token))
    }
   

    const getEmailPin = (emailCode: string) => {
        setIsEmailCodeValue(emailCode)
        const newDateTrans = calculateCurrentDate()
        sendTransferData(emailCode, newDateTrans)
    }

    const closeEmailPinPopUp = () => {
        setShowVerifyCode(false)
    }

    const clearEmailPin = () => {
            
            setIsEmailValid('')
            setIsErrorPopupOpen(false)
        }   
    
        useEffect(() => {
            
        }, [isEmailValid]);

    const sendTransferData = async (emailCode: string, currentData:any) => {
        areLimitsExceeded(limitsInfo, setLimitType, setIsLimitPopupOpen)
        dispatch(setDisabled(true))
        const tempData = await PrepareDataForLuxpayTransaction(props.transferData, accountId, props.code, props.withdrawalStatus)

        const responseDate = {...tempData, emailCode, currentData}


        dispatch(SendTransferThunk(token, responseDate, accountId))
            .then((res) => {
                if (res.data === 'Created') {
                    setIsSuccessPopupOpen(true)
                } else {
                    setIsErrorPopupOpen(true)
                }
            })
            .catch((res) => {
                if (res.response
                      && (res.response.data === "Invalid to Account number"
                      || res.response.data === "User is not owner account")) {
                    setIsAvailableAccountExists(false);
                }
                if (res.response.data === "Not enough money to create transaction") {
                    setIsNotEnoughMoney(true)
                    return
                }
                if (res.response.data === "Daily Limit") {
                    setLimitType("daily")
                    setIsLimitPopupOpen(true)
                    return
                }
                if (res.response.data === "Monthly Limit") {
                    setLimitType("monthly")
                    setIsLimitPopupOpen(true)
                    return
                } if (res.response.data === "Invalid email code") {
                    setIsEmailValid(res.response.data)
                    
                    setIsErrorPopupOpen(true)
                } else {
                    setIsErrorPopupOpen(true)
                }
            })
            .finally(() => {
                dispatch(setDisabled(false))
            })
    }



    const closePopupAndRedirect = () => {
        setIsSuccessPopupOpen(false)
        localStorage.removeItem('luxpay-transfer-form')
        props.setCurrent(0)
    }

    const accountsErrorAndRedirect = () => {
        setIsAvailableAccountExists(true)
        localStorage.removeItem('luxpay-transfer-form')
        props.setCurrent(0)
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >

            <CustomModal type="error" isNotify isModalOpen={isNotEnoughMoney} setIsModalOpen={setIsErrorPopupOpen}>
                <div className={s.modal_content}>
                    <div className={s.popup_subtitle}>
                        <span className={s.error}>Failed!</span> The transfer cannot be made due to insufficient funds.
                    </div>
                    <div className={s.popup_button_block}>
                        <MyBtn buttonType="error" isPersonalAccountBtn title={"Ok"} onClick={() => setIsNotEnoughMoney(false)}/>
                    </div>
                </div>
            </CustomModal>

            <CustomModal isModalOpen={isSuccessPopupOpen} isNotify setIsModalOpen={setIsSuccessPopupOpen}>
                <div className={s.modal_content}>
                    <div className={s.popup_subtitle}>
                        <span className={s.success}>Success!</span> Your request has been sent successfully.
                    </div>
                    <div className={s.popup_button_block}>
                        <MyBtn title={'Ok'} isPersonalAccountBtn medium onClick={() => closePopupAndRedirect()}/>
                    </div>
                </div>
            </CustomModal>

            <CustomModal type="error" isNotify isModalOpen={isErrorPopupOpen} setIsModalOpen={setIsErrorPopupOpen}>
                <div className={s.modal_content}>
                    <div className={s.popup_subtitle}>
                        <span className={s.error}>Failed!</span> {isEmailValid ? "Your email code hasn’t been verified. Please try again" : "Your request was rejected. Please try again."}
                    </div>
                    <div className={s.popup_button_block}>
                        <MyBtn buttonType="error" isPersonalAccountBtn title={"Ok"} onClick={clearEmailPin}/>
                    </div>
                </div>
            </CustomModal>

            <CustomModal type="error" isNotify isModalOpen={isLimitPopupOpen} setIsModalOpen={setIsLimitPopupOpen}>
                <div className={s.modal_content}>
                    <div className={s.popup_subtitle}>
                        <span className={s.error}>Failed!</span> The transaction is impossible due to exceeding {limitType} limit.
                        Please contact <a className={s.popup_link} href='mailto:support@luxpay.lt'>support@luxpay.lt</a>&nbsp;
                        if you have any questions.
                    </div>
                    <div className={s.popup_button_block}>
                        <MyBtn buttonType="error" isPersonalAccountBtn title={"Ok"} onClick={() => setIsLimitPopupOpen(false)} />
                    </div>
                </div>
            </CustomModal>

            <CustomModal type="error" isNotify isModalOpen={!isAvailableAccountExists} setIsModalOpen={setIsAvailableAccountExists}>
                <div className={s.modal_content}>
                    <div className={s.popup_subtitle}>
                        <span className={s.error}>Failed!</span> The transfer cannot be made to the specified&nbsp;
                        {props.transferData?.transferMethod === "account" ? "Wallet Id"
                          : (props.transferData?.transferMethod === "phone" ? "phone number"
                            :props.transferData?.transferMethod?.toLowerCase())} due to the lack of an account with the recipient.
                    </div>
                    <div className={s.popup_button_block}>
                        <MyBtn buttonType="error" isPersonalAccountBtn title={"Ok"} onClick={() => accountsErrorAndRedirect()}/>
                    </div>
                </div>
            </CustomModal>

            <div className={s.title}>
                Transfer Information
            </div>
            <div className={s.data_block}>
                <div className={s.row}>
                    <span className={s.row_name}>Payer account</span>
                    <span className={s.row_value}>
                        {
                            props.transferData.accNumberFrom !== ''
                                ? props.transferData.accNumberFrom
                                : '-'
                        }
                    </span>
                </div>

                <div className={s.row}>
                    <span className={s.row_name}>Recipient’s account</span>
                    <span className={s.row_value}>
                        {
                            props.transferData.accNumberTo !== ''
                                ? props.transferData.accNumberTo
                                : '-'
                        }
                    </span>
                </div>

                <div className={s.row}>
                    <span className={s.row_name}>Amount</span>
                    <span className={s.row_value}>
                        {
                            props.transferData.amount !== ''
                                ? `${props.transferData.amount} ${props.transferData.transferCurrency}`
                                : '-'
                        }
                    </span>
                </div>

                <div className={s.row}>
                    <span className={s.row_name}>Reference</span>
                    <span className={s.row_value}>
                        {
                            props.transferData.purpose !== ''
                                ? props.transferData.purpose
                                : '-'
                        }
                    </span>
                </div>

                <div className={s.row}>
                    <span className={s.row_name}>Template name</span>
                    <span className={s.row_value}>
                        {
                            props.transferData.templateName !== ''
                                ? props.transferData.templateName
                                : '-'
                        }
                    </span>
                </div>

                {
                    (props.transferData.invoice !== '' && props.transferData.invoice?.name!== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Invoice</span>
                        <span className={s.row_value}>
                            {props.transferData.invoice?.name}
                        </span>
                    </div>
                }

            </div>
            <div className={s.buttons_block}>
                <div className={s.button_wrapper}>
                    <TransparentButton
                        icon={<Icon component={() => <Back/>} rev={undefined}/>}
                        large
                        title={"Back"}
                        isPersonalAccountBtn
                        onClick={() => props.setCurrent(0)}/>
                </div>

                <div className={s.button_wrapper}>
                    {/* <MyBtn
                        title={"Transfer"}
                        large
                        isPersonalAccountBtn onClick={() => sendTransferData()}/> */}
                         <MyBtn
                        title={"Transfer"}
                        large
                        isPersonalAccountBtn onClick={showVerifyModal}/>
                </div>
                {isShowVerifyEmailCode ? <PopUpEmailCodConfirm getPinEmail={getEmailPin} onClose={closeEmailPinPopUp} /> : null}
            </div>
        </motion.div>
    );
};

export default ToAnLuxPayStepTwo;
