import React, {Dispatch, useEffect, useState} from 'react';
import CustomModal from "../components/ui/CustomModal/CustomModal";
import styles from "../styles/styles.module.css";
import MyBtn from "../components/ui/MyBtn/MyBtn";
import iconInfo from "../assets/img/personalAccount/info-circle.svg";
import MyInput from "../components/ui/MyInput/MyInput";
import TransparentButton from "../components/ui/TransparentButton/TransparentButton";
import {useAppDispatch, useAppSelector} from "../hooks/redux";
import {useFormik} from "formik";
import {
    checkWithdrawalStatusThunk,
    sendVerifyCodeToEmailThunk,
    sendWithdrawalPinCodeThunk, setTokenState
} from "../store/reducers/ActionCreators";
import * as yup from "yup";
import {setIsLoading} from '../store/reducers/UserSlice';

const validationSchema = yup.object({
    withdrawalCode: yup.string()
        .required("Required field")
        .matches(/^[0-9/,]+$/, "Must be only digits")
        .max(6, "Only 6 digits")
        .min(6, "Only 6 digits")
})

type verifyWithdrawalPropsType = {
    setCode: Dispatch<React.SetStateAction<string>>
    setIsButtonDisabled: Dispatch<React.SetStateAction<boolean>>
    setWithdrawalStatus: Dispatch<React.SetStateAction<string>>
}

const VerifyWithdrawalHelper = (props: verifyWithdrawalPropsType) => {

    const token: string = useAppSelector(state => state.userReducer.userToken)
    const dispatch = useAppDispatch()
    const userEmail: string | null = useAppSelector(state => state.userReducer.user.userData.email)
    const [isModalOpen, setIsModalOpen] = useState<boolean | null>(false);
    const [isVerifyWithdrawalSuccess, setIsVerifyWithdrawalSuccess] = useState<boolean | null>(null);
    const [isBlockedModal, setIsBlockedModal] = useState<boolean | null>(false);

    const verifyWithdrawalFormik = useFormik({
        initialValues: {
            withdrawalCode: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(sendWithdrawalPinCodeThunk(token, values.withdrawalCode))
                .then(() => {
                    props.setCode(values.withdrawalCode)
                    setIsModalOpen(false)
                    props.setIsButtonDisabled(false)
                    setIsVerifyWithdrawalSuccess(true)
                    props.setWithdrawalStatus('allowed')
                })
                .catch((e) => {
                    setIsVerifyWithdrawalSuccess(false)
                })
        },
    })

    useEffect(() => {
        dispatch(setIsLoading(true))
        dispatch(checkWithdrawalStatusThunk(token))
            .then((res: any) => {
                if (res.data === 'allowed') {                
                    props.setIsButtonDisabled(false)
                    props.setWithdrawalStatus("allowed")
                    dispatch(setIsLoading(false))
                } else if (res.data === 'notVerified') {
                    props.setWithdrawalStatus("notVerified")
                    dispatch(sendVerifyCodeToEmailThunk(token))
                        .then(() => {
                            setIsModalOpen(true)
                        })
                        .catch((e: any) => {
                            console.log(e)
                        })
                        .finally(() => {
                            dispatch(setIsLoading(false))
                        })
                } else if (res.data === 'blocked') {
                    setIsBlockedModal(true)
                    props.setWithdrawalStatus("blocked")
                    dispatch(setIsLoading(false))
                }
            })
            .catch((e: any) => {
                if (e.response.data === "Invalid authorization data") {
                    dispatch(setTokenState(false))
                    dispatch(setIsLoading(false))
                }
            })
    }, []);

    return (
        <div>
            <CustomModal type={"error"} isNotify isModalOpen={isBlockedModal}>
                <div className={styles.modal_content}>
                    <div className={styles.popup_subtitle}>
                        <span className={styles.error}>Failed!</span> You can’t perform transactions as you loggen in to
                        your account from new IP address less then 24 hours ago.
                    </div>
                    <div className={styles.popup_button_block}>
                        <MyBtn buttonType={"error"} isPersonalAccountBtn title={"Ok"}
                               onClick={() => setIsBlockedModal(false)}/>
                    </div>
                </div>
            </CustomModal>

            <CustomModal type={"confirm"} isNotify isModalOpen={isVerifyWithdrawalSuccess}>
                <div className={styles.modal_content}>
                    <div className={styles.popup_subtitle}>
                        <span className={styles.success}>Success!</span>
                    </div>
                    <div className={styles.popup_button_block}>
                        <MyBtn isPersonalAccountBtn title={"Ok"} onClick={() => setIsVerifyWithdrawalSuccess(null)}/>
                    </div>
                </div>
            </CustomModal>

            {isModalOpen && <div className={styles.verify_modal}>
                <CustomModal isModalOpen={isModalOpen} type={"info"}>
                    <form onSubmit={verifyWithdrawalFormik.handleSubmit}>
                        <div className={styles.modal_content}>
                            <img src={iconInfo} alt="" className={styles.popup_icon}/>
                            <div className={styles.popup_title}>
                                <div className={styles.popup_text_block}>
                                    As you loggen in to your account from new IP address please complete email
                                    authentication to
                                    perform transactions.
                                </div>
                            </div>
                            <div className={styles.popup_subtitle_block}>
                                <div className={styles.popup_subtitle}>
                                    Email with confirmation code was sent to email address {userEmail}
                                </div>
                            </div>
                            <div className={styles.input_wrapper}>
                                <span className={styles.input_label}>Confirmation Code</span>
                                <MyInput
                                    name={'withdrawalCode'}
                                    id={'withdrawalCode'}
                                    touched={verifyWithdrawalFormik.touched.withdrawalCode}
                                    isError={verifyWithdrawalFormik.errors.withdrawalCode}
                                    onChange={verifyWithdrawalFormik.handleChange}
                                />
                                {verifyWithdrawalFormik.errors.withdrawalCode && <div className={styles.error_text}>
                                    {verifyWithdrawalFormik.errors.withdrawalCode}
                                </div>}
                            </div>
                            <div className={styles.buttonWrapper}>
                                <div className={styles.popup_button_block}>
                                    <TransparentButton
                                        isPersonalAccountBtn
                                        title={"Cancel"}
                                        onClick={() => setIsModalOpen(false)}
                                        medium
                                        buttonType={"info"}
                                    />
                                </div>
                                <div className={styles.popup_button_block}>
                                    <MyBtn
                                        isPersonalAccountBtn
                                        title={"Confirm"}
                                        type={"submit"}
                                        buttonType={"info"}
                                    />
                                </div>
                            </div>
                        </div>

                        {isVerifyWithdrawalSuccess === false && <div className={styles.error_modal_block}>
                            <CustomModal type={"error"} isNotify isModalOpen={isVerifyWithdrawalSuccess === false}>
                                <div className={styles.modal_content}>
                                    <div className={styles.popup_subtitle}>
                                        <span className={styles.error}>Failed!</span> Your email hasn’t been verified.
                                        Please try
                                        again.
                                    </div>
                                    <div className={styles.popup_button_block}>
                                        <MyBtn buttonType={"error"} isPersonalAccountBtn title={"Ok"}
                                               onClick={() => setIsVerifyWithdrawalSuccess(null)}/>
                                    </div>
                                </div>
                            </CustomModal>
                        </div>}

                    </form>
                </CustomModal>
            </div>}
        </div>
    );
};

export default VerifyWithdrawalHelper;
