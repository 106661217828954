import React from 'react';
import s from './KybSuccess.module.css'
import ok from '../../../assets/img/personalAccount/tick-circle.svg'

const KybSuccess = () => {

    return (
        <div className={s.wrapper}>
            <div className={s.content}>
                <div className={s.flex}>
                    <img src={ok} alt="" className={s.img}/>
                    <p className={s.title}>Success!</p>
                    <p className={s.subtitle}>Your KYB was sent for review. Please leave this page.</p>

                    <div className={s.description_block}>
                        <span className={s.description}>
                        Please note: You will receive an email with a link for identity verification shortly. We kindly ask that you proceed with the verification once the link is received to complete your application. Feel free to contact <span
                            className={s.colorfull}>support@luxpay.lt</span> if you have any questions.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KybSuccess;
