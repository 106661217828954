import {createSlice} from "@reduxjs/toolkit";
import {ITemplate} from "../../../types/interfaces";

type TransactionState = {
    transactionsData: Array<ITransaction>,
    filters: IFilters,
    templates: Array<ITemplate>,
    filtersTemplates: IFiltersTemplates,
    currentTransferCurrency: string
}

export interface IFiltersTemplates {
    accountNumber: string,
    recipient: string,
    templateName: string
}

export interface IFilters {
    hiddenAccountNumber?: string;
    accountNumber: string,
    period?: string | null,
    from?: string,
    to?: string,
    purpose?: string,
    recipient?: string,
    amount?: string,
    incoming?: boolean,
    outgoing?: boolean,
    other?: boolean,
    fees?: boolean,
    currency: string,
    selectedType?: string,
    templateName?: string
    paymentRegion?: string
}

export interface ITransaction {
    toAccountUserName?: string;
    deniedReason?: string | null;
    amount: number,
    balance: number,
    bankAddress: string | null,
    bankName: string | null,
    beneficiaryAddress: string | null,
    beneficiaryCity: string | null,
    beneficiaryCountry: string | null,
    beneficiaryName: string | null,
    created: string,
    currency: string,
    direction: string,
    fromAccount: string,
    ibanCode: string | null,
    reference: string,
    recipient: string,
    swiftCode: string | null,
    toAccount: string,
    transactionNumber: string,
    transactionStatus: string,
    transactionType: string
    recipientDetails : {
        beneficiarySortCode?: string | null;
        bankAddress: string
        bankName: string
        beneficiaryAddress: string
        beneficiaryCity: string
        beneficiaryCountry: string
        beneficiaryName: string
        ibanCode: string
        swiftCode: string
        beneficiaryAccountNumber?: string | null
    }
}

const initialState: TransactionState = {
    transactionsData: [],
    filters: {
        accountNumber: '',
        period: null,
        from: '',
        to: '',
        purpose: '',
        recipient: '',
        amount: '',
        incoming: false,
        outgoing: false,
        currency: '',
        selectedType: '',
        templateName: '',
        paymentRegion: ''
    },
    filtersTemplates: {
        accountNumber: '',
        recipient: '',
        templateName: ''
    },
    templates: [],
    currentTransferCurrency: ''
}

export const transactionsSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        setTransactionsData(state, action) {
            state.transactionsData = action.payload
        },
        setFilters(state, action) {
            state.filters = action.payload
        },
        setPaymentRegionFilter(state, action) {
            state.filters.paymentRegion = action.payload
        },
        setTransactionTypeFilter(state, action) {
            state.filters.selectedType = action.payload
        },
        setFiltersTemplates(state, action) {
            state.filtersTemplates = action.payload
        },
        setTemplateList(state, action) {
            state.templates = action.payload
        },
        setCurrentTransferCurrency(state, action) {
            state.currentTransferCurrency = action.payload
        },
        clearTransactionSlice(state) {
            state.transactionsData = []
            state.filters = {
                accountNumber: '',
                period: null,
                from: '',
                to: '',
                purpose: '',
                recipient: '',
                amount: '',
                incoming: false,
                outgoing: false,
                currency: '',
                paymentRegion: '',
            }
            state.filtersTemplates = {
                accountNumber: '',
                recipient: '',
                templateName: ''
            }
            state.templates = []
        }
    }
})
export const {setTransactionsData, setFilters, setTemplateList, setFiltersTemplates, setPaymentRegionFilter, setTransactionTypeFilter} = transactionsSlice.actions;

export default transactionsSlice.reducer
