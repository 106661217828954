import { zeroFill } from "./ZeroFill";

export const myDate = new Date();
export const weekDay = myDate.getDay();
export const monthDay = myDate.getDate();
export const month = myDate.getMonth();
export const getDaysInMonth = (y: number, m: number) => new Date(y, m + 1, 0).getDate();
export const countDayOnMonth = getDaysInMonth(myDate.getFullYear(), month);
export const getFirstWeekDay = () => new Date(myDate.getFullYear(), month, (monthDay - weekDay));
export const getLastWeekDay = () => new Date(myDate.getFullYear(), month, (monthDay + (6 - weekDay)));
export const getFirstDayOfCurrentMonth = () => new Date(myDate.getFullYear(), month, 1);
export const getLastDayOfCurrentMonth = () => new Date(myDate.getFullYear(), month, countDayOnMonth);
export const getFirstDayOfLastWeek = () => new Date(myDate.getFullYear(), month, ((monthDay - 7) - weekDay))
export const getLastDayOfLastWeek = () => new Date(myDate.getFullYear(), month, ((monthDay - 7) + (6 - weekDay)))
export const getFirstDayOfPreviousMonth = () => new Date(myDate.getFullYear(), month - 1, 1);
export const getLastDayOfPreviousMonth = () => {
    return new Date(myDate.getFullYear(), myDate.getMonth() - 1, getDaysInMonth(myDate.getFullYear(), myDate.getMonth() - 1));
}

export const calculateCurrentDate = () => {
    const currentDate = new Date();

    const year = currentDate.getFullYear(); // Год
    const month = zeroFill(currentDate.getMonth() + 1, 2); // Месяц (01-12)
    const day = zeroFill(currentDate.getDate(), 2); // День (01-31)
    const hours = zeroFill(currentDate.getHours(), 2); // Часы (00-23)
    const minutes = zeroFill(currentDate.getMinutes(), 2); // Минуты (00-59)
    const seconds = zeroFill(currentDate.getSeconds(), 2); // Секунды (00-59)

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
