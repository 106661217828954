import React, {useState} from 'react';
import s from './PersonalAccountHeader.module.css'
import burger from '../../assets/img/mobile/burger.svg'
import NavBar from "../Header/NavBar/NavBar";
import CustomModalContactContainer from "../ui/CustomModalContactContainer/CustomModalContactContainer";
import CustomCheckbox from "../ui/customCheckbox/CustomCheckbox";
import classnames from "classnames";
import plus from "../../assets/img/personalAccount/plus_black.svg";
import useOutsideClick from "../../hooks/useOutsideClick";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {
    GetAccoutDetailsByAccountTypeThunk,
    LogoutThunk,
    setChosenAccountTypeIdThunk
} from "../../store/reducers/ActionCreators";
import {useNavigate} from "react-router-dom";
import {calculateCurrentDate} from "../../helpers/CalculateDate";

const PersonalAccountHeader = () => {

    const [navIsShow, setNavIsShow] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState<boolean | null>(false)
    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    const {ref, ref2, isActive, setIsActive} = useOutsideClick(false);

    const clientId: string | null = useAppSelector(state => state.userReducer.user.userData.clientId)
    const sessionId = useAppSelector(store => store.userReducer.sessionId)
    const firstName: string | null = useAppSelector(state => state.userReducer.user.userData.firstName)
    const lastName: string | null = useAppSelector(state => state.userReducer.user.userData.lastName)
    const companyName: string | null = useAppSelector(state => state.userReducer.user.userData.companyName)
    const accTypeList = useAppSelector(state => state.userReducer.accountTypesList)
    const chosenAccountTypeId = useAppSelector(state => state.userReducer.chosenAccountTypeId)
    const token = useAppSelector(state => state.userReducer.userToken)
    const [isModalForCreatingPersonalOpen, setIsModalForCreatingPersonalOpen] = useState(false)
    const [isModalForCreatingBusinessOpen, setIsModalForCreatingBusinessOpen] = useState(false)

    const changeAccountType = (id: number) => {
        dispatch(setChosenAccountTypeIdThunk(id))
        dispatch(GetAccoutDetailsByAccountTypeThunk(token, id))
    }

    const openAccountCreatingPopup = () => {
        if (accTypeList[0].accountType === 'personal') {
            setIsModalForCreatingBusinessOpen(true)
        } else {
            setIsModalForCreatingPersonalOpen(true)
        }
    }

    const handleLogout = () => {
        dispatch(LogoutThunk(sessionId, calculateCurrentDate(), token))
            .then(() => {
                navigate("/login");
            })
    }

    return (
        <>
            <CustomModalContactContainer
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
            />
            <div className={s.wrapper}>

                <div className={s.right}>

                    <div className={s.round} onClick={() => setIsModalOpen(true)}>
                        <div className={s.mail}></div>
                    </div>

                    <div className={isActive ? s.userRoundActive : s.userRound}
                         onClick={() => {
                             setIsActive(!isActive)
                         }}
                         ref={ref2}
                    >
                        <div className={s.always_show}>
                            <div className={s.user_typography}>
                                <div><div className={s.name}><span >{firstName} {lastName}</span>  {companyName}</div>
                                </div>
                                <div><p className={s.id}>ID: {clientId}</p></div>
                            </div>
                            <div className={s.user}></div>
                        </div>

                        <div
                            ref={ref}
                            className={isActive ? s.openDetails : s.openDetailsHidden}
                        >
                            <div>
                                {
                                    accTypeList.map((acc) =>
                                        <div className={s.checkbox_block} key={acc.id}>
                                            {!acc.activeStatus &&
                                                <span className={s.inactive_notify}>Account is inactive</span>}
                                            <label className={s.flex_label} onClick={() => {
                                                if (acc.activeStatus) {
                                                    changeAccountType(acc.id)
                                                }
                                            }}>
                                                <CustomCheckbox
                                                    handleChange={() => {
                                                    }}
                                                    id={acc.accountType}
                                                    name={acc.accountType}
                                                    isChecked={chosenAccountTypeId === acc.id}

                                                />
                                                <span
                                                    className={classnames(s.account_type_label, !acc.activeStatus && s.inactive_account)}>
                                                                        {acc.accountType === 'personal' ? 'Personal account' : 'Business account'}
                                                                    </span>
                                            </label>
                                        </div>
                                    )
                                }
                                {accTypeList.length === 1 &&
                                    <div className={s.checkbox_block}>
                                        <label className={s.flex_label}
                                               onClick={() => openAccountCreatingPopup()}>
                                            <img src={plus} alt="" className={s.icon_plus}/>
                                            <span className={s.account_type_label}>
                                                                    {
                                                                        accTypeList[0].accountType === 'personal'
                                                                            ? 'Add business account'
                                                                            : 'Add personal account'
                                                                    }
                                                                </span>
                                        </label>
                                    </div>
                                }
                            </div>
                            <div className={s.logout_block}>
                                <div className={s.logout_row} onClick={() => handleLogout()}>
                                    <div className={s.img_logout}></div>
                                    <div className={s.text}>Log out</div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div className={s.left}>
                    <div>
                        <img src={burger} alt="" onClick={() => setNavIsShow(true)}/>
                    </div>
                </div>
                <NavBar isActive={navIsShow} setIsActive={setNavIsShow}/>
            </div>
        </>
    );
};

export default PersonalAccountHeader;
