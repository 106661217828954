import {motion} from 'framer-motion';
import React, {useState} from 'react';
import s from './ProfileSettings.module.css'
import MyInput from "../../../../../components/ui/MyInput/MyInput";
import MyBtn from "../../../../../components/ui/MyBtn/MyBtn";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/redux";
import {Formik} from "formik";
import * as yup from "yup";
import {createNewPasswordThunk, setTokenState} from "../../../../../store/reducers/ActionCreators";
import CustomModal from "../../../../../components/ui/CustomModal/CustomModal";
import {ReactComponent as Edit} from "../../../../../assets/img/personalAccount/edit.svg";
import ChangeEmail from "../../../../../components/ChangeEmail/ChangeEmail";
import info from "../../../../../assets/img/personalAccount/information.svg";
import {Popover} from "antd";
import Icon from "@ant-design/icons";
import ChangePhone from "../../../../../components/ChangePhone/ChangePhone";
import styles from "../../../../../styles/styles.module.css"

const validationSchema = yup.object({
    oldPassword: yup.string().required('Required field'),
    newPassword: yup.string()
        .min(8, "min 8")
        .matches(/[1-9]+/, "Password must contain at least one number")
        .matches(/[a-z]+/, "Password must contain at least one lowercase")
        .matches(/[A-Z]+/, "Password must contain at least one uppercase")
        .required('Required field')
        .test({message: 'Please enter a different password (not the current one)',
            test: function (value) {
                const { oldPassword } = this.parent;
                return oldPassword !== value;
            },
        }),
    confirmPassword: yup.string().required('Required field')
        .test(
            'passwords-match',
            'Passwords must match', function (value) {
            const { newPassword } = this.parent;
            return value === newPassword;
    })
})

const ProfileSettings = () => {

    const userInfo = useAppSelector(state => state.userReducer.user)
    const token = useAppSelector(state => state.userReducer.userToken)
    const dispatch = useAppDispatch()

    const [isModalOpen, setIsModalOpen] = useState<boolean | null>(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean | null>(false);
    const [errorText, setErrorText] = useState('');
    const [isChangeEmailProcess, setIsChangeEmailProcess] = useState<boolean | null>(false)
    const [isChangePhoneProcess, setIsChangePhoneProcess] = useState<boolean | null>(false)

    const closePopup = () => {
        setIsModalOpen(false)
        setIsErrorModalOpen(false)
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >

            <ChangeEmail isChangeEmailProcess={isChangeEmailProcess} setIsChangeEmailProcess={setIsChangeEmailProcess}/>

            <ChangePhone isChangePhoneProcess={isChangePhoneProcess} setIsChangePhoneProcess={setIsChangePhoneProcess}/>

            <CustomModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} type={"confirm"}>
                <div className={styles.modal_content}>
                    <div className={styles.popup_title}>
                        Success!
                    </div>
                    <div className={styles.popup_subtitle}>
                        Your password has been changed.
                    </div>
                    <div className={styles.popup_button_block}>
                        <MyBtn isPersonalAccountBtn title={"Ok"} onClick={() => closePopup()}/>
                    </div>
                </div>
            </CustomModal>

            <CustomModal type={"error"} isModalOpen={isErrorModalOpen} setIsModalOpen={setIsErrorModalOpen}>
                <div className={s.flex_column}>
                    <div className={s.modal_title}>
                        Failed!
                    </div>
                    <div className={s.modal_subtitle}>
                        {errorText}
                    </div>
                    <div className={s.button_block}>
                        <MyBtn title={"Ok"} buttonType={"error"} isPersonalAccountBtn onClick={() => closePopup()}/>
                    </div>
                </div>
            </CustomModal>

            <div className={s.wrapper}>
                <div className={s.accounts_list_block}>
                    <div className={s.list_title}>
                        Personal Information
                    </div>
                    {
                        userInfo.userData.accountType === "personal"
                            ? <div className={s.accounts_list}>
                                <div className={s.list_block}>
                                    <div className={s.list_block_title}>
                                        First Name
                                    </div>
                                    <div className={s.user}>
                                        {userInfo.userData.firstName}
                                    </div>
                                </div>
                                <div className={s.list_block}>
                                    <div className={s.list_block_title}>
                                        Last Name
                                    </div>
                                    <div className={s.user}>
                                        {userInfo.userData.lastName}
                                    </div>
                                </div>
                            </div>
                            : <div className={s.accounts_list}>
                                <div className={s.list_block}>
                                    <div className={s.list_block_title}>
                                        company name
                                    </div>
                                    <div className={s.user}>
                                        {userInfo.userData.companyName}
                                    </div>
                                </div>
                                <div className={s.list_block}>

                                </div>
                            </div>
                    }
                    <div className={s.accounts_list}>
                        <div className={s.list_block}>
                            <div className={s.list_block_title}>
                                id
                                <Popover content={"Your ID number generated during registration."}
                                         color={'#EEEFEF'}
                                         overlayInnerStyle={{
                                             fontFamily: 'Manrope',
                                             width: '280px',
                                             padding: '13px 16px',
                                             cursor: 'pointer',
                                             backgroundColor: '#E7F0EC'
                                         }}
                                >
                                    <img src={info} alt=""/>
                                </Popover>
                            </div>
                            <div className={s.list_block_value}>
                                <span className={s.list_block_value}>{userInfo.userData.clientId}</span>
                            </div>
                        </div>
                        <div className={s.list_block}>
                            <div className={s.list_block_title}>
                                TYPE
                            </div>
                            <div className={s.list_block_value}>
                                <span className={s.list_block_value}>{userInfo.userData.accountType}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={s.email_block}>
                    <div className={s.list_title}>
                        Contact Information
                    </div>

                    <div className={s.personal_information_block}>
                        <div className={s.row}>
                            <div className={s.information_title}>
                                Email
                            </div>
                            <div className={s.information_title}>
                                Action
                            </div>
                        </div>
                        <div className={s.row_padding}>
                            <div className={s.contact_information_value}>
                                {userInfo.userData.email}
                            </div>
                            <div className={s.action} onClick={() => setIsChangeEmailProcess(true)}>
                                <Icon component={() => <Edit/>} rev={undefined}/>
                                Change
                            </div>
                        </div>
                    </div>

                    <div className={s.personal_information_block}>
                        <div className={s.row}>
                            <div className={s.information_title}>
                                Phone number
                            </div>
                            <div className={s.information_title}>
                                Action
                            </div>
                        </div>
                        <div className={s.row_padding}>
                            <div className={s.contact_information_value}>
                                {userInfo.userData.callPhone}
                            </div>
                            <div className={s.action} onClick={() => setIsChangePhoneProcess(true)}>
                                <Icon component={() => <Edit/>} rev={undefined}/>
                                Change
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div className={s.wrapper}>
                <Formik
                    initialValues={{
                        oldPassword: '',
                        newPassword: '',
                        confirmPassword: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, {resetForm}) => {
                        dispatch(createNewPasswordThunk(token, values))
                            .then((res: any) => {
                                if (res.data === "Done") {
                                    setIsModalOpen(true)
                                    resetForm()
                                }
                            })
                            .catch((e: any) => {
                                if (e.response.data === "Invalid old password") {
                                    setErrorText("The old password is wrong.")
                                    setIsErrorModalOpen(true)
                                } else if (e.response.data === "Invalid authorization data") {
                                    dispatch(setTokenState(false))
                                } else {
                                    setErrorText("Something went wrong. Try again a little later or contact \n" +
                                        "our support team.")
                                    setIsErrorModalOpen(true)
                                }
                            })
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit
                      }) => (
                        <form onSubmit={handleSubmit} className={s.form}>
                            <div className={s.change_password_block}>
                                <div className={s.change_password_title}>
                                    Change Password
                                </div>
                                <div className={s.change_password_subtitle}>
                                    The new password must contain a minimum 8 characters with at least:
                                    <p className={s.yellow}>1 lowercase, 1 uppercase, and 1 number.</p>
                                </div>
                                <div className={s.input_block}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            * current password
                                        </div>
                                        <MyInput id='oldPassword'
                                                 name='oldPassword'
                                                 password
                                                 onChange={handleChange}
                                                 value={values.oldPassword}
                                                 isError={errors.oldPassword}
                                                 touched={touched.oldPassword}
                                        />
                                        {errors.oldPassword &&
                                            <div className={s.field_error}>{errors.oldPassword}</div>}
                                    </div>
                                </div>
                                <div className={s.input_block}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            * New password
                                        </div>
                                        <MyInput id='newPassword'
                                                 name='newPassword'
                                                 password
                                                 onChange={handleChange}
                                                 value={values.newPassword}
                                                 isError={errors.newPassword}
                                                 touched={touched.newPassword}
                                        />
                                        {errors.newPassword &&
                                            <div className={s.field_error}>{errors.newPassword}</div>}
                                    </div>
                                </div>
                                <div className={s.input_block}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            * Confirm New Password
                                        </div>
                                        <MyInput id='confirmPassword'
                                                 name='confirmPassword'
                                                 password
                                                 onChange={handleChange}
                                                 value={values.confirmPassword}
                                                 isError={errors.confirmPassword}
                                                 touched={touched.confirmPassword}
                                        />
                                        {errors.confirmPassword &&
                                            <div className={s.field_error}>{errors.confirmPassword}</div>}
                                    </div>
                                </div>
                                <div className={s.button_wrapper}>
                                    <div className={s.button_block}>
                                        <MyBtn title={'Change'} type={"submit"} isPersonalAccountBtn/>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </motion.div>
    );
};

export default ProfileSettings;
