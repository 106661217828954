import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {Checkbox, ConfigProvider, DatePicker} from "antd";
import s from "./StatementForm.module.css";
import dayjs from "dayjs";
import MyInput from "../../../../../components/ui/MyInput/MyInput";
import MyBtn from "../../../../../components/ui/MyBtn/MyBtn";
import {Formik, useFormikContext} from "formik";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import {setFilters} from "../../../../../store/reducers/TransactionsSlice/TransactionsSlice";
import {useAppDispatch} from "../../../../../hooks/redux";
import CustomDropdownForAccounts
    from "../../../../../components/ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import CustomDropdown from "../../../../../components/ui/CustomDropdown/CustomDropdown";
import {
    getFirstDayOfCurrentMonth, getFirstDayOfLastWeek, getFirstDayOfPreviousMonth,
    getFirstWeekDay,
    getLastDayOfCurrentMonth, getLastDayOfLastWeek, getLastDayOfPreviousMonth,
    getLastWeekDay,
    myDate
} from "../../../../../helpers/CalculateDate";
import * as yup from "yup";
import {useAccountsForSelect} from "../../../../../hooks/useAccountsForSelect";
import {zeroFill} from "../../../../../helpers/ZeroFill";
dayjs.extend(weekday)
dayjs.extend(localeData)

type propsType = {
    setDataForStatement: Dispatch<SetStateAction<any>>,
    scrollPage: any;
}

const optionsTwo: string[] = [
    'Indicated by user',
    'Today',
    'Yesterday',
    'This week',
    'This month',
    'Last week',
    'Last 30 days',
    'Last month',
    'Last and current month',
];

const dateFormat = 'YYYY-MM-DD';

const SetDateByPeriod = (props: any) => {

    const { values } = useFormikContext();

    useEffect(() => {
        // @ts-ignore
        if (values.period === 'Today') {
            props.setDate({
                ...props.date,
                from: (myDate.getFullYear() + "-" + (zeroFill(myDate.getMonth() + 1, 2)) + "-" + zeroFill(myDate.getDate(), 2)),
                to: (myDate.getFullYear() + "-" + (zeroFill(myDate.getMonth() + 1, 2)) + "-" + zeroFill(myDate.getDate(), 2))
            })
        }
        // @ts-ignore
        if (values.period === 'Yesterday') {
            props.setDate({
                ...props.date,
                from: (myDate.getFullYear() + "-" + (zeroFill(myDate.getMonth() + 1, 2)) + "-" + (zeroFill(myDate.getDate() - 1, 2))),
                to: (myDate.getFullYear() + "-" + (zeroFill(myDate.getMonth() + 1, 2)) + "-" + (zeroFill(myDate.getDate() - 1, 2)))
            })
        }
        // @ts-ignore
        if (values.period === 'This week') {
            props.setDate({
                ...props.date,
                from: (getFirstWeekDay().getFullYear() + "-" + (zeroFill(getFirstWeekDay().getMonth() + 1, 2)) + "-" + zeroFill(getFirstWeekDay().getDate(), 2)),
                to: (getLastWeekDay().getFullYear() + "-" + (zeroFill(getLastWeekDay().getMonth() + 1, 2)) + "-" + zeroFill(getLastWeekDay().getDate(), 2))
            })
        }
        // @ts-ignore
        if (values.period === 'This month') {
            props.setDate({
                ...props.date,
                from: (getFirstDayOfCurrentMonth().getFullYear() + "-" + (zeroFill(getFirstDayOfCurrentMonth().getMonth() + 1, 2)) + "-" + zeroFill(getFirstDayOfCurrentMonth().getDate(), 2)),
                to: (getLastDayOfCurrentMonth().getFullYear() + "-" + (zeroFill(getLastDayOfCurrentMonth().getMonth() + 1, 2)) + "-" + zeroFill(getLastDayOfCurrentMonth().getDate(), 2))
            })
        }
        // @ts-ignore
        if (values.period === 'Last week') {
            props.setDate({
                ...props.date,
                from: (getFirstDayOfLastWeek().getFullYear() + "-" + (zeroFill(getFirstDayOfLastWeek().getMonth() + 1, 2)) + "-" + zeroFill(getFirstDayOfLastWeek().getDate(), 2)),
                to: (getLastDayOfLastWeek().getFullYear() + "-" + (zeroFill(getLastDayOfLastWeek().getMonth() + 1, 2)) + "-" + zeroFill(getLastDayOfLastWeek().getDate(), 2))
            })
        }
        // @ts-ignore
        if (values.period === 'Last 30 days') {

            let myTempDate = new Date()
            const timestamp = myTempDate.setDate(myTempDate.getDate() -30)
            const thirtyDaysAgo = new Date(timestamp)

            props.setDate({
                ...props.date,
                from: (thirtyDaysAgo.getFullYear() + "-" + (zeroFill(thirtyDaysAgo.getMonth() + 1, 2)) + "-" + zeroFill(thirtyDaysAgo.getDate(), 2)),
                to: (myDate.getFullYear() + "-" + (zeroFill(myDate.getMonth() + 1, 2)) + "-" + zeroFill(myDate.getDate(), 2))
            })
        }
        // @ts-ignore
        if (values.period === 'Last month') {

            props.setDate({
                ...props.date,
                from: (getFirstDayOfPreviousMonth().getFullYear() + "-" + (zeroFill(getFirstDayOfPreviousMonth().getMonth() + 1, 2)) + "-" + zeroFill(getFirstDayOfPreviousMonth().getDate(), 2)),
                to: (getLastDayOfPreviousMonth().getFullYear() + "-" + (zeroFill(getLastDayOfPreviousMonth().getMonth() + 1, 2)) + "-" + zeroFill(getLastDayOfPreviousMonth().getDate(), 2))
            })
        }
        // @ts-ignore
        if (values.period === 'Last and current month') {

            props.setDate({
                ...props.date,
                from: (getFirstDayOfPreviousMonth().getFullYear() + "-" + (zeroFill(getFirstDayOfPreviousMonth().getMonth() + 1, 2)) + "-" + zeroFill(getFirstDayOfPreviousMonth().getDate(), 2)),
                to: (getLastDayOfCurrentMonth().getFullYear() + "-" + (zeroFill(getLastDayOfCurrentMonth().getMonth() + 1, 2)) + "-" + zeroFill(getLastDayOfCurrentMonth().getDate(), 2))
            })
        }

        // @ts-ignore
    },[props.period, values.period])

    return null
}

const validationSchema = yup.object({
    recipient: yup.string().max(20, 'max 20 symbols'),
    amount: yup.string()
        .matches(/^[-+]?[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$/, "Must be only digits").max(20, 'max 20 digits'),
})

export const StatementForm = (props: propsType) => {

    const dispatch = useAppDispatch()
    const accountsList = useAccountsForSelect()
    const [isIncoming, setIsIncoming] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const [isOutgoing, setIsOutgoing] = useState(false)
    const [isOther, setIsOther] = useState(false)
    const [currency, setCurrency] = useState('')
    const [period, setPeriod] = useState('')
    const [isFee, setIsFee] = useState(false)
    
    const [hiddenAccountNumber, setHiddenAccountNumber] = useState('')
    const [date, setDate] = useState({
        from: (myDate.getFullYear() + "-" + (myDate.getMonth() + 1 < 10 ?  ("0" + (myDate.getMonth() + 1)).slice(-2) : myDate.getMonth() + 1) + "-" + myDate.getDate()),
        to: (myDate.getFullYear() + "-" + (myDate.getMonth() + 1 < 10 ?  ("0" + (myDate.getMonth() + 1)).slice(-2) : myDate.getMonth() + 1) + "-" + myDate.getDate())
    })

    useEffect(() => {
        
        if (isOther) {
            setIsIncoming(false)
            setIsOutgoing(false)
            setIsFee(false)
        }
    }, [isOther])

    useEffect(() => {
        if (isOutgoing && isIncoming) {
            setIsOther(false);
            setIsFee(false)
        }
    }, [isOutgoing, isIncoming]);

    useEffect(() => {
        if (isFee) {
            
            setIsOther(false)
        }
    }, [isFee])

    useEffect(() => {
        if (isFee && isIncoming) {
            setIsOther(false)
            setIsOutgoing(false)
        }
    }, [isFee, isIncoming])

    useEffect(() => {
        if (isFee && isOutgoing) {
            setIsOther(false)
            setIsIncoming(false)
        }
    }, [isFee, isIncoming])
    

    useEffect(() => {
        return () => {
            dispatch(setFilters({
                accountNumber: '',
                period: null,
                from: '',
                to: '',
                purpose: '',
                recipient: '',
                amount: '',
                incoming: false,
                outgoing: false,
                currency: '',
                selectedType: '',
                templateName: ''
            }))
        }
    }, [])

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    },[])

    const dateStyle = {
        fontFamily: 'Manrope',
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '18px',
        color: '#2A2E37',
        padding: '17px 16px',
        height: 52,
        width: isMobile ? "100%" : 146,
        border: '1.5px solid #E7E7E7',
        borderRadius: 4
    }

    const handleScrollBtn = () => {
       
        props.scrollPage.current?.scrollIntoView({
            block: "start", behavior: "smooth"
        })
    }

    // switch (tempData) {
    //     case isOther == true: console.log(tempData)
    //     default: 
    // }
    

    return (
        <ConfigProvider
            theme={{
                components: {
                    DatePicker: {
                        colorPrimary: '#4DB887',
                        fontWeightStrong: 600,
                        colorLink: 'red'
                    }
                },
                token: {
                    colorPrimary: '#4DB887',
                    colorBorder: '#EEEFEF',
                    fontFamily: 'Manrope',
                },
            }}
        >
            <Formik
                initialValues={{
                    accountNumber: '',
                    period: period ? period : '',
                    from: '',
                    to: '',
                    recipient: '',
                    amount: '',
                    incoming: false,
                    outgoing: false,
                    currency: '',
                    
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    const tempData = {
                        ...values,
                        from: date.from,
                        to: date.to,
                        incoming: isIncoming,
                        outgoing: isOutgoing,
                        other: isOther,
                        currency: currency,
                        fees: isFee,
                        
                        hiddenAccountNumber: hiddenAccountNumber
                    }
                    props.setDataForStatement(tempData)
                    dispatch(setFilters(tempData))
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit
                  }) => (
                    <form className={s.form} onSubmit={handleSubmit}>
                        <div className={s.dropdown_wrapper}>
                            <p className={s.labelText}>Account Number</p>
                            <CustomDropdownForAccounts
                                placeholder='More details'
                                setCurrency={setCurrency}
                                items={accountsList}
                                id={'accountNumber'}
                                name={'accountNumber'}
                                setAccountNumber={setHiddenAccountNumber}
                            />
                        </div>

                        <div className={s.periodRow}>
                            <div className={s.dropdown_wrapper}>
                                <p className={s.labelText}>Period</p>
                                <CustomDropdown
                                    placeholder='More details'
                                    items={optionsTwo}
                                    id={'period'}
                                    name={'period'}
                                />
                            </div>
                            <div className={s.datapicker_wrapper}>
                            <p className={s.labelText}>From</p>
                            <DatePicker suffixIcon={null} style={dateStyle}
                                        className={s.datepicker}
                                        defaultValue={dayjs(new Date(), dateFormat)}
                                        value={dayjs(date.from)}
                                        format={dateFormat}
                                        onChange={(_, dateString: any) => {
                                            if (!dateString) {
                                                setDate({...date, from: new Date().toDateString()})
                                            } else {
                                                setDate({...date, from: dateString})
                                            }
                                            handleChange(values.period = 'Indicated by user')

                                        }}
                            />
                            </div>
                             <div className={s.datapicker_wrapper}>
                            <p className={s.labelText}>To</p>
                            <DatePicker suffixIcon={null} style={dateStyle}
                                        className={s.datepicker}
                                        defaultValue={dayjs(new Date(), dateFormat)}
                                        value={dayjs(date.to)}
                                        format={dateFormat}
                                        onChange={(_, dateString: any) => {
                                            if (!dateString) {
                                                setDate({...date, to: new Date().toDateString()})
                                            } else {
                                                setDate({...date, to: dateString})
                                            }
                                            handleChange(values.period = 'Indicated by user')
                                        }}
                            />
                            </div>
                        </div>
                        <div className={s.recipientAmount}>
                            <div className={s.inputWrapper}>
                                <p className={s.labelText}>Recipient</p>
                                <MyInput
                                    id={'recipient'}
                                    name={'recipient'}
                                    onChange={handleChange}
                                    value={values.recipient}
                                    isError={errors.recipient}
                                />
                                {errors.recipient &&
                                    <div className={s.error_message}>{errors.recipient}</div>}
                            </div>
                            <div className={s.inputWrapper}>
                                <p className={s.labelText}>Amount</p>
                                <MyInput
                                    id={'amount'}
                                    name={'amount'}
                                    onChange={handleChange}
                                    value={values.amount}
                                    isError={errors.amount}
                                />
                                {errors.amount &&
                                    <div className={s.error_message}>{errors.amount}</div>}
                            </div>
                        </div>
                        <div className={s.transactionsBlock}>
                            <p className={s.labelText}>Transactions</p>
                            <div className={s.checkboxWrapper}>
                                <Checkbox
                                    checked={isIncoming}
                                    className={s.checkbox}
                                    value={isIncoming}
                                    onChange={() => setIsIncoming(prevState => !prevState)}
                                >
                                    Incoming
                                </Checkbox>
                                <Checkbox
                                    checked={isOutgoing}
                                    className={s.checkbox}
                                    value={isOutgoing}
                                    onChange={() => setIsOutgoing(prevState => !prevState)}
                                >
                                    Outgoing
                                </Checkbox>
                                <Checkbox
                                    checked={isOther}
                                    className={s.checkbox}
                                    value={isOther}
                                    onChange={() => setIsOther(prevState => !prevState)}
                                >
                                    Other
                                </Checkbox>
                                <Checkbox
                                    checked={isFee}
                                    className={s.checkbox}
                                    value={isFee}
                                    onChange={() => setIsFee(prevState => !prevState)}
                                >
                                    Fees
                                </Checkbox>
                            </div>
                        </div>
                        <div className={s.buttonWrapper}>
                            <MyBtn onClick={handleScrollBtn} isPersonalAccountBtn large type={'submit'} style={{ maxWidth: isMobile ? '100%' : 180}} title={'Show'}/>
                        </div>
                        <SetDateByPeriod
                            values={values}
                            period={period}
                            date={date}
                            setDate={setDate}
                        />
                    </form>)}
            </Formik>
        </ConfigProvider>
    )
}
