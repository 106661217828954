import {ITransacton} from "../types/interfaces";

export const PrepareDataForPaymentTemplate = (values: ITransacton) => {

    const getPaymentType = () => {
        if (values.paymentRegions === "international") {
            return 'International (SWIFT)'
        } else if (values.paymentRegions === "domestic" && values.transferCurrency === "EUR") {
            return 'Europe (SEPA)'
        } else if (values.paymentRegions === "domestic" && values.transferCurrency === "GBP") {
            return 'FPS'
        }
    }


    return {
        accountId: values.accountId,
        templateName: values.templateName,
        type: getPaymentType(),
        paymentType: values.paymentRegions?.toLowerCase(),
        transactionType: values.transferCurrency,
        fromAccount: values.fromAccount || values.hiddenAccountNumberFrom,
        toAccount: values.toAccount,
        recipient: values.recipientsSwiftBic || values.toAccount || values.recipientsAccountNumber,
        recipientDetails: {
            recipientType: values.recipientType ? values.recipientType : null,
            firstName: values.name ? values.name : null,
            lastName: values.surname ? values.surname : null,
            companyName: values.companyName ? values.companyName : null,
            swiftCode: values.recipientsSwiftBic ? values.recipientsSwiftBic : null,
            ibanCode: values.recipientsIban ? values.recipientsIban : null,
            beneficiaryAccountNumber: values.recipientsAccountNumber ? values.recipientsAccountNumber : null,
            beneficiarySortCode: values.sortCode ? values.sortCode : null ,
            beneficiaryAddress: values.recipientsAddress ? values.recipientsAddress : null,
            beneficiaryCountry: values.recipientCountry ? values.recipientCountry : null,
            beneficiaryCity: values.recipientCity ? values.recipientCity : null,
            bankName: values.bankName ? values.bankName : null,
            recipientPostCode: values.recipientPostCode ? values.recipientPostCode : null,
            bankCountry: values.bankCountry ? values.bankCountry : null,
            bankCity: values.bankCity ? values.bankCity : null,
            bankPostCode: values.bankPostCode ? values.bankPostCode : null,
            bankAddress: values.bankAddress ? values.bankAddress : null,
            achRoutingNumber: values.achRoutingNumber ? values.achRoutingNumber : null,
            reference: values.reference ? values.reference : null,
        }
    }
}

export const PrepareDataForPaymentIntraTemplate = (values: ITransacton, accountId: number | null) => {

    return {
        accountId: accountId,
        templateName: values.templateName,
        type: "Internal",
        fromAccount: values.accountNumber,
        toAccount: values.accNumberTo,
        hiddenAccountNumberTo: values.hiddenAccountNumberTo,
        transferType: values.transferMethod,
        transactionType: values.transferCurrency,
        recipientDetails: {
            purpose: values.purpose,
            email: values.email,
            phone: values.phoneNumber
        }
    }
}
