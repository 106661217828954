import React from 'react';
import s from './KycSuccess.module.css'
import ok from '../../../assets/img/personalAccount/tick-circle.svg'

const KycSuccess = () => {

    return (
        <div className={s.wrapper}>
            <div className={s.content}>
                <div className={s.flex}>
                    <img src={ok} alt="" className={s.img}/>
                    <p className={s.title}>Success!</p>
                    <p className={s.subtitle}>Your KYC was sent for review. Please leave this page.</p>

                    <div className={s.description_block}>
                        <span className={s.description}>
                            Feel free to contact <span
                            className={s.colorfull}>support@luxpay.lt</span> if you have any questions.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KycSuccess;
