import * as React from "react";

export const clearFormByChangeType = (values: Record<string, unknown>, setValues: (values: React.SetStateAction<any>) => void) => {
    const fieldsToKeep = ["payerAccount", "paymentRegions", "recipientType", "type"]; // unresetebleInputs

    const clearedValues = Object.keys(values).reduce((acc, key) => {
        // unresetableInputs
        if (fieldsToKeep.includes(key)) {
            acc[key] = values[key] || ''
        }

        else {
            acc[key] = "";
        }
        return acc;
    }, {} as Record<string, unknown>);


    setValues(clearedValues);
};
