import './mySwitch.css'
import s from "../../../pages/PersonalAccount/Security/Security.module.css";
import {Switch} from "antd";
import React from "react";
import {ReactComponent as Check} from "../../../assets/img/personalAccount/checked.svg";
import {ReactComponent as Cross} from "../../../assets/img/personalAccount/cross.svg";

type customizedSwitchPropsType = {
    checked: boolean | undefined
    onHandleChange: (checked: boolean) => void
    isMobile: boolean
}

export default function CustomizedSwitch(props: customizedSwitchPropsType) {

    return (
        <Switch
            rootClassName={s.my_root_switch}
            className={s.my_switch}
            checked={props.checked}
            onChange={(checked: boolean) => {
                props.onHandleChange(checked)
            }}
            checkedChildren={<Check style={props.checked ? {
                position: 'relative',
                left: props.isMobile ? 16 : 20,
                top: props.isMobile ? 4 : 2
            } : {position: 'relative', left: 19, top: 100}}/>}
            unCheckedChildren={<Cross style={props.checked ? {
                position: 'relative',
                left: 19.5,
                top: 100
            } : {
                position: 'relative',
                right: props.isMobile ? 18 : 18,
                top: props.isMobile ? 3 : 1
            }}/>}
        />
    );
}
