import React from "react";
import cl from './PopUpEmailCodConfirm.module.css'
import { useAppSelector } from "../../../hooks/redux";
import MyInput from "../MyInput/MyInput";
import TransparentButton from "../TransparentButton/TransparentButton";
import MyBtn from "../MyBtn/MyBtn";
import Icon from "@ant-design/icons";

import IconCircle from '../../../assets/img/personalAccount/info-circle.svg'


import {ReactComponent as Back} from '../../../assets/img/personalAccount/back.svg'
import { useFormikContext } from 'formik';

type PopUpVerificationCodeProps = {
    children?: React.ReactNode,
    onClick?: (e?: any) => void;
    onClose?: () => void;
    getPinEmail: (pin: string) => void;
    submitForm?: (e: React.FormEvent<HTMLFormElement>) => void;
};

const PopUpEmailCodConfirm = ({getPinEmail, onClose, submitForm, children }: PopUpVerificationCodeProps) => {
    const [inputValue, setInputValue] = React.useState('')

    const handlerChange = (e: any) => {
        setInputValue(e.target.value)
    }

    const maskEmail = (email: string | null ) => {
        if (!email) return '';

        const [localPart, domainPart] = email.split('@');

        // Маскируем локальную часть (до @)
        const maskedLocal = localPart.length > 2
            ? `${localPart.slice(0, 2)}${'*'.repeat(localPart.length - 2)}`
            : localPart;

        // Маскируем доменную часть (после @)
        const domainParts = domainPart.split('.');
        const maskedDomain = domainParts.map((part, index) => {
            if (index === 0 && part.length > 1) {
                return `${part[0]}${'*'.repeat(part.length - 1)}`;
            }
            return part;
        }).join('.');

        return `${maskedLocal}@${maskedDomain}`;
    };

    const oldEmail = useAppSelector(state => state.userReducer.user.userData.email)

    const saveValue = (e: any) => {
        e.preventDefault()
        getPinEmail(inputValue)
        submitForm && submitForm(e)
    }



    return (
        <div className={[cl.myModal, cl.active].join(' ')}>
            <div className={cl.myModalWrapper}>
            <div className={cl.myModalContent}>
                <div className="img-wrapper">
                    <img src={IconCircle} alt="#circle_info" />
                </div>
                <div className={cl.modal_title}>
                        Email authentication
                    </div>
                    <div className={cl.modal_subtitle}>
                        Email with confirmation code was sent to your email address <b>{maskEmail(oldEmail)}</b>
                    </div>
                    <div className={cl.input_block}>
                        <div className={cl.input_label}>
                            confirmation Code
                        </div>
                        <MyInput
                            value={inputValue}
                            onChange={handlerChange}
                            id={"codeFromCurrentEmail"}
                            name={"codeFromCurrentEmail"}
                            placeholder="6 digits"
                        />
                    </div>
                    <div className={cl.buttonsWrapper}>
                        <TransparentButton
                            buttonType={'confirm'}
                            isPersonalAccountBtn
                            medium
                            style={{width: '100%'}}
                            title={'Cancel'}
                            onClick={onClose}
                            icon={<Icon component={() => <Back/>} rev={undefined}/>}
                        />


                        <MyBtn
                            buttonType={'confirm'}
                            isPersonalAccountBtn
                            medium
                            style={{width: '100%'}}
                            title={'Confirm'}
                            type={"submit"}
                            onClick={saveValue}
                        />
                    </div>
                {/* <input type="text" placeholder="" onChange={handlerChange} value={inputValue} />
                <button onClick={saveValue}>SAVE</button> */}
                {children}
            </div>
            </div>
        </div>
     );
}

export default PopUpEmailCodConfirm;
