import React from 'react';
import s from "./KybFailure.module.css";
import error from "../../../assets/img/personalAccount/close-circle.svg";

const KybFailure = () => {

    return (
        <div className={s.wrapper}>
            <div className={s.content}>
                <div className={s.flex}>
                    <img src={error} alt="" className={s.img}/>
                    <p className={s.title}>Failed!</p>
                    <p className={s.subtitle}>Failure appeared while sending your KYB. Please leave this page and try
                        again.</p>

                    <div className={s.description_block}>
                        <span className={s.description}>
                            Feel free to contact <span
                            className={s.colorfull}>support@luxpay.lt</span> if you have any questions.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KybFailure;
