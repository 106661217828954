import {ITransacton} from "../types/interfaces";
import {toBase64} from "./toBase64";

export const PrepareDataForBankTransaction = async (values: ITransacton, code?: string, withdrawalStatus?: string) => {

    const invoiceAsString = await toBase64(values.invoice)

    // const cleanObject = (function () {
    //     function isEmpty(value: any) {
    //         return value === '';
    //     }
    //
    //     return function (obj: any) {
    //
    //         const tempObj = {...obj}
    //
    //         for (let key in obj) {
    //             if (obj.hasOwnProperty(key)) {
    //
    //                 let value = tempObj[key];
    //
    //                 if (
    //                     isEmpty(value)
    //                     || (value instanceof Object && cleanObject(value))
    //                 ) {
    //                     delete tempObj[key];
    //                 }
    //             }
    //         }
    //
    //         return tempObj
    //     };
    // })();

    // recipientDetails
    const recipientTempObject = {
        recipientType: values.recipientType ? values.recipientType : null,
        firstName: values.name ? values.name : null,
        lastName: values.surname ? values.surname : null,
        companyName: values.companyName ? values.companyName : null,
        swiftCode: values.recipientsSwiftBic ? values.recipientsSwiftBic : null,
        ibanCode: values.recipientsIban ? values.recipientsIban : null,
        beneficiaryAccountNumber: values.recipientsAccountNumber ? values.recipientsAccountNumber : null,
        beneficiarySortCode: values.sortCode ? values.sortCode : null ,
        beneficiaryAddress: values.recipientsAddress ? values.recipientsAddress : null,
        beneficiaryCountry: values.recipientCountry ? values.recipientCountry : null,
        bankName: values.bankName ? values.bankName : null,
        bankCountry: values.bankCountry ? values.bankCountry : null,
        bankCity: values.bankCity ? values.bankCity : null,
        bankPostCode: values.bankPostCode ? values.bankPostCode : null,
        bankAddress: values.bankAddress ? values.bankAddress : null,
        achRoutingNumber: values.achRoutingNumber ? values.achRoutingNumber : null,
    }

    const testObj = {
        type: values.type,
        accountId: values.accountId,
        fromAccount: values.hiddenAccountNumberFrom,
        reference: values.reference,
        amount: Number(values.amount),
        currency: values.transferCurrency,
        paymentType: values.paymentRegions?.toLowerCase(),
        invoice: invoiceAsString,
        invoiceFilename: values.invoice.name,
        templateName: values.templateName,
    }

    const preparedForSendObject = {
        ...testObj,
        recipientDetails: recipientTempObject,
        withdrawalCode: code,
        emailCode: values.emailCode,
        userDatetime: values.userDatetime,
    }

    return preparedForSendObject
}

