import React, {useState, useEffect} from 'react';
import {Formik} from "formik";
import {motion} from 'framer-motion';
import * as Yup from 'yup';
import s from './main-info.module.css';
import MyBtn from '../../../components/ui/MyBtn/MyBtn';
import MyInput from '../../../components/ui/MyInput/MyInput';
import PhoneCodesInput from '../../../components/ui/PhoneCodesInput/PhoneCodesInput';
import {useAppDispatch} from "../../../hooks/redux";
import {checkAvailableEmailThunk, checkAvailablePhoneThunk} from "../../../store/reducers/ActionCreators";
import attention from '../../../assets/img/attention.svg'

interface RegistrationData {
    firstName: string;
    lastName: string;
    companyName: string;
    email: string;
    phoneNumber: string;
}

interface commonRegistrationInterface {
    setIsEmailInUse: React.Dispatch<React.SetStateAction<boolean | null>>;
    setIsEmailLuxPay: React.Dispatch<React.SetStateAction<boolean | null>>;
    setIserrorEmailText: React.Dispatch<React.SetStateAction<string>>;
    setIsPhoneInvalid: React.Dispatch<React.SetStateAction<boolean | null>>;
    setPhoneErrorText: React.Dispatch<React.SetStateAction<string>>;
    values: RegistrationData;
}

type PropsType = {
    onSubmit: () => void
}


const validationPersonalSchema = Yup.object({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string()
      .required('Email is required')
      .email('Invalid email format'), // Базовая валидация формата email
    phoneNumber: Yup.string()
        .matches(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){0,20}(\s*)?$/, "Must be only digits")
        .min(6, "min 6")
        .max(20, "max 20")
        .required('Phone number is required'),
});

const validationBusinessSchema = Yup.object({
    companyName: Yup.string(),
    email: Yup.string()
      .required('Email is required')
      .email('Invalid email format'), // Базовая валидация формата email
    phoneNumber: Yup.string()
        .matches(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){0,20}(\s*)?$/, "Must be only digits")
        .min(6, "min 6")
        .max(20, "max 20")
        .required('Phone number is required'),
});

const RegistrationFormikHelper = (props: Omit<commonRegistrationInterface, 'setIsEmailLuxPay' | 'setIsPhoneInvalid' | 'setPhoneErrorText'>) => {

    useEffect(() => {
        props.setIsEmailInUse(false)
        props.setIserrorEmailText('Email already exists')
    }, [props.values]);

    return null
}

const EmailHandlerLuxPayHelper = (props: Omit<commonRegistrationInterface, 'setIsEmailInUse' | 'setIsPhoneInvalid' | 'setPhoneErrorText'>) => {
    useEffect(() => {

        props.setIsEmailLuxPay(false)
        props.setIserrorEmailText('Invaid email')
    }, [props.values])
    return null
}

const RegistrationPhoneHelper = (props: Pick<commonRegistrationInterface, 'setIsPhoneInvalid' | 'setPhoneErrorText' | 'values'>) => {
    useEffect(() => {
        props.setIsPhoneInvalid(false);
        props.setPhoneErrorText('Phone already exists');
    }, [props.values.phoneNumber]); // Следим за changes phoneNumber

    return null;
};




const MainInfoStep: React.FC<PropsType> = ({...props}) => {

    const dispatch = useAppDispatch()

    function getDefaultInitialValues(): RegistrationData {
        return {
            companyName: '',
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
        };
    }

    const localData = localStorage.getItem('registrationData');

    const initialValues: RegistrationData = localData ? JSON.parse(localData) as RegistrationData : getDefaultInitialValues();

    const [accountType, setAccountType] = useState<string | null>(null)
    const [isEmailInUse, setIsEmailInUse] = useState<boolean | null>(false)

    const [isEmailLuxpay, setIsEmailLuxPay] = useState <boolean | null> (false)
    
    const [isErrorEmailText, setIserrorEmailText] = useState <string>('')

    const [isPhoneInvalid, setIsPhoneInvalid] = useState<boolean | null>(null);
    const [phoneErrorText, setPhoneErrorText] = useState('');

    useEffect(() => {
        if (localData) {
            const parsedData = JSON.parse(localData);
            setAccountType(parsedData.type);
        }
    }, [localData]);

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >

            <div className={s.info}>
                <div className={s.title}>
                    Registration Form

                    <div className={s.error_wrapper}>
                    {(isEmailLuxpay || isEmailInUse || isPhoneInvalid) && (
                        <div className={s.error_block}>
                            <img src={attention} alt="Warning" />
                            <div>
                            {isEmailLuxpay || isEmailInUse ? isErrorEmailText : phoneErrorText}
                            </div>
                        </div>
                    )}

                    </div>

                </div>

                <Formik
                    initialValues={initialValues}
                    validationSchema={accountType === 'business' ? validationBusinessSchema : validationPersonalSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                          // Проверка на luxpay в email
                          if (values.email.includes('luxpay')) {
                            setIsEmailLuxPay(true);
                            setIserrorEmailText("You can't use luxpay in your email");
                            return;
                          }
                    
                          // Сбрасываем состояния ошибок перед проверкой
                          setIsEmailInUse(false);
                          setIsEmailLuxPay(false);
                          setIsPhoneInvalid(false);
                          setIserrorEmailText('');
                          setPhoneErrorText('');
                    
                          // Параллельная проверка email и телефона
                          const [emailResponse, phoneResponse] = await Promise.all([
                            dispatch(checkAvailableEmailThunk(values.email)),
                            dispatch(checkAvailablePhoneThunk(values.phoneNumber))
                          ]);
                    
                        //   Обработка email
                          if (!emailResponse.data || emailResponse.data !== 'Email is valid') {
                            setIsEmailInUse(true);
                            setIserrorEmailText(
                              emailResponse.response?.data.includes('is already in use')
                                ? "Email already exists"
                                : "Invalid email"
                            );
                            return;
                          }
                    
                          // Обработка телефона
                          if (phoneResponse.response?.data.includes('already in use')) {
                            setIsPhoneInvalid(true);
                            setPhoneErrorText('Phone is already exist');
                            return;
                          } 
                    
                          // Если все проверки пройдены
                          const filledData = {
                            ...JSON.parse(localStorage.getItem('registrationData') || '{}'),
                            ...values,
                            firstName: values.firstName.trim(),
                            lastName: values.lastName.trim(),
                            companyName: values.companyName.trim()
                          };
                    
                          localStorage.setItem('registrationData', JSON.stringify(filledData));
                          props.onSubmit();
                    
                        } catch (error) {
                          console.error('Validation error:', error);
                        } finally {
                          setSubmitting(false);
                        }
                      }}
                    >

                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit
                      }) => (

                        <form className={s.form} onSubmit={handleSubmit}>
                            {accountType === 'business' ? (
                                <>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            Company Name
                                        </div>
                                        <MyInput
                                            name="companyName"
                                            value={values.companyName}
                                            onChange={handleChange}
                                            isError={errors.companyName}
                                            touched={touched.companyName}
                                            width={'100%'}
                                        />
                                        {touched.companyName && errors.companyName ? (
                                            <div className={s.error}>{errors.companyName}</div>
                                        ) : null}
                                    </div>

                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            email
                                        </div>
                                        <MyInput
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            isError={errors.email}
                                            touched={touched.email}
                                            width={'100%'}
                                        />
                                        {touched.email && errors.email ? (
                                            <div className={s.error}>{errors.email}</div>
                                        ) : null}
                                    </div>

                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            Phone number
                                        </div>
                                        <PhoneCodesInput
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            error={errors.phoneNumber}
                                            touched={touched.phoneNumber}
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            first name
                                        </div>
                                        <MyInput
                                            name="firstName"
                                            value={values.firstName}
                                            onChange={handleChange}
                                            isError={errors.firstName}
                                            touched={touched.firstName}
                                            width={'100%'}
                                        />
                                        {touched.firstName && errors.firstName ? (
                                            <div className={s.error}>{errors.firstName}</div>
                                        ) : null}
                                    </div>

                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            last name
                                        </div>
                                        <MyInput
                                            name="lastName"
                                            value={values.lastName}
                                            onChange={handleChange}
                                            isError={errors.lastName}
                                            touched={touched.lastName}
                                            width={'100%'}
                                        />
                                        {touched.lastName && errors.lastName ? (
                                            <div className={s.error}>{errors.lastName}</div>
                                        ) : null}
                                    </div>

                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            email
                                        </div>
                                        <MyInput
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            isError={errors.email}
                                            touched={touched.email}
                                            width={'100%'}
                                        />
                                        {touched.email && errors.email ? (
                                            <div className={s.error}>{errors.email}</div>
                                        ) : null}
                                    </div>

                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            Phone number
                                        </div>
                                        <PhoneCodesInput
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            error={errors.phoneNumber}
                                            touched={touched.phoneNumber}
                                        />
                                    </div>
                                </>
                            )}
                            <MyBtn title={'Next'}
                                   type="submit"
                                   large
                                   isPersonalAccountBtn
                            />
                                <RegistrationPhoneHelper 
                                    setIsPhoneInvalid={setIsPhoneInvalid}
                                    setPhoneErrorText={setPhoneErrorText}
                                    values={values}
                                />
                            <RegistrationFormikHelper setIserrorEmailText={setIserrorEmailText} values={values} setIsEmailInUse={setIsEmailInUse}/>
                            <EmailHandlerLuxPayHelper setIserrorEmailText={setIserrorEmailText} values ={values} setIsEmailLuxPay={setIsEmailLuxPay}/>
                        </form>
                    )}
                </Formik>
            </div>
        </motion.div>
    );
};

export default MainInfoStep;
