import React from 'react';
import s from "./KycDeclined.module.css";
import notify from "../../../assets/img/personalAccount/info-circle.svg";

const KycDeclined = () => {

    return (
        <div className={s.wrapper}>
            <div className={s.content}>
                <div className={s.flex}>
                    <img src={notify} alt="" className={s.img}/>
                    <p className={s.title}>Declined!</p>
                    <p className={s.subtitle}>Your KYC was declined. Please leave this page.</p>
                    <div className={s.description_block}>
                        <span className={s.description}>
                            Feel free to contact <span
                            className={s.colorfull}>support@luxpay.lt</span> if you have any questions.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KycDeclined;
