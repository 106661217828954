import React, {useState} from 'react';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import s from "../../../styles/styles.module.css";
import {motion} from "framer-motion";
import BankTransfer from "./BankTransfer/BankTransfer";
import ToAnLuxPay from "./ToAnLuxPay/ToAnLuxPay";
import VerifyWithdrawalHelper from "../../../helpers/VerifyWithdrawalHelper";

const Transfers = () => {

    const [isButtonDisable, setIsButtonDisabled] = useState(true)
    const [code, setCode] = useState("")
    const [withdrawalStatus, setWithdrawalStatus] = useState("")

    if (withdrawalStatus === 'notVerified' || withdrawalStatus === 'blocked' || withdrawalStatus === '') {
        return (
            <VerifyWithdrawalHelper
                setWithdrawalStatus={setWithdrawalStatus}
                setIsButtonDisabled={setIsButtonDisabled}
                setCode={setCode}
            />
        )
    }

    if (withdrawalStatus === 'allowed') {
        return (
            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
            >
                <Tabs>
                    <TabList className={s.tabstitle_block}>
                        <Tab selectedClassName={s.active_tab} className={s.tabtitle}>Bank Transfer</Tab>
                        <Tab selectedClassName={s.active_tab} className={s.tabtitle}>To a LuxPay User</Tab>
                    </TabList>

                    <TabPanel>
                        <BankTransfer
                            code={code}
                            isButtonDisable={isButtonDisable}
                            withdrawalStatus={withdrawalStatus}
                        />
                    </TabPanel>
                    <TabPanel>
                        <ToAnLuxPay
                            isButtonDisable={isButtonDisable}
                            code={code}
                            withdrawalStatus={withdrawalStatus}
                            setIsButtonDisabled={setIsButtonDisabled}
                        />
                    </TabPanel>
                </Tabs>
            </motion.div>
        );
    }

    return null
};

export default Transfers;
