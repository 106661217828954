import React, {useEffect} from 'react'
import {Layout} from "antd";
import s from './Loader.module.css'




export const Loader = () => {

    return (
        <div style={{height: '162px',  display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 auto'}}>
            <Layout style={{width: 'calc(100% - 353px)', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 auto', backgroundColor: "transparent"}}>
                <div className={s.waveform}>
                    <div className={`${s.waveform__bar} ${s.one}`}></div>
                    <div className={`${s.waveform__bar} ${s.two}`}></div>
                    <div className={`${s.waveform__bar} ${s.three}`}></div>
                    <div className={`${s.waveform__bar} ${s.four}`}></div>
                    <div className={`${s.waveform__bar} ${s.five}`}></div>
                </div>
                <p className={s.text}>Load...</p>
            </Layout>
        </div>
    )
}