import {useEffect} from "react";
import {useField, useFormikContext} from "formik";
import {
    defaultValidation,
    validationSchemaDomesticEUR,
    validationSchemaDomesticGBP, validationSchemaDomesticUSD,
    validationSchemaInternationalEUR, validationSchemaInternationalGBP
} from "./ValidationSchemas";
import * as yup from "yup";
import useDebounce from "../../../hooks/useDebounce";
import {getBicCodeThunk} from "../../../store/reducers/ActionCreators";
import {useAppDispatch} from "../../../hooks/redux";

export const CurrencyHelper = (props: any) => {
    useEffect(() => {
        props.accountsList.map((account: any) => {
            if (account.number === props.payerAccount) {
                props.setCurrency(account.currency)
            }
        })
    }, [props.accountsList, props.payerAccount])

    return null
}

export const PaymentTypeHelper = (props: any) => {

    const { values } = useFormikContext();
    const [field, meta, helpers] = useField<string>(props.name);
    const { setValue } = helpers;

    useEffect(() => {
        setValue("")
        // @ts-ignore
    },[values.recipientsBankCountry])

    return null
}

export const amountValidation = yup.string()
    .matches(/^[-+]?[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$/, "Must be only digits")
    .required('Required field')
    .max(10, 'max 10 digits')
    .test(
        'no-leading-zero',
        'invalid format',
        (value, context) => {

            const tempArr = context.originalValue.split('')

            const onlyZero = tempArr.every((item: string) => {
                if (item === '0') {
                    return true
                }
            })

            if (context.originalValue && onlyZero) {
                return false
            } else if ((context.originalValue.match(/,/g) || []).length > 1) {
                return false
            }
            return true
        }
    )
    .test(
        'max-decimal-places',
        'max 2 symbols after .',
        (value) => {
            const parts = value.split('.')
            return parts.length === 1 || parts[1].length <= 2
        }
    )

// Function that returns the validation schema based on the payment region
export const getValidationSchema = (paymentRegion: string, currency? : string) => {



    const comparedValidationSchemaInternationalEUR = yup.object({
        name: validationSchemaInternationalEUR.name,
        surname: validationSchemaInternationalEUR.surname,
        companyName: validationSchemaInternationalEUR.companyName,
        recipientsIban: validationSchemaInternationalEUR.recipientsIban,
        recipientsSwiftBic: validationSchemaInternationalEUR.recipientsSwiftBic,
        recipientsAddress: validationSchemaInternationalEUR.recipientsAddress,
        recipientCity: validationSchemaInternationalEUR.recipientCity,
        recipientPostCode: validationSchemaInternationalEUR.recipientPostCode,
        recipientCountry: validationSchemaInternationalEUR.recipientCountry,
        bankName: validationSchemaInternationalEUR.bankName,
        bankAddress: validationSchemaInternationalEUR.bankAddress,
        bankCity: validationSchemaInternationalEUR.bankCity,
        bankPostCode: validationSchemaInternationalEUR.bankPostCode,
        bankCountry: validationSchemaInternationalEUR.bankCountry,
        reference: validationSchemaInternationalEUR.reference,
        payerAccount: validationSchemaInternationalEUR.payerAccount,
        paymentRegions: validationSchemaInternationalEUR.paymentRegions,
        templateName: validationSchemaInternationalEUR.templateName,
        amount: amountValidation
    });

    const comparedValidationSchemaDomesticEUR = yup.object({
        payerAccount: validationSchemaDomesticEUR.payerAccount,
        paymentRegions: validationSchemaDomesticEUR.paymentRegions,
        name: validationSchemaDomesticEUR.name,
        surname: validationSchemaDomesticEUR.surname,
        companyName: validationSchemaDomesticEUR.companyName,
        recipientsIban: validationSchemaDomesticEUR.recipientsIban,
        recipientsSwiftBic: validationSchemaDomesticEUR.recipientsSwiftBic,
        reference: validationSchemaDomesticEUR.reference,
        templateName: validationSchemaDomesticEUR.templateName,
        amount: amountValidation
    });

    const comparedValidationSchemaDomesticGBP = yup.object({
        payerAccount: validationSchemaDomesticGBP.payerAccount,
        name: validationSchemaDomesticEUR.name,
        surname: validationSchemaDomesticEUR.surname,
        companyName: validationSchemaDomesticEUR.companyName,
        reference: validationSchemaDomesticGBP.reference,
        recipientsAccountNumber: validationSchemaDomesticGBP.recipientsAccountNumber,
        sortCode: validationSchemaDomesticGBP.sortCode,
        paymentRegions: validationSchemaDomesticGBP.paymentRegions,
        templateName: validationSchemaDomesticGBP.templateName,
        amount: amountValidation
    });

    const comparedValidationSchemaDomesticUSD = yup.object({
        name: validationSchemaDomesticUSD.name,
        surname: validationSchemaDomesticUSD.surname,
        companyName: validationSchemaDomesticUSD.companyName,
        reference: validationSchemaDomesticUSD.reference,
        recipientsAccountNumber: validationSchemaDomesticUSD.recipientsAccountNumber,
        sortCode: validationSchemaDomesticUSD.sortCode,
        paymentRegions: validationSchemaDomesticUSD.paymentRegions,
        templateName: validationSchemaDomesticUSD.templateName,
        amount: amountValidation
    });

    const comparedValidationSchemaInternationalGBP = yup.object({
        sortCode: validationSchemaInternationalGBP.sortCode,
        recipientsAccountNumber: validationSchemaInternationalGBP.recipientsAccountNumber,
        recipientsIban: validationSchemaInternationalGBP.recipientsIban,
        recipientsSwiftBic: validationSchemaInternationalGBP.recipientsSwiftBic,
        name: validationSchemaDomesticEUR.name,
        surname: validationSchemaDomesticEUR.surname,
        companyName: validationSchemaDomesticEUR.companyName,
        reference: validationSchemaInternationalGBP.reference,
        paymentRegions: validationSchemaInternationalGBP.paymentRegions,
        templateName: validationSchemaInternationalGBP.templateName,
        amount: amountValidation
    });

    if (currency === "EUR") {
        switch (paymentRegion) {
            case "domestic":
                return comparedValidationSchemaDomesticEUR;
            case "international":
                return comparedValidationSchemaInternationalEUR;
        }
        return
    }

    if (currency === "GBP") {
        switch (paymentRegion) {
            case "domestic":
                return comparedValidationSchemaDomesticGBP;
            case "international":
                return comparedValidationSchemaInternationalGBP;
        }
        return
    }

    if (currency === "USD") {
        switch (paymentRegion) {
            case "domestic":
                return comparedValidationSchemaDomesticUSD;
        }
        return
    }

    return defaultValidation
}

export const SwiftCheckerHelper = (props: any) => {

    const dispatch = useAppDispatch()
    const { values }: any = useFormikContext();
    const debouncedValue = useDebounce(values.recipientsIban, 2000);

    useEffect(() => {
        if (debouncedValue) {

            dispatch(getBicCodeThunk(debouncedValue))
                .then((res) => {
                    if (values.paymentRegions === "international" && props.currency === "EUR" && props.isAdditionalFields) {
                        props.setFieldValue("bankName", res.data.data.swift.bank.name)
                        props.setFieldValue("recipientsSwiftBic", res.data.data.swift.id)
                        props.setFieldValue("bankCity", res.data.data.swift.city.name)
                        props.setFieldValue("bankAddress", res.data.data.swift.address)
                        props.setFieldValue("bankPostCode", res.data.data.swift.postcode)
                        props.setFieldValue("bankCountry", res.data.data.swift.country.name)
                    }

                    if (values.paymentRegions === "domestic" && props.currency === "EUR" && props.isAdditionalFields) {
                        props.setFieldValue("recipientsSwiftBic", res.data.data.swift.id)
                    }

                    if (values.paymentRegions === "international" && props.currency === "GBP" && props.isAdditionalFields) {
                        props.setFieldValue("recipientsSwiftBic", res.data.data.swift.id)
                    }

                    props.setIsIbanWrong(false)
                })
                .catch((e) => {
                    if (e.response.data.message === 'Invalid IBAN' || e.response.data.message === 'IBAN not found') {
                        props.setIsIbanWrong(true)
                    }
                })

        } else if (debouncedValue === '') {
            props.setIsIbanWrong(false)
        }
        // @ts-ignore
    },[debouncedValue])

    return null
}
