import React, {useEffect, useState} from 'react'
import s from './AccountOverview.module.css'
import {Dropdown, Layout, MenuProps, Popover, Space} from 'antd';
import arrow from '../../../../assets/img/personalAccount/arrow.svg'
import Table, {ColumnsType} from "antd/es/table";
import info from "../../../../assets/img/personalAccount/information.svg";

import mobStyle from '../../../../components/ui/AccountCardMobile/AccountCardMobile.module.css'

import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import AccountCardMobile from "../../../../components/ui/AccountCardMobile/AccountCardMobile";
import {ReactComponent as SortIconAsc} from "../../../../assets/img/personalAccount/sortArrow.svg";
import {ReactComponent as SortIconDesc} from "../../../../assets/img/personalAccount/sortArrowDown.svg";
import {ReactComponent as SortIcons} from "../../../../assets/img/personalAccount/sortIcons.svg";
import {GetAccountsListThunk} from "../../../../store/reducers/ActionCreators";

const menuStyle = {
    border: '1.5px solid #EEEFEF',
    borderRadius: 8,
    width: '145%',
    color: '#B5B5B5',
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '18px',
    letterSpacing: '-0.28px',
    cursor: 'pointer',
};

const contentStyle = {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px',
    color: '#979797'
};

const currencyStyle = {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px',
    color: '#979797',
    width: 'fit-content'
};

interface DataType {
    pendingOutgoingTransactionsSum: string;
    availableBalance: string;
    accountMainIban: string;
    balance: number,
    currency: string,
    number: string,
    status: string
}

const columns: ColumnsType<DataType> = [
    {
        title: 'Number',
        dataIndex: 'number',
        sorter: true,
        align: 'left',
        className: `${s.headerCellNumber}`,
        sortIcon: (props: { sortOrder: any }) => {
            if (props.sortOrder === 'ascend') {
                return <SortIconAsc/>
            }
            if (props.sortOrder === 'descend') {
                return <SortIconDesc/>
            }
            return <SortIcons/>
        }
    },
    Table.EXPAND_COLUMN,
    {
        title: 'Currency',
        dataIndex: 'currency',
        align: 'right',
        sorter: true,
        className: `${s.headerCell}`,
        sortIcon: (props: { sortOrder: any }) => {
            if (props.sortOrder === 'ascend') {
                return <SortIconAsc/>
            }
            if (props.sortOrder === 'descend') {
                return <SortIconDesc/>
            }
            return <SortIcons/>
        }
    },
    {
        title: 'Reserved',
        dataIndex: 'reserved',
        align: 'right',
        sorter: true,
        className: `${s.headerCell}`,
        sortIcon: (props: { sortOrder: any }) => {
            if (props.sortOrder === 'ascend') {
                return <SortIconAsc/>
            }
            if (props.sortOrder === 'descend') {
                return <SortIconDesc/>
            }
            return <SortIcons/>
        }
    },
    {
        title: 'Total balance',
        dataIndex: 'totalBalance',
        align: 'right',
        sorter: true,
        className: `${s.headerCell}`,
        sortIcon: (props: { sortOrder: any }) => {
            if (props.sortOrder === 'ascend') {
                return <SortIconAsc/>
            }
            if (props.sortOrder === 'descend') {
                return <SortIconDesc/>
            }
            return <SortIcons/>
        }
    },
    {
        title: 'Available balance',
        dataIndex: 'availableBalance',
        align: 'right',
        sorter: true,
        className: `${s.headerCell}`,
        sortIcon: (props: { sortOrder: any }) => {
            if (props.sortOrder === 'ascend') {
                return <SortIconAsc/>
            }
            if (props.sortOrder === 'descend') {
                return <SortIconDesc/>
            }
            return <SortIcons/>
        }
    },
];
export const AccountOverview = () => {
    const accounts = useAppSelector(store => store.userReducer.accountsList.accounts)
    const accountsStatistics = useAppSelector(store => store.userReducer.accountsList.statistics)
    const [currency, setCurrency] = useState('EUR')
    const dispatch = useAppDispatch()
    const chosenAccountTypeId = useAppSelector(state => state.userReducer.chosenAccountTypeId)
    const token = useAppSelector(state => state.userReducer.userToken)

    const clientId = useAppSelector(state => state.userReducer.user.userData.clientId)

    const summResevered = useAppSelector((state) => state.userReducer.accountsList.statistics.totalPendingOutgoingTransactionsSum)
    const totalReserved = useAppSelector((state) => state.userReducer.accountsList.statistics.totalPendingOutgoingTransactionsSum)

    const optionsTwo: MenuProps['items'] = [
        {
            label: 'Commission statement for the previous year',
            key: '0',
        },
        {
            label: 'Commission statement from the beginning of the current calendar year',
            key: '1',
        },
    ];

    useEffect(() => {
        dispatch(GetAccountsListThunk(token, chosenAccountTypeId, currency))
    }, [])

    const options: MenuProps['items'] = [
        {
            label: 'EUR',
            key: 'EUR',
        },
        {
            label: 'GBP',
            key: 'GBP',
        },
    ];

    const menuItems = options.map((option: any) => ({
        key: option.value,
        label: (
            <div onClick={() => currencyHandleChange(option.key)}>
                {option.label}
            </div>
        ),
    }));

    const getType = (type: string) => {
        if (type === 'Requested') {
            return 'pending approval'
        }
        if (type === 'Inactive') {
            return 'blocked'
        }
    }

    const tableData = accounts?.map((item: DataType) => {

        return {
            key: item.number,
            number: <p className={s.accountTitleWrapper}>
                        <p className={item.status === 'Active' ? s.numberWrapper : `${s.numberWrapper} ${s.disabled}`}>
                        {item.accountMainIban}

                        <Popover content={"Please note: Always use the Mandatory Reference when submitting the payment; otherwise, it will not be recived."}
                                color={'#EEEFEF'}
                                placement={"top"}

                                overlayInnerStyle={{
                                    fontFamily: 'Manrope',
                                    padding: '13px 16px',
                                    boxShadow: 'none',
                                    cursor: 'pointer',
                                    backgroundColor: '#E7F0EC',
                                    width: "280px"
                                }}
                        >
                            <img src={info} alt=""/>
                        </Popover>

                        </p>
                        <div className={s.counterName}>Mandatory Reference:<span className={s.counterName}>{clientId}</span></div>
                    </p>,
            currency: <p>
                        <p className={item.status !== 'Active' ? s.disabled : ''}>{item.currency}</p>
                      </p> ,
            reserved: item.status !== 'Requested' ?
                        <p>
                            <p className={item.status !== 'Active' ? s.disabled : ''}>{item.pendingOutgoingTransactionsSum + ' ' + item.currency}</p>
                        </p>  : '',
            totalBalance: item.status !== 'Requested' ?
                        <p>
                            <p className={item.status !== 'Active' ? s.disabled : ''}>{item.balance + ' ' + item.currency}</p>
                        </p>  : '',
            availableBalance: item.status === 'Active' ?
                        <p>
                            <p>{item.availableBalance + ' ' + item.currency}</p>
                        </p>  :
                        <p>
                            <p className={item.status === 'Requested' ? s.pending : s.blocked}>{getType(item.status)}</p>
                        </p> ,
        }
    })

    const isMobile = window.innerWidth <= 768

    const currencyHandleChange = (e: any) => {
        setCurrency(e)
        dispatch(GetAccountsListThunk(token, chosenAccountTypeId, e))
    }

    return (
            <div className={s.accountOverviewWrapper}>
                <div className={s.topMenu}>
                    <h2 className={s.topMenuTitle}>Accounts</h2>
                </div>
                {!isMobile
                    ? <Layout>
                        <Table
                            bordered={false}
                            className={s.accountTable}
                            columns={columns}
                            dataSource={tableData}
                            pagination={false}
                            summary={(pageData) => {
                                let total = 0;
                                return (
                                    <>

                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0} colSpan={3} className={s.footerTotalCell}>
                                                <p>Total</p>
                                                <Popover content={"“In total on personal account” is given in the selected currency after conversion at the accounting rate."}
                                                         color={'#EEEFEF'}
                                                         placement={"bottom"}

                                                         overlayInnerStyle={{
                                                             fontFamily: 'Manrope',
                                                             padding: '13px 16px',
                                                             boxShadow: 'none',
                                                             width: '280px',
                                                             cursor: 'pointer',
                                                             backgroundColor: '#E7F0EC'
                                                         }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                                <Dropdown menu={{items: menuItems}} placement={"bottomCenter"}
                                                          trigger={['click']}
                                                          dropdownRender={(menu: React.ReactNode) => (
                                                              <div style={currencyStyle}>
                                                                  {React.cloneElement(menu as React.ReactElement, {style: menuStyle})}
                                                              </div>
                                                          )}>
                                                    <Space
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            width: 102,
                                                            height: 44,
                                                            border: '1.5px solid #EEEFEF',
                                                            borderColor: '#E7E7E7',
                                                            borderRadius: 4,
                                                            fontFamily: 'Manrope',
                                                            fontWeight: 600,
                                                            fontSize: 14,
                                                            lineHeight: '18px',
                                                            color: '#282828',
                                                            cursor: 'pointer',
                                                        }}>
                                                        <p className={s.total_currency}>{currency}</p>
                                                        <img src={arrow} alt=""/>
                                                    </Space>
                                                </Dropdown>

                                            </Table.Summary.Cell>

                                            <Table.Summary.Cell index={2} align={'right'} colSpan={2}
                                                                className={s.footerCell}>
                                                {totalReserved} {currency}
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={2} align={'right'} className={s.footerCell}>
                                                {accountsStatistics?.totalBalance} {currency}
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={2} align={'right'} className={s.footerCell}>
                                                {accountsStatistics?.totalAvailableBalance} {currency}
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        />
                    </Layout>
                    : <>
                        {
                            accounts.map((item: any) =>
                                <AccountCardMobile
                                    pendingOutgoingTransactionsSum={item.pendingOutgoingTransactionsSum}
                                    iban={item.accountMainIban}
                                    status={item.status}
                                    number={item.number}
                                    currency={item.currency}
                                    balance={item.balance}
                                />
                            )
                        }
                        <div className={mobStyle.accountSummBillWrapper}>
                            <div className={mobStyle.headerSummWrapper}>
                                <div className={mobStyle.headerSummTitle}>Total</div>
                                <Popover content={"“In total on personal account” is given in the selected currency after conversion at the accounting rate."}
                                                         color={'#EEEFEF'}
                                                         placement={"bottom"}

                                                         overlayInnerStyle={{
                                                             fontFamily: 'Manrope',
                                                             padding: '13px 16px',
                                                             boxShadow: 'none',
                                                             width: '280px',
                                                             cursor: 'pointer',
                                                             backgroundColor: '#E7F0EC',

                                                         }}
                                                >
                                                    <img src={info} alt="" className={mobStyle.popoverImg}/>
                                </Popover>
                                <Dropdown menu={{items: menuItems}} placement={"bottomCenter"}
                                                          trigger={['click']}
                                                          dropdownRender={(menu: React.ReactNode) => (
                                                              <div style={currencyStyle}>
                                                                  {React.cloneElement(menu as React.ReactElement, {style: menuStyle})}
                                                              </div>
                                                          )}>
                                                    <Space
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            width: 102,
                                                            height: 44,
                                                            border: '1.5px solid #EEEFEF',
                                                            borderColor: '#E7E7E7',
                                                            borderRadius: 4,
                                                            fontFamily: 'Manrope',
                                                            fontWeight: 600,
                                                            fontSize: 14,
                                                            lineHeight: '18px',
                                                            color: '#282828',
                                                            cursor: 'pointer',
                                                        }}>
                                                        <p className={s.total_currency}>{currency}</p>
                                                        <img src={arrow} alt=""/>
                                        </Space>
                                    </Dropdown>
                            </div>
                            <div className={mobStyle.calcFunds}>
                                <div className={mobStyle.calcItem}>Reserved: <span className={mobStyle.summValues}>{summResevered} {currency}</span></div>
                                <div className={mobStyle.calcItem}>Total Balance: <span className={mobStyle.summValues}>{accountsStatistics?.totalBalance} {currency}</span></div>
                                <div className={mobStyle.calcItem}>Available Balance: <span className={mobStyle.summValues}>{accountsStatistics?.totalAvailableBalance} {currency}</span></div>
                            </div>
                        </div>
                    </>
                }
            </div>

    )
}
