import React, {ChangeEvent, useEffect, useState} from 'react';
import s from './MyInput.module.css'
import classnames from "classnames";

type PropsType = {
    password?: boolean
    isError?: any
    id?: string
    name?: string
    value?: any
    placeholder?: string
    onChange?: { (e: ChangeEvent<any>): void;}
    touched?:  any,
    width?: string,
    padding?: string
    ref?: any,
    isMobile?: boolean
    disabled?: boolean
}

const MyInput = (props: PropsType) => {

    const { 
        isError, 
        touched, 
        password, 
        isMobile, 
        width, 
        padding, 
        ...inputProps  // остальные пропы пойдут в <input>
    } = props

    const [inputType, setInputType] = useState("text")

    useEffect(() => {
        if (props.password) {
            setInputType("password")
        }
    }, [])

    const changeInputType = (e: any) => {

        e.preventDefault()

        if (inputType === "password") {
            setInputType("text")
        }

        if (inputType === "text") {
            setInputType("password")
        }

    }
    return (
        <div className={s.inputWrapper} style={{width: props.width, }}>

        {props.isMobile && window.innerWidth <= 501 ?
            <textarea
                {...inputProps}
                className={classnames(s.my_input, {[s.error_input]: !!props.isError}, s.textareaStyles)}
                id={props.id}
                name={props.name}

                placeholder={props.placeholder}>

            </textarea>
        :
            <input onChange={props.onChange} type={inputType}
                   autoComplete="off"
                   style={{padding: props.padding}}
                   className={classnames(s.my_input, {[s.error_input]: !!props.isError},)}
                   {...inputProps}
            />}
            <div className={props.password ? s.view : s.hidden}>
                <a href="#" className={inputType === "text" ? s.passwordView : s.passwordControl}
                   onClick={(e) => changeInputType(e)}>
                </a>
            </div>
        </div>
    );
}

export default MyInput;
