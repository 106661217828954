import {createSelector} from "@reduxjs/toolkit";
import {ITemplate, ITransacton} from "../../../types/interfaces";
import {IFilters} from "./TransactionsSlice";
import {RootState} from "../../store";

export const selectAllTransactions = (state: RootState) => state.transactionsReducer.transactionsData
export const allTemplates = (state: RootState) => state.transactionsReducer.templates
export const selectFilters = (state: RootState) => state.transactionsReducer.filters
export const selectFiltersTemplates = (state: RootState) => state.transactionsReducer.filtersTemplates
export const allAccounts = (state: RootState) => state.userReducer.accountsList.accounts

export const selectTransactionsByFilter = createSelector<any, any>(
    selectAllTransactions,
    selectFilters,
    (allTransactions: any, selectFilters: IFilters) => {


        let sortedTransactions = [...allTransactions]

        const dateFromBackInTimestamp = (date: string) => {
            return Date.parse(date as string)
        }


        const dateFromInTimestamp = Date.parse(selectFilters.from as string);


        const dateToInTimestamp = Date.parse(selectFilters.to as string);

        if (selectFilters.selectedType === 'ALL') {
            sortedTransactions = allTransactions
        }

        if (selectFilters.accountNumber === 'ALL') {
            sortedTransactions = allTransactions
        }

        if (selectFilters.accountNumber !== 'ALL') {
            sortedTransactions = sortedTransactions.filter((transaction : ITransacton) => transaction.currency === selectFilters.currency)
        }

        if (dateFromInTimestamp !== null && selectFilters.from !== '') {


            sortedTransactions = sortedTransactions.filter((transaction : ITransacton) => (dateFromBackInTimestamp(transaction.created!)) > dateFromInTimestamp)

        }

        if (dateToInTimestamp !== null && selectFilters.to !== '') {

            sortedTransactions = sortedTransactions.filter((transaction : ITransacton) => (dateFromBackInTimestamp(transaction.created!) ) < (dateToInTimestamp + 86400000))
        }

        // Фильтрация по рецепту
        if (selectFilters.recipient !== undefined && selectFilters.recipient !== null && selectFilters.recipient !== '') {
            const filterRecipientLower = (selectFilters.recipient || '').toLowerCase().trim();

            sortedTransactions = sortedTransactions.filter((transaction) => {
                const transactionRecipientLower = transaction.recipient?.trim().toLowerCase() || '';
                const beneficiaryAccountNumberLower = transaction.recipientDetails?.beneficiaryAccountNumber?.trim().toLowerCase() || '';
                const transactionAccountUser = transaction?.toAccountUserName?.trim().toLowerCase() || '';


                if (selectFilters.recipient === '') {
                    return transactionRecipientLower === '' || beneficiaryAccountNumberLower === '';
                }


                const fullMatch =
                    beneficiaryAccountNumberLower === filterRecipientLower ||
                    transactionRecipientLower === filterRecipientLower ||
                    transactionAccountUser === filterRecipientLower;

                // Проверка на совпадение по первым двум символам
                const partialMatch =
                    beneficiaryAccountNumberLower.startsWith(filterRecipientLower) ||
                    transactionRecipientLower.startsWith(filterRecipientLower) ||
                    transactionAccountUser.startsWith(filterRecipientLower);

                // Возвращаем true, если есть либо полное совпадение, либо частичное
                return fullMatch || partialMatch;
            });
        }

        // Фильтрация по рецепту

        if (selectFilters.amount && selectFilters.amount !== '') {
            sortedTransactions = sortedTransactions.filter((transaction : ITransacton) => transaction.amount === Number(selectFilters.amount))
        }

        // фильтрация по 2 эл

        if (selectFilters.outgoing && selectFilters.incoming) {
            sortedTransactions = sortedTransactions.filter((transaction: ITransacton) =>
                (transaction.direction === 'OUT' || transaction.direction === 'IN') &&
                transaction.transactionType !== 'Fee' &&
                transaction.transactionType !== 'Exchange'
            );
            return sortedTransactions

        }
        // фильтрация по 2 эл

        // фильтрация по Fee

        if (selectFilters.fees && selectFilters.outgoing) {
            sortedTransactions = sortedTransactions.filter((transaction: ITransacton) =>
            (transaction.direction === 'OUT') &&
            transaction.transactionType !== 'Exchange'
            )

            return sortedTransactions
        }

        if (selectFilters.fees && selectFilters.incoming) {

            const onlyFeeTransactions = sortedTransactions.filter((transaction: ITransacton) =>
                transaction.transactionType === "Fee"
            )

            const onlyIncomingTransactions = sortedTransactions.filter((transaction: ITransacton) =>
                transaction.direction === 'IN'
            )

            sortedTransactions = [...onlyFeeTransactions, ...onlyIncomingTransactions]

            // sortedTransactions = sortedTransactions.filter((transaction: ITransacton) =>
            // (transaction.direction === 'IN') &&
            // transaction.transactionType !== 'Exchange'
            // )

            return sortedTransactions
        }

        if (selectFilters.fees) {
            sortedTransactions = sortedTransactions.filter((transaction : ITransacton) =>  transaction.transactionType === 'Fee')
        }

        // фильтрация по Fee

        if (selectFilters.incoming) {
            sortedTransactions = sortedTransactions.filter((transaction : ITransacton) => transaction.direction === 'IN'  && transaction.transactionType !== 'Commission' && transaction.transactionType !== 'Exchange')
        }

        if (selectFilters.outgoing) {
            sortedTransactions = sortedTransactions.filter((transaction : ITransacton) => transaction.direction === 'OUT' && transaction.transactionType !== 'Fee' && transaction.transactionType !== 'Exchange')
        }

        if (selectFilters.other) {
            sortedTransactions = sortedTransactions.filter((transaction : ITransacton) =>  transaction.transactionType === 'Fee' || transaction.transactionType === 'Exchange')
        }


        if (selectFilters.outgoing && selectFilters.incoming && selectFilters.other) {
            sortedTransactions = allTransactions
        }

        if (selectFilters.selectedType && selectFilters.selectedType !== 'ALL' && selectFilters.selectedType !== '' ) {
            sortedTransactions = sortedTransactions.filter((transaction : ITransacton) => transaction.transactionType === selectFilters.selectedType)
        }

        return sortedTransactions
    }
)

export const selectTemplatesByFilter = createSelector<any, any>(
    allTemplates,
    selectFiltersTemplates,
    (allTemplates: ITemplate[], selectFilters: IFilters) => {

        let sortedTemplates = [...allTemplates]

        if (selectFilters.templateName === 'ALL') {
            sortedTemplates = allTemplates
        }

        if (selectFilters.recipient === '') {
            sortedTemplates = allTemplates
        }

        if (selectFilters.accountNumber !== 'ALL' && selectFilters.accountNumber !== '') {
            sortedTemplates = sortedTemplates.filter((template: ITemplate) => template.account === selectFilters.hiddenAccountNumber)
        }

        if (selectFilters.templateName && selectFilters.templateName !== 'ALL' ) {
            sortedTemplates = sortedTemplates.filter((template: ITemplate) => template.templateName === selectFilters.templateName)
        }

        if (selectFilters.recipient !== '') {

            sortedTemplates = sortedTemplates.filter((template: ITemplate) => {

                if (template.type === "Intra Transaction") {
                    return (
                        template?.toAccount?.toLowerCase() === selectFilters?.recipient?.toLowerCase()
                    )
                } else {
                    return (
                        template?.recipient?.toLowerCase() === selectFilters?.recipient?.toLowerCase()
                    )
                }
            })
        }

        return sortedTemplates
    }
)

export const selectAccountsByFilter = createSelector<any, any>(
    allAccounts,
    selectFilters,
    (allAccounts: any, selectFilters: IFilters) => {

        let sortedAccounts = [...allAccounts]

        if (selectFilters.paymentRegion === 'SWIFT (International Transfer)') {
            sortedAccounts = allAccounts
        }

        if (selectFilters.paymentRegion === 'SEPA (only for EUR)') {
            sortedAccounts = sortedAccounts.filter((account: any) => account.currency === 'EUR')
        }

        return sortedAccounts.filter((account: any) => account.status === "Active")
    }
)

export const selectTopUpByAccount = createSelector<any, any>(
    allAccounts,
    selectFilters,
    (allAccounts: any, selectFilters: IFilters) => {

        let sortedAccounts = [...allAccounts.filter((account: any) => account.status === "Active")]

        const selectedAccount = sortedAccounts.find((account) => account.accountMainIban === selectFilters.accountNumber)

        return selectedAccount?.topUp
    }
)

export const selectAccountForConfirmationLetter = createSelector<any, any>(
    allAccounts,
    selectFilters,
    (allAccounts: any, selectFilters: IFilters) => {

        let sortedAccounts = [...allAccounts.filter((account: any) => account.status === "Active")]

        const selectedAccount = sortedAccounts.find((account) => account.accountMainIban === selectFilters.accountNumber)

        return selectedAccount
    }
)
