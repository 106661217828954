import {useFormikContext} from "formik";
import {useAppDispatch} from "../../../../../hooks/redux";
import {useEffect} from "react";
import {
    setCurrencyExchangeAmount,
    setCurrencyExchangeFrom,
    setCurrencyExchangeResult, setCurrencyExchangeTo, setCurrencyExchangeToAmount
} from "../../../../../store/reducers/UserSlice";

export const SetCurrencyByAccount = (props: any) => {

    const {values, setFieldValue} = useFormikContext();

    const dispatch = useAppDispatch()

    useEffect(() => {
        props.accountsForSelect.map(async (account: any) => {
            // @ts-ignore
            if (account.number === props?.accountNumberFrom) {
                await dispatch(setCurrencyExchangeAmount(''))
                await dispatch(setCurrencyExchangeResult(''))
                await dispatch(setCurrencyExchangeFrom(account.currency))
                setFieldValue('amount', '')
                setFieldValue('toAmount', '')
            }
        })
        // @ts-ignore
    }, [props.accountNumberFrom])

    useEffect(() => {
        props.accountsForSelect.map(async (account: any) => {
            // @ts-ignore
            if (account.number === props?.accountNumberTo) {
                await dispatch(setCurrencyExchangeAmount(''))
                await dispatch(setCurrencyExchangeResult(''))
                await dispatch(setCurrencyExchangeTo(account.currency))
                setFieldValue('amount', '')
                setFieldValue('toAmount', '')
            }
        })
        // @ts-ignore
    }, [props.accountNumberTo])

    useEffect(() => {

        // @ts-ignore
        if (!!values.amount && values?.amount?.length > 0) {
            // @ts-ignore
            dispatch(setCurrencyExchangeFrom(values.accNumberFromCurrency))
            // @ts-ignore
            dispatch(setCurrencyExchangeTo(values.accNumberToCurrency))
            // @ts-ignore
            dispatch(setCurrencyExchangeAmount(values.amount))
        }

        // @ts-ignore
        if (!!values.toAmount && values?.toAmount?.length > 0) {
            // @ts-ignore
            dispatch(setCurrencyExchangeFrom(values.accNumberToCurrency))
            // @ts-ignore
            dispatch(setCurrencyExchangeTo(values.accNumberFromCurrency))
            // @ts-ignore
            dispatch(setCurrencyExchangeToAmount(values.toAmount))
        }
        // @ts-ignore
    }, [values.amount, values.toAmount])

    useEffect(() => {
        // @ts-ignore
        if (values.amount === '') {
            dispatch(setCurrencyExchangeResult(''))
            // @ts-ignore
            dispatch(setCurrencyExchangeAmount(''))
        }
        // @ts-ignore
    }, [values.amount])

    useEffect(() => {
        // @ts-ignore
        if (values.toAmount === '') {
            dispatch(setCurrencyExchangeResult(''))
            // @ts-ignore
            dispatch(setCurrencyExchangeToAmount(''))
        }
        // @ts-ignore
    }, [values.toAmount])

    return null;
};
